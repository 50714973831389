import React, { useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FilledBgBtn from "../../Buttons/FilledBg/FilledBgBtn";
import Paper from "@mui/material/Paper";
import "./TimeSlot.css";
import { capitalize } from "@mui/material";

const TimeSlot = () => {
  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  const timeInputs = useRef({});

  const handleSaveChanges = () => {
    let message = "Time slots:\n";
    let allSlotsSelected = true;

    days.forEach((day) => {
      const fromTime = timeInputs.current[`from-${day}`]?.value;
      const toTime = timeInputs.current[`to-${day}`]?.value;

      if (!fromTime || !toTime) {
        allSlotsSelected = false;
        return;
      }

      message += `${day}: ${fromTime} - ${toTime}\n`;
    });

    if (!allSlotsSelected) {
      alert("Please select time slots");
    } else {
      alert(message);
    }
  };
  return (
    <div className="time-slots">
      <div className="tableWrapper">
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", backgroundColor: "transparent" }}
        >
          <Table
            sx={{
              minWidth: 100,
              "& thead": {
                borderBottom: "none",
              },
            }}
            aria-label="simple table"
          >
            <TableBody sx={{ border: "none" }}>
              {days.map((day) => (
                <TableRow key={day}>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    sx={{
                      paddingRight: "0px",
                      borderRight: "2px solid #3c73d6",
                      borderBottom: "none",
                    }}
                  >
                    {day}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      borderBottom: "none",
                      paddingLeft: "3vw",
                      paddingRight: "0vw",
                    }}
                  >
                    <TextField
                      id={`from-${day}`}
                      type="time"
                      defaultValue="07:30"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300,
                      }}
                      inputRef={(el) =>
                        (timeInputs.current[`from-${day}`] = el)
                      }
                    />
                  </TableCell>

                  <TableCell
                    align="left"
                    sx={{ borderBottom: "none", paddingLeft: "0vw" }}
                  >
                    <TextField
                      id={`to-${day}`}
                      type="time"
                      defaultValue="17:30"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300,
                      }}
                      inputRef={(el) => (timeInputs.current[`to-${day}`] = el)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="btns">
        <FilledBgBtn
          className="save-btn capitalize"
          text={"Cancel"}
          style={{
            fontSize: "17px",
            textTransform: "none",
            color: "#3c73d6",
            border: "1px solid #3c73d6",
            capitalize: "none",
            borderRadius: "10px",
            paddingLeft: "5px",
            paddingRight: "5px",
            boarderColor: "#3c73d6",
            backgroundColor: "white",
            marginTop: "10px",
            width: "25%",
            maxWidth: "500px",
            minWidth: "50px",
            height: "50px",
          }}
        />
        <FilledBgBtn
          className="save-btn capitalize"
          text={"Save Changes"}
          callBack={() => handleSaveChanges()}
          style={{
            fontSize: "17px",
            textTransform: "none",
            capitalize: "none",
            borderRadius: "10px",
            paddingLeft: "5px",
            paddingRight: "5px",
            boarderColor: "#3c73d6",
            backgroundColor: "#3c73d6",
            marginTop: "10px",
            width: "25%",
            maxWidth: "500px",
            minWidth: "50px",
            height: "50px",
          }}
        />
      </div>
    </div>
  );
};

export default TimeSlot;
