import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import "./SignInPage.css";
import logo from "../../assets/icons/logo1.png";
import Navbar from "../component/Navigation/NavBar";
import NavbarMobile from "../component/Navigation/NavBarMobile";
import { useSelector } from "react-redux";

export default function LoginForm({ onGoogleLogin, onAppleLogin }) {
  const isWide = useSelector((state) => state.windowSize.isWide);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [isSignIn, setIsSignIn] = useState(true);

  const auth = getAuth();
  const navigate = useNavigate();

  const handleSignIn = (e) => {
    e.preventDefault();
    // Reset error messages
    setEmailError("");
    setPasswordError("");
    setLoginError("");

    let valid = true;
    if (!email) {
      setEmailError("Please enter your email address.");
      valid = false;
    }

    if (!password) {
      setPasswordError("Please enter your password.");
      valid = false;
    }

    if (valid) {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // User signed in
          // ... handle user sign in ...
        })
        .catch((error) => {
          console.error(error);
          setPasswordError("The password is incorrect.");
          // Differentiate between different types of errors here if needed
          // setLoginError('Username and password do not match.');
        });
    }
  };

  return (
    <>
      {isWide ? <Navbar /> : <NavbarMobile />}
      <div className="login-container">
        <img className="logo" src={logo} alt="Logo" />
        <div className="tab-container">
          <div
            className={`tab ${isSignIn ? "active" : ""}`}
            onClick={() => setIsSignIn(true)}
          >
            Sign in
          </div>
          <div
            className={`tab ${!isSignIn ? "active" : ""}`}
            onClick={() => navigate("/register")}
          >
            New account
          </div>
        </div>
        <form onSubmit={handleSignIn}>
          <div className="input-group">
            <label htmlFor="email" className="input-label">
              Email
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Email"
              className="input-field"
              autoComplete="username"
            />
            {emailError && <div className="error-message">{emailError}</div>}
          </div>
          <div className="input-group">
            <label htmlFor="password" className="input-label">
              Password
            </label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter Password"
              className="input-field"
              autoComplete="current-password"
            />
            {passwordError && (
              <div className="error-message">{passwordError}</div>
            )}
          </div>
          <button type="submit" className="primary-button">
            Sign in
          </button>
          <Link to="/forgot-password" className="forgot-password-link">
            Forgot your password?
          </Link>
          <hr />
          <p className="connect-text">Or Connect With</p>
          <button
            type="button"
            onClick={onGoogleLogin}
            className="social-button"
          >
            Continue with Google
          </button>
        </form>
      </div>
    </>
  );
}
