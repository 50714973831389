import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  Box,
  Pagination,
  IconButton,
} from "@mui/material";
import { Bookmark } from "@mui/icons-material";
import "./MobileAdminPage.css";
import Navbar from "../component/Navigation/NavBar";
import NavbarMobile from "../component/Navigation/NavBarMobile";
import LrgSearchBar from "../component/SearchBar/LargeSearchBar/LargeSearchBar";
import backToAdmin from "../../assets/images/Arrow.png";
import ListingTab from "../component/Tabs/ListingTab/ListingTab";
import NoBorderBtn from "../component/Buttons/NoBorder/NoBorderBtn";
import ConfirmPopup from "../component/PopUps/RemoveListing/ConfirmPopup";
import {Link, useNavigate} from "react-router-dom";

export default function MobileAdminPage({ isWide }) {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [listingToRemove, setListingToRemove] = useState(null);
  const [activeTab, setActiveTab] = useState("admin");
  const [currentPage, setCurrentPage] = useState(1);
  const [isBookmarked, setIsBookmarked] = useState({});
  const listingsPerPage = 4;
  const [listings, setListings] = useState([
    {
      id: 1,
      address: "11121 Montana Avenue",
      city: "Fort Lee, NJ 07210",
      type: "Single Home",
      price: "$500,000",
      status: "On Sale",
    },
    {
      id: 2,
      address: "11121 Caroline Avenue",
      city: "New York, NY 10016",
      type: "Condo",
      price: "$1,000,000",
      status: "Sold",
    },
    {
      id: 3,
      address: "11121 Montana Avenue",
      city: "Los Angeles, CA 90049",
      type: "Condo",
      price: "$1,000,000",
      status: "Sold",
    },
    {
      id: 4,
      address: "11121 Montana Avenue",
      city: "Los Angeles, CA 90049",
      type: "Townhouse",
      price: "$1,000,000",
      status: "No longer available",
    },
    {
      id: 5,
      address: "11121 Elm Street",
      city: "Fort Lee, NJ 07210",
      type: "Single Home",
      price: "$500,000",
      status: "On Sale",
    },
    {
      id: 6,
      address: "11121 Maple Street",
      city: "Fort Lee, NJ 07210",
      type: "Single Home",
      price: "$500,000",
      status: "On Sale",
    },
    {
      id: 7,
      address: "11121 Oak Street",
      city: "Fort Lee, NJ 07210",
      type: "Single Home",
      price: "$500,000",
      status: "On Sale",
    },
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("Current Listings:", listings);
    console.log("Current Page:", currentPage);
    console.log(
      "Listings on Current Page:",
      listings.slice(
        (currentPage - 1) * listingsPerPage,
        currentPage * listingsPerPage
      )
    );
  }, [listings, currentPage]);

  const indexOfLastListing = currentPage * listingsPerPage;
  const indexOfFirstListing = indexOfLastListing - listingsPerPage;
  const currentListings = listings.slice(
    indexOfFirstListing,
    indexOfLastListing
  );

  const paginate = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const noBorderBtnStyle = {
    backgroundColor: "transparent",
    border: "none",
    color: "black",
    textAlign: "right",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "0.8rem",
    fontWeight: "bold",
    fontFamily: "Roboto, sans-serif",
    margin: "4px 2px",
    cursor: "pointer",
    whiteSpace: "nowrap",
    width: "auto",
    textTransform: "capitalize",
  };

  const getImagePath = (id) => {
    try {
      return require(`../../assets/images/adminPage/home_${id}.png`);
    } catch (err) {
      console.error(`Image not found: home_${id}.png`);
      return null;
    }
  };

  const openPopup = (listingId) => {
    setPopupOpen(true);
    setListingToRemove(listingId);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const removeListing = () => {
    const updatedListings = listings.filter(
      (listing) => listing.id !== listingToRemove
    );
    setListings(updatedListings);
    const totalPages = Math.ceil(updatedListings.length / listingsPerPage);
    /*if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    } else if (currentPage === 1 && updatedListings.length === 0) {
      setCurrentPage(1);
    } else {
      setCurrentPage((prev) => Math.min(prev, totalPages));
    }*/
    setCurrentPage((prev) => Math.min(prev, totalPages));

    closePopup();
  };

  return (
    <>
      <div className="nav-bar">{isWide ? <Navbar /> : <NavbarMobile />}</div>
      <div className="body">
        <div className="header">
          <div className="arrow">
            <a href="/my-account" className="back-to-myAccount-link">
              <img src={backToAdmin} alt="Back to Admin" />
            </a>
          </div>
          <div
            className="admin-header"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
              gap: "5px",
            }}
          >
            <Typography
              variant="h4"
              className="mobile-admin-title"
              sx={{
                marginTop: "5vh",
                marginLeft: "40px",
                fontWeight: "bold",
                fontSize: "1.6rem",
                textAlign: "left",
              }}
            >
              My Account
            </Typography>
            <Typography
              variant="body1"
              className="mobile-admin-decription"
              sx={{
                marginLeft: "40px",
                fontSize: "0.8rem",
                textAlign: "left",
                marginBottom: "20px",
              }}
            >
              Description of managing your account
            </Typography>
            <div style={{ display: "flex", width: isWide ? "75%" : "100%" }}>
              <ListingTab activeTab={activeTab} setActiveTab={setActiveTab} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: isWide ? "-5vw" : "0",
                  textTransform: "none",
                }}
              >
                <a
                  href="/create-listing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <NoBorderBtn style={noBorderBtnStyle} text="Add New" />
                </a>
                <a
                  href="https://www.amazon.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <NoBorderBtn style={noBorderBtnStyle} text="# Items" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <Container className="main-body">
          <div
            className="mobile-searchContainer"
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              width: "100%",
            }}
          >
            <LrgSearchBar placeholder="Search" />
          </div>
          <Box className="listing-container">
            {currentListings.map((listing) => (
                <Box className="listing-item" key={listing.id}>
                  <Box className="listing-image-container">
                    <img
                        src={getImagePath(listing.id)}
                        alt={listing.address}
                        className="listing-image"
                    />
                    <IconButton
                        className="bookmark-icon"
                        sx={{
                          marginTop: "-115px",
                          left: "-35px",
                          color: isBookmarked[listing.id] ? "#3c73d6" : "default",
                          borderRadius: 0,
                        }}
                        onClick={() =>
                            setIsBookmarked({
                              ...isBookmarked,
                              [listing.id]: !isBookmarked[listing.id],
                            })
                        }
                    >
                      <Bookmark />
                    </IconButton>
                  </Box>
                  <Box className="listing-details-wrapper">
                    <Box className="listing-details">
                      <Box className="listing-details-top">
                        <Typography className="listing-title">
                          {listing.address}
                        </Typography>
                        <Typography className="listing-subtitle">
                          {listing.city}
                        </Typography>
                      </Box>
                      <Box className="listing-details-bottom">
                        <Typography>{listing.type}</Typography>
                        <Typography>{listing.price}</Typography>
                        <Typography>{listing.status}</Typography>
                      </Box>
                    </Box>
                    <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          alignItems: "center",
                        }}
                    >
                      <Link
                          className="edit-btn"
                          to="/edit-listing"
                          state={listing}
                      >
                        <NoBorderBtn style={noBorderBtnStyle} text="Edit"/>
                      </Link>
                      <a
                          className="remove-btn"
                          href="#"
                          rel="noopener noreferrer"
                          onClick={() => openPopup(listing.id)}
                      >
                        <NoBorderBtn style={noBorderBtnStyle} text="Remove" />
                      </a>
                    </div>
                  </Box>
                </Box>
            ))}
          </Box>

          <div className="pagination">
            <Pagination
              count={Math.ceil(listings.length / listingsPerPage)}
              color="primary"
              page={currentPage}
              onChange={paginate}
            />
          </div>
        </Container>
        <ConfirmPopup
          isOpen={isPopupOpen}
          onClose={closePopup}
          onConfirm={removeListing}
        >
          Are you sure you want to remove this listing?
        </ConfirmPopup>
      </div>
    </>
  );
}
