import React, { useState, useEffect } from "react";
import "./CreateListing.css";
import Navbar from "../component/Navigation/NavBar";
import NavbarMobile from "../component/Navigation/NavBarMobile";
import Footer from "../component/Footer/Footer";
import FooterMobile from "../component/Footer/FooterMobile";
import backToAdmin from "../../assets/images/Arrow.png";
import ListingTab from "../component/Tabs/ListingTab/ListingTab";
import TimeSlot from "../component/Calendar/TimeSlotTable/TimeSlot";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import FilledBgBtn from "../component/Buttons/FilledBg/FilledBgBtn";
import birdIcon from "../../assets/icons/bird_icon.png";
import CustomCheckbox from "../component/Checkbox/Checkbox";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";
import { Container } from "postcss";
import { addListing } from "../../services/apiServices";
import { TextField, Typography, Select, MenuItem } from "@mui/material";

export default function CreateListingPage({ isWide, user }) {
  const [address, setAddress] = useState("");
  const [activeTab, setActiveTab] = useState("profile");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [modalImage, setModalImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(birdIcon);
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const [submit, setSubmit] = useState(false);

  const [formState, setFormState] = useState({
    Address: "",
    Price: "",
    AppraisedValue: "",
    PropertyTax: "",
    HOA: "",
    Utilities: "",
    Size: "",
    Beds: "",
    Baths: "",
    YearBuilt: "",
    BasementFinish: "",
    Parking: "",
    Deck: "",
    Patio: "",
    FloorFinish: "",
    Fireplace: "",
  });

  const [errors, setErrors] = useState({
    Address: "",
    Price: "",
    AppraisedValue: "",
    PropertyTax: "",
    HOA: "",
    Utilities: "",
    Size: "",
    Beds: "",
    Baths: "",
    YearBuilt: "",
    BasementFinish: "",
    Parking: "",
    Deck: "",
    Patio: "",
    FloorFinish: "",
    Fireplace: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Update the form state with the new value
    setFormState((prevState) => ({ ...prevState, [name]: value }));

    // Immediately validate the field and update the error state
    // const errorMessage = validateField(name, value);
    // setErrors((prevErrors) => ({ ...prevErrors, [name]: errorMessage }));

    if (submitAttempted) {
      const errorMessage = validateField(name, value);
      setErrors((prevErrors) => ({ ...prevErrors, [name]: errorMessage }));
    }
  };

  const validateField = (name, value) => {
    let errMsg = "";

    switch (name) {
      case "Address":
        if (!value) {
          errMsg = "Address is required";
        }
        if (!validateAddress(value)) {
          errMsg =
            "Correct Format: Street Number and Street Name, City, State Zipcode";
        }
        break;
      case "Price":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      case "AppraisedValue":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      case "PropertyTax":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      case "HOA":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      case "Utilities":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      case "Size":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      case "Beds":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      case "Baths":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      case "YearBuilt":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      case "BasementFinish":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      case "Parking":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      // case "Deck":
      //   if (!value.match(/^\d*(\.\d{0,2})?$/)) {
      //     errMsg = "Please enter a valid number";
      //   }
      //   break;
      // case "Patio":
      //   if (!value.match(/^\d*(\.\d{0,2})?$/)) {
      //     errMsg = "Please enter a valid number";
      //   }
      //   break;
      case "FloorFinish":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      case "Fireplace":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
    }
    return errMsg;
  };

  const validateAddress = (input) => {
    const pattern = /^\d+\s[a-z\s]+\,\s[a-z\s]+\,\s[a-z]{2}\s\d{5}$/i;
    return pattern.test(input);
  };

  const handleSubmit = async () => {
    event.preventDefault();
    setSubmitAttempted(true);

    // Validate all fields again on submission
    const newErrors = Object.keys(formState).reduce((acc, key) => {
      acc[key] = validateField(key, formState[key]);
      return acc;
    }, {});

    setErrors(newErrors);

    // Check for any errors in the form after revalidation
    const hasErrors = Object.values(newErrors).some((error) => error !== "");

    if (!hasErrors) {
      try {
        const userId = user.uid;
        const response = await addListing(userId, formState);
        console.log("Form Data Submitted:", formState);
      } catch (error) {
        console.log("Error Create Listing: ", error);
      }
    } else {
      alert("Please correct the errors before submitting.");
    }
  };

  return (
    <>
      <div className="cl-edit-listing">
        {isWide ? <Navbar /> : <NavbarMobile />}
        <div className="cl-arrow">
          <a href="/admin" className="cl-back-to-admin-link">
            <img src={backToAdmin} />
            <span className="cl-back">Back to Admin</span>
          </a>
        </div>
        <div className="cl-bold-text">Create Listing</div>
        <div className="cl-grid-container">
          <ListingTab activeTab={activeTab} setActiveTab={setActiveTab} />
        </div>
        <div className="cl-new-container-background">
          <div className="cl-new-container">
            <div className="cl-left-section">
              <div className="cl-address">
                <div className="cl-address-field">
                  <label htmlFor="address" className="label address-label">
                    Address
                  </label>

                  <TextField
                    id="address"
                    name="Address"
                    variant="outlined"
                    fullWidth
                    required
                    value={formState.Address}
                    onChange={handleInputChange}
                    error={Boolean(errors.Address)}
                    helperText={
                      errors.Address ||
                      "Format: Street Number and Street Name, City, State Zipcode"
                    }
                  />
                </div>
              </div>

              <div className="cl-row1">
                <div className="cl-field">
                  <label htmlFor="price" className="label row-label">
                    Price
                  </label>
                  <TextField
                    id="price"
                    name="Price"
                    variant="outlined"
                    fullWidth
                    required
                    value={formState.Price}
                    onChange={handleInputChange}
                    error={Boolean(errors.Price)}
                    helperText={errors.Price || ""}
                  />
                </div>

                <div className="cl-field">
                  <label htmlFor="price" className="label row-label">
                    Appraised Value
                  </label>
                  <TextField
                    id="AppraisedValue"
                    name="AppraisedValue"
                    variant="outlined"
                    fullWidth
                    required
                    value={formState.AppraisedValue}
                    onChange={handleInputChange}
                    error={Boolean(errors.AppraisedValue)}
                    helperText={errors.AppraisedValue || ""}
                  />
                </div>

                <div className="cl-field">
                  <label htmlFor="price" className="label row-label">
                    Property Tax
                  </label>
                  <TextField
                    id="PropertyTax"
                    name="PropertyTax"
                    variant="outlined"
                    fullWidth
                    required
                    value={formState.PropertyTax}
                    onChange={handleInputChange}
                    error={Boolean(errors.PropertyTax)}
                    helperText={errors.PropertyTax || ""}
                  />
                </div>
              </div>

              <div className="cl-row">
                <div className="cl-field">
                  <label htmlFor="price" className="label row-label">
                    HOA
                  </label>
                  <TextField
                    id="HOA"
                    name="HOA"
                    variant="outlined"
                    fullWidth
                    required
                    value={formState.HOA}
                    onChange={handleInputChange}
                    error={Boolean(errors.HOA)}
                    helperText={errors.HOA || ""}
                  />
                </div>

                <div className="cl-field">
                  <label htmlFor="price" className="label row-label">
                    Utilities
                  </label>
                  <TextField
                    id="Utilities"
                    name="Utilities"
                    variant="outlined"
                    fullWidth
                    required
                    value={formState.Utilities}
                    onChange={handleInputChange}
                    error={Boolean(errors.Utilities)}
                    helperText={errors.Utilities || ""}
                  />
                </div>

                <div className="cl-field">
                  <label htmlFor="price" className="label row-label">
                    Size(SQFT)
                  </label>
                  <TextField
                    id="Size"
                    name="Size"
                    variant="outlined"
                    fullWidth
                    required
                    value={formState.Size}
                    onChange={handleInputChange}
                    error={Boolean(errors.Size)}
                    helperText={errors.Size || ""}
                  />
                </div>
              </div>

              <div className="cl-row">
                <div className="cl-field">
                  <label htmlFor="price" className="label row-label">
                    # Beds
                  </label>
                  <TextField
                    id="Beds"
                    name="Beds"
                    variant="outlined"
                    fullWidth
                    required
                    value={formState.Beds}
                    onChange={handleInputChange}
                    error={Boolean(errors.Beds)}
                    helperText={errors.Beds || ""}
                  />
                </div>

                <div className="cl-field">
                  <label htmlFor="price" className="label row-label">
                    # Baths
                  </label>
                  <TextField
                    id="Baths"
                    name="Baths"
                    variant="outlined"
                    fullWidth
                    required
                    value={formState.Baths}
                    onChange={handleInputChange}
                    error={Boolean(errors.Baths)}
                    helperText={errors.Baths || ""}
                  />
                </div>

                <div className="cl-field">
                  <label htmlFor="price" className="label row-label">
                    Year Built
                  </label>
                  <TextField
                    id="YearBuilt"
                    name="YearBuilt"
                    variant="outlined"
                    fullWidth
                    required
                    value={formState.YearBuilt}
                    onChange={handleInputChange}
                    error={Boolean(errors.YearBuilt)}
                    helperText={errors.YearBuilt || ""}
                  />
                </div>
              </div>

              <div className="cl-row">
                <div className="cl-field">
                  <label htmlFor="price" className="label row-label">
                    Basement Finish
                  </label>
                  <TextField
                    id="BasementFinish"
                    name="BasementFinish"
                    variant="outlined"
                    fullWidth
                    required
                    value={formState.BasementFinish}
                    onChange={handleInputChange}
                    error={Boolean(errors.BasementFinish)}
                    helperText={errors.BasementFinish || ""}
                  />
                </div>

                <div className="cl-field">
                  <label htmlFor="price" className="label row-label">
                    Parking
                  </label>
                  <TextField
                    id="Parking"
                    name="Parking"
                    variant="outlined"
                    fullWidth
                    required
                    value={formState.Parking}
                    onChange={handleInputChange}
                    error={Boolean(errors.Parking)}
                    helperText={errors.Parking || ""}
                  />
                </div>

                <div className="cl-field">
                  <label htmlFor="price" className="label row-label">
                    Deck
                  </label>
                  <Select
                    id="Deck"
                    name="Deck"
                    variant="outlined"
                    fullWidth
                    required
                    value={formState.Deck}
                    onChange={handleInputChange}
                    error={Boolean(errors.Deck)}
                    helperText={errors.Deck || ""}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="have">Have</MenuItem>
                    <MenuItem value="don't have">Don't have</MenuItem>
                  </Select>
                </div>
              </div>

              <div className="cl-row">
                <div className="cl-field">
                  <label htmlFor="price" className="label row-label">
                    Patio
                  </label>
                  <Select
                    id="Patio"
                    name="Patio"
                    variant="outlined"
                    fullWidth
                    required
                    value={formState.Patio}
                    onChange={handleInputChange}
                    error={Boolean(errors.Patio)}
                    helperText={errors.Patio || ""}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="have">Have</MenuItem>
                    <MenuItem value="don't have">Don't have</MenuItem>
                  </Select>
                </div>

                <div className="cl-field">
                  <label htmlFor="price" className="label row-label">
                    Floor Finish
                  </label>
                  <TextField
                    id="FloorFinish"
                    name="FloorFinish"
                    variant="outlined"
                    fullWidth
                    required
                    value={formState.FloorFinish}
                    onChange={handleInputChange}
                    error={Boolean(errors.FloorFinish)}
                    helperText={errors.FloorFinish || ""}
                  />
                </div>

                <div className="cl-field">
                  <label htmlFor="price" className="label row-label">
                    Fireplace
                  </label>
                  <TextField
                    id="Fireplace"
                    name="Fireplace"
                    variant="outlined"
                    fullWidth
                    required
                    value={formState.Fireplace}
                    onChange={handleInputChange}
                    error={Boolean(errors.Fireplace)}
                    helperText={errors.Fireplace || ""}
                  />
                </div>
              </div>
            </div>

            <div className="cl-right-section">
              <div className="cl-subtitle">
                <h1>Media</h1>
              </div>
              <div className="cl-media">
                <div className="cl-media-upload">
                  <input
                    type="file"
                    id="file-upload"
                    name="cl-file-upload"
                    accept="image/jpeg, image/png"
                    multiple
                    hidden
                  />
                  <label htmlFor="cl-file-upload" className="cl-upload-area">
                    <div>
                      <UploadOutlined className="cl-upload-icon" />
                    </div>
                    <div className="cl-upload-instructions">
                      Drag images here or click to upload
                    </div>
                    <div className="cl-format-instructions">
                      Formats: JPG and PNG
                    </div>
                  </label>
                </div>
                <div className="cl-image-preview-container">
                  {uploadedImages.map((image, index) => (
                    <div key={index} className="cl-image-preview">
                      <img
                        src={image}
                        alt={`Upload Preview ${index}`}
                        style={{ width: "100px", height: "auto" }}
                      />
                      <button onClick={() => removeImage(index)}>Remove</button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cl-button-background">
          <Flex gap="small" wrap>
            <div className="cl-button-group">
              <Button className="cl-button-margin">Cancel</Button>
              <Button type="primary" onClick={handleSubmit}>
                Save Changes
              </Button>
            </div>
          </Flex>
        </div>
        {/* <div className="time-slot">
          <Typography
            variant="h4"
            className="time-slot-title"
            sx={{
              marginTop: "5vh",
              fontWeight: "bold",
              marginBottom: "60px",
              color: "#3c73d6",
              fontSize: "25px",
              textAlign: "left",
            }}
          >
            Available Date and Time
          </Typography>
          <div>
            <TimeSlot />
          </div>
          <div className="cl-btns">
            <FilledBgBtn
              className="save-btn capitalize"
              text={"Cancel"}
              style={{
                fontSize: "17px",
                textTransform: "none",
                color: "#3c73d6",
                border: "1px solid #3c73d6",
                capitalize: "none",
                borderRadius: "10px",
                paddingLeft: "15px",
                paddingRight: "15px",
                boarderColor: "#3c73d6",
                backgroundColor: "white",
                marginTop: "10px",
                width: "50%",
                maxWidth: "500px",
                minWidth: "50px",
                height: "50px",
              }}
            />
            <FilledBgBtn
              className="save-btn capitalize"
              text={"Save Changes"}
              style={{
                fontSize: "17px",
                textTransform: "none",
                capitalize: "none",
                borderRadius: "10px",
                paddingLeft: "15px",
                paddingRight: "15px",
                boarderColor: "#3c73d6",
                backgroundColor: "#3c73d6",
                marginTop: "10px",
                width: "50%",
                maxWidth: "500px",
                minWidth: "50px",
                height: "50px",
              }}
            />
          </div>
        </div> */}
        <div className="cl-footer-create">
          {isWide ? <Footer /> : <FooterMobile />}
        </div>
      </div>
    </>
  );
}
