// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container for the saved searches page */
.SavedSearchPage {
   width: 100%;
   padding: 20px;
   box-sizing: border-box;
}


/* Title for the saved searches page */
.SavedSearchPageTitle {
   font-size: 36px;
   font-weight: bolder;
   margin-bottom: 10px;
   text-align: left;
   width: 100%;
}


/* Subtitle for the saved searches page */
.SavedSearchPageSubtitle {
   font-size: 24px;
   text-align: left;
   width: 100%;
   margin-bottom: 20px;
}


/* Container for the saved searches */
.SavedSearchesContainer {
   margin-top: 20px;
}


/* Individual saved search entry */
.SavedSearchEntry {
   background-color: #f9f9f9;
   padding: 15px;
   margin-bottom: 10px;
   border-radius: 5px;
   display: flex;
   justify-content: space-between;
   align-items: center;
}



/* Container for the footer */
.Footer {
   margin-top: 40px;
   text-align: center;
}


/* Links in the footer */
.FooterLink {
   margin: 0 10px;
   text-decoration: none;
   color: #000;
}

`, "",{"version":3,"sources":["webpack://./App/Pages/SavedPage/SavedPage.css"],"names":[],"mappings":"AAAA,0CAA0C;AAC1C;GACG,WAAW;GACX,aAAa;GACb,sBAAsB;AACzB;;;AAGA,sCAAsC;AACtC;GACG,eAAe;GACf,mBAAmB;GACnB,mBAAmB;GACnB,gBAAgB;GAChB,WAAW;AACd;;;AAGA,yCAAyC;AACzC;GACG,eAAe;GACf,gBAAgB;GAChB,WAAW;GACX,mBAAmB;AACtB;;;AAGA,qCAAqC;AACrC;GACG,gBAAgB;AACnB;;;AAGA,kCAAkC;AAClC;GACG,yBAAyB;GACzB,aAAa;GACb,mBAAmB;GACnB,kBAAkB;GAClB,aAAa;GACb,8BAA8B;GAC9B,mBAAmB;AACtB;;;;AAIA,6BAA6B;AAC7B;GACG,gBAAgB;GAChB,kBAAkB;AACrB;;;AAGA,wBAAwB;AACxB;GACG,cAAc;GACd,qBAAqB;GACrB,WAAW;AACd","sourcesContent":["/* Container for the saved searches page */\r\n.SavedSearchPage {\r\n   width: 100%;\r\n   padding: 20px;\r\n   box-sizing: border-box;\r\n}\r\n\r\n\r\n/* Title for the saved searches page */\r\n.SavedSearchPageTitle {\r\n   font-size: 36px;\r\n   font-weight: bolder;\r\n   margin-bottom: 10px;\r\n   text-align: left;\r\n   width: 100%;\r\n}\r\n\r\n\r\n/* Subtitle for the saved searches page */\r\n.SavedSearchPageSubtitle {\r\n   font-size: 24px;\r\n   text-align: left;\r\n   width: 100%;\r\n   margin-bottom: 20px;\r\n}\r\n\r\n\r\n/* Container for the saved searches */\r\n.SavedSearchesContainer {\r\n   margin-top: 20px;\r\n}\r\n\r\n\r\n/* Individual saved search entry */\r\n.SavedSearchEntry {\r\n   background-color: #f9f9f9;\r\n   padding: 15px;\r\n   margin-bottom: 10px;\r\n   border-radius: 5px;\r\n   display: flex;\r\n   justify-content: space-between;\r\n   align-items: center;\r\n}\r\n\r\n\r\n\r\n/* Container for the footer */\r\n.Footer {\r\n   margin-top: 40px;\r\n   text-align: center;\r\n}\r\n\r\n\r\n/* Links in the footer */\r\n.FooterLink {\r\n   margin: 0 10px;\r\n   text-decoration: none;\r\n   color: #000;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
