import React from 'react';
import { Grid, Paper } from '@mui/material';
import SingleGuide from './SingleGuide';

export default function GuideSection() {
    return (
        <Paper elevation={0} sx={{ width: '100%', minHeight: '300px'}}>
            <Grid container sx={{ justifyContent: 'space-around', alignItems: 'center', padding: 4, mt: '50px'  }}>
                <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
                    <SingleGuide
                        title={"Tailored Searches"}
                        description={"On the NEST's geo-map, the filtered properties for sales/lease are shown with" +
                            "some pictures and a virtual tour option. Users can browse each filtered property and save for the comparison."}
                        buttonText={"guide"}
                    />
                </Grid>
                <Grid item xs={12} sm={4} sx={{ textAlign: 'center', borderLeft: { sm: '1px dashed', height: '100%' }, borderRight: { sm: '1px dashed', height: '100%'  } }}>
                    <SingleGuide
                        title={"Accessibility"}
                        description={"Buyers can search properties simply and intuitively using our " +
                            "voice A.I engine for a tailored search."}
                        buttonText={"Speech-to-text guide"}
                    />
                </Grid>
                <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
                    <SingleGuide
                        title={"Convenience"}
                        description={"When it comes to the contract, buyers and sellers do not need to meet in person. " +
                            "Instead, contracts can be signed directly from their mobile devices with bio signatures."}
                        buttonText={"Try out our geo-map"}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
}
