// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-slots{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  margin: auto
}
.tableWrapper {
  width: 600px;
  height: 500px;
  margin: auto;
}

.tableContainer {
  width: 100%;
  margin: auto;
}

.tableCell {
  padding: 10px;
}

.btns{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3vw;
  margin-left: 3vw;
}`, "",{"version":3,"sources":["webpack://./App/Pages/component/Calendar/TimeSlotTable/TimeSlot.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,iBAAiB;EACjB;AACF;AACA;EACE,YAAY;EACZ,aAAa;EACb,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,QAAQ;EACR,gBAAgB;AAClB","sourcesContent":[".time-slots{\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: flex-start;\r\n  align-items: left;\r\n  margin: auto\r\n}\r\n.tableWrapper {\r\n  width: 600px;\r\n  height: 500px;\r\n  margin: auto;\r\n}\r\n\r\n.tableContainer {\r\n  width: 100%;\r\n  margin: auto;\r\n}\r\n\r\n.tableCell {\r\n  padding: 10px;\r\n}\r\n\r\n.btns{\r\n  display: flex;\r\n  flex-direction: row;\r\n  justify-content: center;\r\n  gap: 3vw;\r\n  margin-left: 3vw;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
