import Navbar from "../component/Navigation/NavBar";
import Footer from "../component/Footer/Footer";
import NewsPageTitle from "../component/NewsPage/NewsPageTitle/NewsPageTitle";
import LastArticlesBoard from "../component/NewsPage/LatestArticlesBoard/LatestArticlesBoard";
import GetLatestNewsForm from "../component/GetLatestNewsForm/GetLatestNewsForm";
import FooterMobile from '../component/Footer/FooterMobile';
import NavbarMobile from '../component/Navigation/NavBarMobile';
import './NewsPage.css'
import { fetchNewsData } from "../../services/fetchData";
import { useState, useEffect } from "react";
import FeaturedArticleCard from "../component/FeaturedNestCard/FeaturedNestCard";
import { useSelector } from 'react-redux'

export default function NewsPage() {
    const isWide = useSelector(state => state.windowSize.isWide);
    const [news, setNews] = useState([]);
    useEffect(() => {
        const getNews = async () => {
            try {
                const data = await fetchNewsData();
                setNews(data);
            }
            catch (error) {
                console.error('Error fetching news data:', error);
            }
        };

        getNews();
    }, []);

    return (
        <>
            {isWide ? <Navbar /> : <NavbarMobile />}
            <div className="fullScreen && NewsPageMainDiv">
                <div className="NewsPageElements">
                    <NewsPageTitle />
                </div>
                <div className="NewsPageElements" style={{ marginTop: '30px' }}>
                    {news.length >= 4 && (
                        <div style={{ minHeight: '800px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                            <FeaturedArticleCard title={news[0].title} description={news[0].description} link={{ absolute: true, url: news[0].link }} author={news[0].author} date={news[0].date} />
                            <FeaturedArticleCard title={news[1].title} description={news[1].description} link={{ absolute: true, url: news[1].link }} author={news[1].author} date={news[1].date} />
                            {
                                isWide ?
                                    <LastArticlesBoard news={news} />
                                    :
                                    <>
                                        <FeaturedArticleCard title={news[2].title} description={news[2].description} link={{ absolute: true, url: news[2].link }} author={news[2].author} date={news[2].date} />
                                        <FeaturedArticleCard title={news[3].title} description={news[3].description} link={{ absolute: true, url: news[3].link }} author={news[3].author} date={news[3].date} />
                                    </>
                            }

                        </div>
                    )}
                </div>
                <div className="GetLatestNewsDiv">
                    <div className="NewsPageElements">
                        <GetLatestNewsForm />
                    </div>
                </div>
                {isWide ? <Footer /> : <FooterMobile />}
            </div>
        </>
    )
}