import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Slider, Input as MuiInput, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';

const Input = styled(MuiInput)`
    width: 60px;
    text-decoration: none; /* Remove underline */
`;

const CustomSlider = styled(Slider)(({ theme }) => ({
    color: 'lightgrey',
    padding: '22px 0',
    '& .MuiSlider-thumb': {
        height: 20,
        width: 20,
        backgroundColor: '#fff', // White thumb
        boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
        '&:focus, &:hover, &.Mui-active': {
            boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
            '@media (hover: none)': {
                boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
            },
        },
    },
    '& .MuiSlider-rail': {
        backgroundColor: '#d0d0d0', // Grey rail
        opacity: 0.5,
        height: 5,
    },
    '& .MuiSlider-track': {
        height: 5,
        border: 'none',
    },
}));

export default function MRSlider({ onRateChange }) {
    const [MRvalue, setMRValue] = useState(0);

    const handleSliderChange = (event, newValue) => {
        setMRValue(newValue);
        onRateChange(event, newValue / 10);
    };

    const handleMRChange = (event) => {
        const newValue = event.target.value === '' ? 0 : Number(event.target.value) * 10;
        setMRValue(newValue);
        onRateChange(event, newValue / 10);
    };

    const handleBlur = () => {
        if (MRvalue < 0) {
            setMRValue(0);
        } else if (MRvalue > 100) {
            setMRValue(100);
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Typography id="input-slider" gutterBottom>
                Mortgage Rate
            </Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                    <CustomSlider
                        value={MRvalue}
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                        min={0}
                        max={100}
                        step={10}
                    />
                </Grid>
                <Grid item>
                    <Input
                        value={(MRvalue / 10).toFixed(0)} // No decimal digit
                        size="small"
                        onChange={handleMRChange}
                        onBlur={handleBlur}
                        inputProps={{
                            step: 1,
                            min: 0,
                            max: 10,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
