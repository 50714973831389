import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const BedBathPopup = ({ beds, setBeds, baths, setBaths, closePopup }) => {
  const bedOptions = ['1', '2', '3', '4+', 'any'];
  const bathOptions = ['1', '2', '3', '4+', 'any'];
  const popupRef = useRef();

  useEffect(() => {
      const handleClickOutside = (event) => {
          if (popupRef.current && !popupRef.current.contains(event.target)) {
              const popover = event.target.closest('.MuiPopover-root');
              if (!popover) {
                  closePopup();
              }
          }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [closePopup]);

  return (
    <Box sx={{
      position: 'absolute',
      top: '45px',
      left: '368px',
      transform: 'translateX(-50%)',
      width: 290, 
      height: 200,
      p: 2,
      bgcolor: 'background.paper',
      boxShadow: 3,
      borderRadius: 2,
      zIndex: 1000,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    }} ref={popupRef} >
      <Typography variant="subtitle2" gutterBottom sx={{ color: '#0461cf', width: '100%' }}>
        Bedrooms
      </Typography>
      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'nowrap', mb: 2}}>
        {bedOptions.map(option => (
          <Button
            key={option}
            onClick={() => setBeds(option)}
            color={beds === option ? 'primary' : 'secondary'}
            sx={{ 
              minWidth: 46, 
              height: 26, 
              borderRadius: 2,
              border: 1,
              borderColor: beds === option ? '#0461cf' : 'gray',
              fontSize: '0.75rem',
              color: beds === option ? '#0461cf' : 'gray',
            }}
          >
            {option}
          </Button>
        ))}
      </Box>
      <Typography variant="subtitle2" gutterBottom sx={{ color: '#0461cf', width: '100%' }}>
        Bathrooms
      </Typography>
      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'nowrap' }}>
        {bathOptions.map(option => (
          <Button
            key={option}
            onClick={() => setBaths(option)}
            color={baths === option ? 'primary' : 'secondary'}
            sx={{ 
              minWidth: 46, 
              height: 26, 
              borderRadius: 2,
              border: 1,
              borderColor: baths === option ? '#0461cf' : 'gray',
              fontSize: '0.75rem',
              color: baths === option ? '#0461cf' : 'gray',
            }}
          >
            {option}
          </Button>
        ))}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2, mb: 1, width: '100%' }}>
        <Button variant="contained" onClick={closePopup} sx={{ 
            borderRadius: '25px', 
            width: '50%', 
            fontSize: '0.75rem', 
            padding: '4px',
        }}>Apply</Button>
      </Box>
    </Box>
  );
};

export default BedBathPopup;
