import React from "react";
import "typeface-roboto";
import './Section5Info.css'

export default function S5Info () {
    return (
        <>
        <div className="section5-container">
            <div className="info-row5">
                <div className="info-item5">
                    <p className="info-text5"> Bedroom(s) </p>
                    <p style={{ border: '1px solid black', padding: '5px', borderRadius: '10px'}}> # </p>
                </div>
                <div className="info-item5">
                    <p className="info-text5"> Bathroom(s) </p>
                    <p style={{ border: '1px solid black', padding: '5px', borderRadius: '10px'}}> # </p>
                </div>
                <div className="info-item5">
                    <p className="info-text5"> Basement </p>
                    <p style={{ border: '1px solid black', padding: '5px', borderRadius: '10px'}}> Unfinished </p>
                </div>
                <div className="info-item5">
                    <p className="info-text5"> Heating & Cooling </p>
                    <p style={{ border: '1px solid black', padding: '5px', borderRadius: '10px'}}> Central </p>
                </div>
                <div className="info-item5">
                    <p className="info-text5"> Flooring </p>
                    <p style={{ border: '1px solid black', padding: '5px', borderRadius: '10px'}}> Wood </p>
                </div>
                <div className="info-item5">
                    <p className="info-text5"> Floor Finish </p>
                    <p style={{ border: '1px solid black', padding: '5px', borderRadius: '10px'}}> Hardwood </p>
                </div>
                <div className="info-item5">
                    <p className="info-text5"> Appliances </p>
                    <p style={{ border: '1px solid black', padding: '5px', borderRadius: '10px'}}> Included </p>
                </div>
                <div className="info-item5">
                    <p className="info-text5"> Fireplace </p>
                    <p style={{ border: '1px solid black', padding: '5px', borderRadius: '10px'}}> Wood Burning </p>
                </div>
                <div className="info-item5">
                    <p className="info-text5"> Other Interior Features </p>
                    <div style={{ border: '1px solid black', padding: '5px', borderRadius: '10px'}}>
                        <p> - Fireplace features: none </p>
                        <p> - Interior livable area: 2000 sq ft </p>
                        <p> - Attic: walk up </p>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}