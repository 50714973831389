import React from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';
import NoBorderBtn from '../Buttons/NoBorder/NoBorderBtn';
import { useNavigate } from 'react-router-dom';

export default function Footer() {
    return (
        <Box component="footer" sx={{
            width: '100%',
            height: '100px',
            bgcolor: 'white',
            color: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            padding: '0 20px 0px 20px', 
            boxSizing: 'border-box',
            marginTop: 'auto'
        }}>
            <Box sx={{ display: 'flex', gap: 3, textAlign: 'left' }}>
                <NoBorderBtn text={"Profile"} style={{color: 'black', fontSize: '12px'}}/>
                <NoBorderBtn text={"Who We Are"} style={{color: 'black', fontSize: '12px'}}/>
                <NoBorderBtn text={"Contact"} style={{color: 'black', fontSize: '12px'}}/>
                <NoBorderBtn text={"Terms Of Use"} style={{color: 'black', fontSize: '12px'}} link={{url:'/terms'}}/>
                <NoBorderBtn text={"Privacy"} style={{color: 'black', fontSize: '12px'}}/>
            </Box>
            <Typography variant="body2" sx={{ textAlign: 'right', color: 'black' }}>
                Copyright © 2024 Nest.
            </Typography>
        </Box>
    );
};

