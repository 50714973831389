// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.propGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  row-gap: 45px;
  max-height: calc(100vh - 170px);
  overflow-y: scroll;
  padding-top: 70px;
}
`, "",{"version":3,"sources":["webpack://./App/Pages/component/ListingPage/PropertyView/PropertyView.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,4DAA4D;EAC5D,aAAa;EACb,+BAA+B;EAC/B,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".propGrid {\r\n  display: grid;\r\n  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));\r\n  row-gap: 45px;\r\n  max-height: calc(100vh - 170px);\r\n  overflow-y: scroll;\r\n  padding-top: 70px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
