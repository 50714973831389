import React, { useState, useEffect } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./SmallSearchBar.css";
import { styled } from "@mui/system";

const RoundedTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "7px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "7px",
  },
});

const SmallSearchBar = ({ placeholder, onChange, onSearch }) => {
  const [localValue, setLocalValue] = useState("");

  const handleClick = () => {
    setLocalValue(localValue);
    onSearch(localValue);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSearch(localValue);
    }
  };

  return (
    <div className="search-bar">
      <RoundedTextField
        variant="outlined"
        size="small"
        placeholder={placeholder}
        value={localValue}
        onChange={(e) => setLocalValue(e.target.value)}
        onKeyPress={handleKeyPress}
        className="search-input"
        sx={{ borderStyle: "bold", color: "#3c73d6" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClick}
                sx={{
                  backgroundColor: "#3c73d6",
                  paddingTop: "7px",
                  paddingBottom: "4px",
                  marginRight: "-15px",
                  zIndex: 1,
                  borderRadius: "0 7px 7px 0",
                  "&:hover": {
                    backgroundColor: "#115293",
                  },
                }}
              >
                <SearchIcon className="search-icon" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default SmallSearchBar;
