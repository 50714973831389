import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const HometypePopup = ({ homeType, setHomeType, closePopup }) => {
    const popupRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                closePopup();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [closePopup]);

    const toggleHomeType = (type) => {
        const newHomeType = { ...homeType, [type]: !homeType[type] };
        setHomeType(newHomeType);
    };

    const deselectAll = () => {
        setHomeType({
            'Single house': false,
            'Townhouse': false,
            'Co-op': false,
            'Condo': false
        });
    };

    return (
        <Box ref={popupRef} sx={{
            position: 'absolute', 
            top: '45px', 
            left: '100px', 
            width: '160px',
            p: 1,
            bgcolor: 'background.paper', 
            boxShadow: 3, 
            borderRadius: 2, 
            zIndex: 1000,
        }}>
            <FormGroup>
                {Object.entries(homeType).map(([key, value]) => (
                    <FormControlLabel
                        key={key}
                        control={<Checkbox checked={value} onChange={() => toggleHomeType(key)} size="small" 
                        sx={{ color: '#0461cf' }}/>}
                        label={key.replace(/([A-Z])/g, ' $1').trim()}
                        sx={{ marginY: '-2px', color: '#0461cf', '& .MuiTypography-root': { fontSize: '0.9rem' } }} 
                    />
                ))}
            </FormGroup>
            <Typography sx={{ 
                cursor: 'pointer', 
                textDecoration: 'underline', 
                fontSize: '0.75rem', 
                mt: 1, 
                mb: 1
            }} onClick={deselectAll}>
                deselect all
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1, mb: 1 }}>
                <Button variant="contained" onClick={closePopup} sx={{ 
                    borderRadius: '25px', 
                    width: '80%', 
                    fontSize: '0.75rem', 
                    padding: '4px'
                }}>Apply</Button>
            </Box>
        </Box>
    );
};

export default HometypePopup;
