import FeaturedArticleCard from "../../FeaturedNestCard/FeaturedNestCard"
import './LatestArticlesBoard.css'

export default function LastArticlesBoard({ news }) {
    return (
        <div className="LatestArticlesBoardMainDiv">
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <div style={{ width: '45%', minWidth: '300px' }}>
                    <FeaturedArticleCard title={news[2].title} author={news[2].author} date={news[2].date} description={news[2].description} link={{ absolute: true, url: news[2].link }} />
                </div>
                <div style={{ width: '45%', minWidth: '300px' }}>
                    <FeaturedArticleCard title={news[3].title} author={news[3].author} date={news[3].date} description={news[3].description} link={{ absolute: true, url: news[3].link }} />
                </div>
            </div>
        </div>
    )
}
