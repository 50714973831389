import React from "react";
import "./RecommendListingCard.css";
import property from "../../../../assets/images/listingPage/property.png";
import { useSelector } from "react-redux";
import heart from "../../../../assets/images/listingPage/heart.png";
import bookmark from "../../../../assets/images/listingPage/bookmark.png";
import FavoriteIcon from "@mui/icons-material/Favorite";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    Grid,
    Checkbox,
    Box,
} from "@mui/material";

function RecommendListingCard({ isTop, houseInfo, user }) {
    const isWide = useSelector((state) => state.windowSize.isWide);

    const cardsData = [
        {
            id: 1,
            houseInfo: {
                price: "1,300,000",
                bedroomNum: "3",
                bathroomNum: "2",
                img: property,
                size: "1500",
                address: "9500 Gilman Dr, La Jolla, CA 92093",
                postedBy: "Houlihan Lawrence Inc.",
                houseType: "Single family residence",
            },
        },
    ];

    const topStyle = isTop
        ? {
            borderRadius: "50px 50px 0 0",
        }
        : {};

    const loggedIn = user ? true : false;

    return (
        <div className="favProp" style={topStyle}>
            <img
                src={houseInfo.img ? houseInfo.img : property}
                alt="sampleMap"
                className="sampleImg"
            />
            {loggedIn && (
                <Checkbox
                    icon={<FavoriteIcon />}
                    checkedIcon={<FavoriteIcon />}
                    sx={{
                        position: "absolute",
                        top: "20px",
                        left: "20px",
                        color: "white",
                        "&.Mui-checked": { color: "red" },
                    }}
                />
            )}
            {loggedIn && (
                <Checkbox
                    icon={<TurnedInIcon />}
                    checkedIcon={<TurnedInIcon />}
                    sx={{
                        position: "absolute",
                        top: "20px",
                        right: "20px",
                        color: "white",
                        "&.Mui-checked": { color: "#3c73d6" },
                    }}
                />
            )}
            <div className="descriptionContainer">
                <div className="houseNumInfo">
                    <div className="price">${houseInfo.price}</div>
                    <div style={{ display: "flex" }}>
                        <div
                            className="recommendListingCardColoredText"
                            style={{ marginRight: "20px" }}
                        >
                            <span style={{ fontWeight: "bold" }}>{houseInfo.bedrooms}</span>{" "}
                            beds
                        </div>
                        <div
                            className="recommendListingCardColoredText"
                            style={{ marginRight: "20px" }}
                        >
                            <span style={{ fontWeight: "bold" }}>{houseInfo.bathrooms}</span>{" "}
                            baths
                        </div>
                        <div className="recommendListingCardColoredText">
                            <span style={{ fontWeight: "bold" }}>{houseInfo.size}</span> sq.Ft
                        </div>
                    </div>
                </div>
                <div className="address1">{houseInfo.address}</div>
                {isWide ? (
                    <div className="address1">
                        <span>{houseInfo.houseType}</span>
                        <span className="recommendListinCardPostedBy">
              Listed by {houseInfo.listedBy}
            </span>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}

export default RecommendListingCard;
