import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    comparedHomes: []
};

const comparedHomesSlice = createSlice({
    name: 'comparedHomes',
    initialState,
    reducers: {
        addHomeToCompare: (state, action) => {
            state.comparedHomes.push(action.payload);
        },
        removeHomeFromCompare: (state, action) => {
            state.comparedHomes = state.comparedHomes.filter(home => home.id !== action.payload.id);
        },
        clearComparedHomes: (state) => {
            state.comparedHomes = [];
        }
    }
});

export const { addHomeToCompare, removeHomeFromCompare, clearComparedHomes } = comparedHomesSlice.actions;

export default comparedHomesSlice.reducer;
