import './Compare.css'
import React from 'react';
import "typeface-roboto";
import { Divider, Pagination, TextField, InputAdornment, Select, MenuItem, FormControl, InputLabel } from '@mui/material';


export default function CompareInterface() {
    return (
        <>
            <h1 className='main-title' style={{ fontFamily: "'Roboto', sans-serif"}}>Compare Homes</h1>
        </>
    );
}