// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filterMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0px;
  width: 100%;
  position: relative;
  font-family: Arial, sans-serif;
}

.filterProp {
  width: auto;  
  height: auto;
  padding: 6px 12px;
  color: gray;
  background-color: white;
  border: 1px solid #0461cf;
  border-radius: 5px;
  justify-content: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
  align-items: center;
  margin: 5px 4px;
}


@media screen and (max-width: 768px) {
  .filterProp{
    margin: 5px;
  }
}

.filterProp:hover {
  background-color: rgb(254, 254, 255) !important;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block; 
  width: 0;
  height: 8px; 
  margin-left: 5px;
  margin-right: 7px;
}

.dropdown::before,
.dropdown::after {
  position: absolute;
  display: block;
  content: "";
  border: 6px solid transparent;
}
.dropdown::before {
  top: 0;
  border-top-color: #0461cf; 
}

.dropdown::after {
  top: -2px; 
  border-top-color: #ffffff; 
}
`, "",{"version":3,"sources":["webpack://./App/Pages/component/ListingPage/Filter/FilterSearch.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;EACX,kBAAkB;EAClB,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,WAAW;EACX,uBAAuB;EACvB,yBAAyB;EACzB,kBAAkB;EAClB,uBAAuB;EACvB,4DAA4D;EAC5D,eAAe;EACf,mBAAmB;EACnB,eAAe;AACjB;;;AAGA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE,+CAA+C;EAC/C,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,QAAQ;EACR,WAAW;EACX,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;;EAEE,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,6BAA6B;AAC/B;AACA;EACE,MAAM;EACN,yBAAyB;AAC3B;;AAEA;EACE,SAAS;EACT,yBAAyB;AAC3B","sourcesContent":[".filterMenu {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  margin: 15px 0px;\r\n  width: 100%;\r\n  position: relative;\r\n  font-family: Arial, sans-serif;\r\n}\r\n\r\n.filterProp {\r\n  width: auto;  \r\n  height: auto;\r\n  padding: 6px 12px;\r\n  color: gray;\r\n  background-color: white;\r\n  border: 1px solid #0461cf;\r\n  border-radius: 5px;\r\n  justify-content: center;\r\n  font-family: \"Segoe UI\", Tahoma, Geneva, Verdana, sans-serif;\r\n  font-size: 15px;\r\n  align-items: center;\r\n  margin: 5px 4px;\r\n}\r\n\r\n\r\n@media screen and (max-width: 768px) {\r\n  .filterProp{\r\n    margin: 5px;\r\n  }\r\n}\r\n\r\n.filterProp:hover {\r\n  background-color: rgb(254, 254, 255) !important;\r\n  cursor: pointer;\r\n}\r\n\r\n.dropdown {\r\n  position: relative;\r\n  display: inline-block; \r\n  width: 0;\r\n  height: 8px; \r\n  margin-left: 5px;\r\n  margin-right: 7px;\r\n}\r\n\r\n.dropdown::before,\r\n.dropdown::after {\r\n  position: absolute;\r\n  display: block;\r\n  content: \"\";\r\n  border: 6px solid transparent;\r\n}\r\n.dropdown::before {\r\n  top: 0;\r\n  border-top-color: #0461cf; \r\n}\r\n\r\n.dropdown::after {\r\n  top: -2px; \r\n  border-top-color: #ffffff; \r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
