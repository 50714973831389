import React, { useState, useRef } from "react";
import "./ProfileEditor.css";
import NoBorderBtn from "../component/Buttons/NoBorder/NoBorderBtn";
import FilledBgBtn from "../component/Buttons/FilledBg/FilledBgBtn";
import xImage from "../../assets/images/xImage.png";
import editImg from "../../assets/images/edit-icon.png";

const ProfileEditor = ({ onClose }) => {
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(URL.createObjectURL(selectedFile));
    }
  };

  const handleFileClick = () => {
    console.log("Button is clicked");
    fileInputRef.current.click();
  };

  const handleFileRemoval = () => {
    console.log("Removing Photo");
    setFile(null);
    if (fileInputRef.current) {
      console.log("Clearing file input value");
      fileInputRef.current.value = null;
    } else {
      console.log("fileInputRef.current is null");
    }
  };

  const FilledBgButtonStyle = {
    borderRadius: "50px",
    height: "45px",
    padding: "10px 50px",
    backgroundColor: "white",
    color: "#3399FF",
    border: "2px solid #3399FF",
    boxShadow: "none",
    outline: "none",
  };

  const NoBgButtonStyle = {
    color: "grey",
  };

  return (
    <div className="editor-overlay">
      <div className="editor-content">
        <span className="close-button" onClick={onClose}>
          <img src={xImage} />
        </span>
        <div className="editor-portion">
          <h1 className="Profile-editor-title">Profile Photo</h1>
          <div className="image-container">
            <img src={editImg} alt="Edit Icon" className="background-image" />
            {file && (
              <img src={file} alt="Profile" className="uploaded-image" />
            )}
          </div>
          <div className="upload-button">
            <FilledBgBtn
              text={"Upload Photo"}
              style={FilledBgButtonStyle}
              callBack={handleFileClick}
            />
            <input
              type="file"
              style={{ display: "none" }}
              accept=".jpg, .jpeg, .png, .gif"
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </div>
          {file && (
            <div className="remove-button">
              <NoBorderBtn
                text={"Remove Photo"}
                style={NoBgButtonStyle}
                callBack={handleFileRemoval}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileEditor;
