import { Typography } from "@mui/material";
import NoBorderBtn from "../../Buttons/NoBorder/NoBorderBtn";
import { Box, Card } from "@mui/material";

export default function SingleGuideCardMobile({
  title,
  description,
  buttonText,
}) {
  return (
    <Card
      sx={{
        minHeight: "150px",
        padding: "2px 3px",
        backgroundColor: "#feffff",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          p: 1, // shorthand for padding: '20px'
        }}
      >
        <Box sx={{ mb: 1 }}>
          {" "}
          {/* margin-bottom: '20px' */}
          <Typography
            variant="h5"
            component="h2"
            sx={{ fontWeight: "bold", fontSize: "1.2rem", textAlign: "center" }}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            sx={{ mt: 1.25, fontSize: "0.9rem", textAlign: "center" }}
          >
            {description}
          </Typography>
        </Box>
        <Box
          sx={{ mt: "auto" }} // marginTop: 'auto'
        >
          <NoBorderBtn
            text={buttonText}
            style={{ textDecoration: "underline" }}
          />
        </Box>
      </Box>
    </Card>
  );
}
