import React from 'react';
import { Box, Grid,  Paper } from '@mui/material';
import sampleHouse3 from '../../../../assets/images/homePage/sampleHouse3.png'
import './RegisterSection.css'
import GetLatestNewsForm from "../../GetLatestNewsForm/GetLatestNewsForm";

export default function RegisterSection() {
    const sampleHouseImage = sampleHouse3;
    
    return (
        <Paper elevation={0} className="BrowseSectionPaper">
            <Grid container className="BrowseSectionPaperMainGrid">
                <Grid item xs={12} md={6}>
                    <Box
                        component="img"
                        sx={{
                            height: '100%',
                            width: '100%',
                            display: 'block', 
                        }}
                        alt="House"
                        src={sampleHouseImage}
                    />
                </Grid>
                <GetLatestNewsForm />
            </Grid>
        </Paper>
    );
}

