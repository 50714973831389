// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
    font-family: 'Roboto', sans-serif;
    width: 100%; /* Ensure the table container takes full width */
    margin-top: 20px; /* Added margin-top for spacing */
}

.table-row {
    border-bottom: 1px solid #ddd; /* Borderline between rows */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
}

.table-cell {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

.arrow-image {
    margin-right: 10px;
    transform: rotate(-90deg); 
    transition: transform 0.3s ease;
    filter: invert(33%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(91%) contrast(89%);
    transform-origin: center;
}

.arrow-image.rotate {
    transform: rotate(90deg);
}

.showmore-text {
    margin-left: 8px;
    font-size: 16px;
    font-weight: 300;
    color: grey;
}

.toggleb-container {
    display: flex;
    align-items: center;
}

.toggle-button {
    border: none;
    cursor: pointer;
    color: grey;
}

.p1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./App/Pages/component/BuyPageComps/ShowMoreRows.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,WAAW,EAAE,gDAAgD;IAC7D,gBAAgB,EAAE,iCAAiC;AACvD;;AAEA;IACI,6BAA6B,EAAE,4BAA4B;IAC3D,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;AAChB;;AAEA;IACI,SAAS;IACT,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,+BAA+B;IAC/B,2FAA2F;IAC3F,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC","sourcesContent":[".table-container {\r\n    font-family: 'Roboto', sans-serif;\r\n    width: 100%; /* Ensure the table container takes full width */\r\n    margin-top: 20px; /* Added margin-top for spacing */\r\n}\r\n\r\n.table-row {\r\n    border-bottom: 1px solid #ddd; /* Borderline between rows */\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    padding: 5px;\r\n}\r\n\r\n.table-cell {\r\n    margin: 0;\r\n    padding: 0;\r\n    font-size: 14px;\r\n}\r\n\r\n.arrow-image {\r\n    margin-right: 10px;\r\n    transform: rotate(-90deg); \r\n    transition: transform 0.3s ease;\r\n    filter: invert(33%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(91%) contrast(89%);\r\n    transform-origin: center;\r\n}\r\n\r\n.arrow-image.rotate {\r\n    transform: rotate(90deg);\r\n}\r\n\r\n.showmore-text {\r\n    margin-left: 8px;\r\n    font-size: 16px;\r\n    font-weight: 300;\r\n    color: grey;\r\n}\r\n\r\n.toggleb-container {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.toggle-button {\r\n    border: none;\r\n    cursor: pointer;\r\n    color: grey;\r\n}\r\n\r\n.p1 {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
