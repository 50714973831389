// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewsPageMainDiv {
    padding-top: 70px;
}

.NewsPageElements {
    width: 75%;
    min-width: 600px;
}

.GetLatestNewsDiv {
    margin-top: 50px;
    background-color: rgb(233, 233, 233);
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 60px 0px;
}`, "",{"version":3,"sources":["webpack://./App/Pages/NewsPage/NewsPage.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,oCAAoC;IACpC,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,iBAAiB;AACrB","sourcesContent":[".NewsPageMainDiv {\r\n    padding-top: 70px;\r\n}\r\n\r\n.NewsPageElements {\r\n    width: 75%;\r\n    min-width: 600px;\r\n}\r\n\r\n.GetLatestNewsDiv {\r\n    margin-top: 50px;\r\n    background-color: rgb(233, 233, 233);\r\n    width: 100%;\r\n    display: flex;\r\n    justify-content: center;\r\n    padding: 60px 0px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
