import RecommendListingCard from "../ListingPage/RecommendListingCard/RecommendListingCard"
import sampleHouse1 from '../../../assets/images/homePage/sampleHouse1.png';
import sampleHouse2 from '../../../assets/images/homePage/sampleHouse2.png';
import sampleHouse3 from '../../../assets/images/homePage/sampleHouse3.png';

export default function ListingSearchResults({ houseInfo }) {
    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginBottom: '15px' }}>
            <p style={{ fontWeight: 'lighter', textAlign: 'center', marginTop: '0px' }}>search results:</p>
            {
                houseInfo.map(card => (
                    <div style={{ margin: '10px 0px 20px 0px' }}>
                        <RecommendListingCard
                            key={card.id}
                            id={card.id}
                            houseInfo={card}
                        />
                    </div>
                ))
            }
        </div>
    )
}

// const cardsData = [
//     { id: 1, houseInfo: { price: '1,300,000', bedroomNum: '3', bathroomNum: '2', img: sampleHouse1, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
//     { id: 2, houseInfo: { price: '1,300,000', bedroomNum: '3', bathroomNum: '2', img: sampleHouse2, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
//     { id: 3, houseInfo: { price: '1,300,000', bedroomNum: '3', bathroomNum: '2', img: sampleHouse2, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
//     { id: 4, houseInfo: { price: '1,300,000', bedroomNum: '3', bathroomNum: '2', img: sampleHouse1, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
//     { id: 5, houseInfo: { price: '1,300,000', bedroomNum: '3', bathroomNum: '2', img: sampleHouse2, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
//     { id: 6, houseInfo: { price: '1,300,000', bedroomNum: '3', bathroomNum: '2', img: sampleHouse2, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
//     { id: 7, houseInfo: { price: '1,300,000', bedroomNum: '3', bathroomNum: '2', img: sampleHouse2, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
//     { id: 8, houseInfo: { price: '1,300,000', bedroomNum: '3', bathroomNum: '2', img: sampleHouse2, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
// ];