// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.browseSectionMainDiv {
    margin-top: 100px;
    height: 40vh;
    min-height: 600px;
    display: flex;
    align-items: space-around;
    width: 100%;
    flex-wrap: nowrap;
}

.BulletCardsDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 5%;
    padding-right: 20px;
    flex: 0 0 0px;
    flex-wrap: nowrap;
}

.CarsoualMainDiv {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 30px;
    flex-wrap: nowrap;
    flex: 1;
}


@media (max-width: 768px) {
    .browseSectionMainDiv {
        flex-direction: column;
    }

    .BulletCardsDiv {
        width: 100%;
        order: 2;
        padding: 0;
        align-items: stretch;
    }

    .CarsoualMainDiv {
        width: 100%;
        order: 1;
        padding: 0;
    }
}`, "",{"version":3,"sources":["webpack://./App/Pages/component/FinalLandingPage/BrowseSection/BrowseSection.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,yBAAyB;IACzB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,uBAAuB;IACvB,kBAAkB;IAClB,iBAAiB;IACjB,OAAO;AACX;;;AAGA;IACI;QACI,sBAAsB;IAC1B;;IAEA;QACI,WAAW;QACX,QAAQ;QACR,UAAU;QACV,oBAAoB;IACxB;;IAEA;QACI,WAAW;QACX,QAAQ;QACR,UAAU;IACd;AACJ","sourcesContent":[".browseSectionMainDiv {\r\n    margin-top: 100px;\r\n    height: 40vh;\r\n    min-height: 600px;\r\n    display: flex;\r\n    align-items: space-around;\r\n    width: 100%;\r\n    flex-wrap: nowrap;\r\n}\r\n\r\n.BulletCardsDiv {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    height: 100%;\r\n    padding: 0 5%;\r\n    padding-right: 20px;\r\n    flex: 0 0 0px;\r\n    flex-wrap: nowrap;\r\n}\r\n\r\n.CarsoualMainDiv {\r\n    height: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n    align-items: flex-start;\r\n    padding-left: 30px;\r\n    flex-wrap: nowrap;\r\n    flex: 1;\r\n}\r\n\r\n\r\n@media (max-width: 768px) {\r\n    .browseSectionMainDiv {\r\n        flex-direction: column;\r\n    }\r\n\r\n    .BulletCardsDiv {\r\n        width: 100%;\r\n        order: 2;\r\n        padding: 0;\r\n        align-items: stretch;\r\n    }\r\n\r\n    .CarsoualMainDiv {\r\n        width: 100%;\r\n        order: 1;\r\n        padding: 0;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
