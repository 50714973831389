import React, { useState } from "react";
import "./SellPage.css";
import topImage from "../../assets/images/sellPage/sell-my-home.png";
import Navbar from "../component/Navigation/NavBar";
import NavbarMobile from "../component/Navigation/NavBarMobile";
import Footer from "../component/Footer/Footer";
import FooterMobile from "../component/Footer/FooterMobile";
import fileUploadImage from "../../assets/images/sellPage/file-upload.png";
import arrowImage from "../../assets/images/sellPage/Arrow.png";
import FilledBgBtn from "../component/Buttons/FilledBg/FilledBgBtn";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function SellPage() {
  // dropdown menu
  const [option, setOption] = useState("");
  const handleOptionChange = (event) => {
    setOption(event.target.value);
  };

  const navigate = useNavigate();
  const isWide = useSelector((state) => state.windowSize.isWide);
  // file upload
  const [file1, setFile1] = useState(null); // 保存文件的状态
  const [file2, setFile2] = useState(null); // 保存文件的状态
  const file1InputRef = React.useRef(null);
  const file2InputRef = React.useRef(null);
  // 处理文件选择框的点击事件
  const handleClick1 = () => {
    // 触发input元素的点击事件
    file1InputRef.current.click();
  };
  const handleClick2 = () => {
    // 触发input元素的点击事件
    file2InputRef.current.click();
  };
  // 处理文件的变更事件
  const handleFile1Change = (event) => {
    const file = event.target.files[0];
    setFile1(file); // 更新文件状态为选中的文件
    if (file) {
      const validTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "application/pdf",
      ];
      if (!validTypes.includes(file.type)) {
        alert("Unsupported file type.");
        setFile1(null);
        return;
      }
      // 进行文件处理或状态更新
      console.log("Uploading:", file.name);
    }
  };
  // 处理文件的变更事件
  const handleFile2Change = (event) => {
    const file = event.target.files[0];
    setFile2(file); // 更新文件状态为选中的文件
    if (file) {
      const validTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "application/pdf",
      ];
      if (!validTypes.includes(file.type)) {
        alert("Unsupported file type.");
        setFile2(null);
        return;
      }
      // 进行文件处理或状态更新
      console.log("Uploading:", file.name);
    }
  };

  const handleSubmit = () => {};

  // checkbox
  const [checked, setChecked] = useState(false); // 初始化复选框的选中状态
  const toggleCheckbox = () => {
    console.log("Checkbox is checked:", checked); // 打印选中状态
    setChecked(!checked); // 切换选中状态
  };

  const FilledBgButtonStyle = {
    borderRadius: "10px",
    padding: "10px 50px",
    backgroundColor: "#3c73d6",
    marginTop: "10px",
    width: "50%",
    maxWidth: "500px",
    minWidth: "250px",
    height: isWide ? "65px" : "50px",
  };

  return (
    <>
      {isWide ? <Navbar /> : <NavbarMobile />}
      <div className="fullScreen">
        <div className="sellPageMainDiv">
          <div className="arrow" onClick={() => navigate("/")}>
            <img src={arrowImage} />
            <span className="back">Back to Home</span>
          </div>

          <div className="sellCoverImageMainDiv">
            <img src={topImage} className="sellCoverImage" alt="Cover" />
            <div className="overlay-text">Sell My Home</div>
          </div>

          <div className="top-text">
            The legal owner of the selling property must submit this form. Any
            third party individual representing the owner of the property cannot
            submit this form on behalf of the property owner unless the third
            party is statutory representative and/or legal solicitor. Please
            fill out the form. At the end of this form, you will need to upload
            two documents: A photo copy of your Driver's License or Federal ID
            and the Property Records of your selling property. Your personal
            data is securely protected by the AWS Data Security and is never
            shared or sold to any third party. As soon as you delete your data
            from the "My Account", your data is irrevocably deleted and the
            caches are cleared from the cloud server.
          </div>

          <div className="form-container">
            <div>
              <label
                htmlFor="name"
                style={{ marginTop: "60px" }}
                className="label"
              >
                Legal Full Name
              </label>
              <input name="name" className="inputField" />
            </div>

            <div>
              <label htmlFor="phone" className="label">
                Phone Number
              </label>
              <input name="phone" className="inputField" />
            </div>

            <div>
              <label htmlFor="email" className="label">
                Email Address
              </label>
              <input name="email" className="inputField" />
            </div>

            <div>
              <label htmlFor="residency" className="label">
                Current Residency Address
              </label>
              <input name="residency" className="inputField" />
            </div>

            <div>
              <label htmlFor="property" className="label">
                Selling Property's Address
              </label>
              <input name="property" className="inputField" />
            </div>

            <div className="sellPageSelectionContainer">
              <label className="label" htmlFor="home-type1">
                Home Type
              </label>
              <select
                id="home-type1"
                className="selectField"
                value={option}
                onChange={handleOptionChange}
              >
                <option value="choose">Home Type</option>
                <option value="home1">Home 1</option>
                <option value="home1">Home 2</option>
              </select>

              <label className="label" htmlFor="home-type2">
                Willing To Sell
              </label>
              <select
                id="home-type2"
                className="selectField"
                value={option}
                onChange={handleOptionChange}
              >
                <option value="choose">Willing To Sell (choose one)</option>
                <option value="home1">option 1</option>
                <option value="home1">option 2</option>
              </select>
            </div>

            <div>
              <label htmlFor="price" className="label">
                Pre-determined Asking Price in Dollars after the $ sign
              </label>
              <input name="price" className="inputField" />
            </div>

            <div>
              <label htmlFor="range" className="label">
                Acceptable Price Range Between (Below and Above)
              </label>
              <input name="range" className="inputField" />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
                alignItems: "center",
                width: "75%",
                minWidth: "200px",
                minHeight: "530px",
                marginTop: "20px",
                justifyContent: "space-between",
              }}
            >
              <div>
                <label htmlFor="file1" className="label">
                  Property Records (Tax Records)
                </label>
                <div className="upload-box" onClick={handleClick1}>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    accept=".jpg, .jpeg, .png, .gif, .pdf"
                    ref={file1InputRef}
                    onChange={handleFile1Change}
                  />
                  {!file1 && <img src={fileUploadImage} />}
                  {file1 && (
                    <p
                      style={{ display: "flex", textAlign: "center" }}
                      className="label"
                    >
                      File name: {file1.name}
                    </p>
                  )}
                </div>
                <label
                  style={{ marginBottom: "60px", opacity: "60%" }}
                  className="label"
                >
                  jpg, jpeg, png, gif, pdf files are only acceptable
                </label>
              </div>

              <div>
                <label htmlFor="file2" className="label">
                  Drivers License or Federal ID
                </label>
                <div className="upload-box" onClick={handleClick2}>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    accept=".jpg, .jpeg, .png, .gif, .pdf"
                    ref={file2InputRef}
                    onChange={handleFile2Change}
                  />
                  {!file2 && <img src={fileUploadImage} />}
                  {file2 && <img src={file2} />}
                </div>
                <label
                  style={{ marginBottom: "60px", opacity: "60%" }}
                  className="label"
                >
                  jpg, jpeg, png, gif, pdf files are only acceptable
                </label>
              </div>
            </div>

            <div className="consent-form-mainDiv">
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={toggleCheckbox}
                  className="checkbox-input"
                />
                <span className="checkmark"></span>
                <span className="checkbox-label">Consent Form</span>
              </label>

              <div className="consent-form">
                <p>
                  By submitting this to NEST, I intend to list the above
                  property, in which I legally own, for selling. I accept that I
                  will receive phone calls, messages, and/or emails from NEST's
                  designated and registered an agent. I declare that above
                  information is true by the time of this submission. I
                  understand that submitting this form does not automatically
                  creates any contractual relationship with NEST until the
                  agent's presence and duly sign on the NEST's agent
                  agreement/contract.
                </p>
                <FilledBgBtn
                  text={"Submit"}
                  style={FilledBgButtonStyle}
                  link={{ absolute: true, url: "https://www.google.com" }}
                  callBack={() => handleSubmit()}
                />
              </div>
            </div>
          </div>
        </div>
        {isWide ? <Footer /> : <FooterMobile />}
      </div>
    </>
  );
}
