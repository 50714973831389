import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export default function CompareTabs({value, handleChange}) {  // Add a prop for the

    return (
        <div>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider'
                }}>
                <Tab label="Overview" sx={{ textTransform: 'capitalize' }} />
                <Tab label="Interior" sx={{ textTransform: 'capitalize' }} />
                <Tab label="Property" sx={{ textTransform: 'capitalize' }} />
                <Tab label="HOA & Financial" sx={{ textTransform: 'capitalize' }} />
                <Tab label="Community" sx={{ textTransform: 'capitalize' }} />
            </Tabs>
        </div>
    );
}
