import React, { useState } from 'react';
import "./WhoWeAre.css"; 
import Footer from "../component/Footer/Footer";
import wwr from "../../assets/images/WhoWeArePage/StockImage1.png";
import wwr2 from "../../assets/images/WhoWeArePage/StockImage2.png";
import { Typography } from "@mui/material";
import Navbar from "../component/Navigation/NavBar";
import FooterMobile from '../component/Footer/FooterMobile';
import NavbarMobile from '../component/Navigation/NavBarMobile';
import { useSelector } from 'react-redux'

const WhoWeAre = () => {
  const isWide = useSelector(state => state.windowSize.isWide || false) ;
  return (
<div className="who-we-are">
{isWide ? <Navbar /> : <NavbarMobile />}
      {/* Title Section */}
      <div className="title-container">
        <div className="title-header">
          <Typography className="title-text">Who We Are</Typography>
          <Typography className="title-subtext">
            Meet the team behind Nest
          </Typography>
        </div>
      </div>
      {/* Mission Section */}
      <section className="mission">
        <div className="missioncontainer">
          <div className="missionpara">
            <Typography variant="h2">Our Mission</Typography>
            <Typography className="typography">
              NEST pioneers the new era of real estate market by launching its
              new home shopping A.I. tool that leads the customers to enjoy
              flawless transaction processes and intuitive searching experiences
              to make you, as a buyer or a seller, save time, money, and effort
              and ease your stress. Ultimately, NEST gives perfect matches for
              the properties that you ever wanted and ever needed even at lower
              cost. Be smart, do simple, pay less, earn more, find right, and
              live better. NEST is the answer.
            </Typography>
          </div>
          <div className="mission-img">
            <img src={wwr} alt="Mission" />
          </div>
        </div>
      </section>
      {/* Features Section */}
      <section className="features">
        <div className="featurescontainer">
          <div className="featuresimg">
            <img src={wwr2} alt="Feature Image" />
          </div>
          <div className="featurespara">
            <Typography variant="h3">Why use Nest?</Typography>
            <Typography className="typography">
              NEST will make the property shopping experience more intuitive and
              enjoyable. While the users can benefit from all the futuristic
              A.I. performance features, they can even further enjoy hassle-free
              transactions guided by the NEST’s designated professional real
              estate agents. With NEST, there is no waste of time, money, and
              physical efforts for real estate trading. Let the future of the
              real estate market be super simple and enjoyable.
            </Typography>
            <div className="button-container">
              <a href="#explore" className="btn">
                Explore Features
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Section */}
      <section className="contact">
        <div className="contactContainer">
          <div className="contact-left">
            <Typography variant="h2">Contact</Typography>
            <Typography className="contact-header">
              For customer and technical support DIAL{" "}
              <a href="tel:###-###-####">###-###-####</a>
            </Typography>
            <Typography className="contact-details">
              Operating hours: __ - __, Mon-Fri
              <br />
              For 24/7 support, see Nest's premium package (EXAMPLE)
            </Typography>
            <div className="contact-form">
              <form>
                <div className="form-row">
                  <div>
                    <label htmlFor="name">Full Name</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="input-text"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="input-text"
                      required
                    />
                  </div>
                </div>
                <div className="full-width">
                  <label htmlFor="message">Message (under 500 words)</label>
                  <textarea
                    id="message"
                    name="message"
                    className="textarea-message"
                    required
                  ></textarea>
                </div>
                <button type="submit">Email Nest</button>
              </form>
            </div>
          </div>
          <div className="contact-right">
            <div className="contact-address">
              <Typography className="address-title">Mailing address</Typography>
              <address>
                Nest, Inc.
                <br />
                Attn: Brokerage Operations
                <br />
                Random #, Random building, Floor 36
                <br />
                Random city, NY 00000
              </address>
            </div>
          </div>
        </div>
      </section>
      {/* Footer */}
      {isWide ? <Footer /> : <FooterMobile />}
      </div>
  );
};

export default WhoWeAre;
