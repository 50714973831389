import React from 'react';
import { Card, CardContent, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { removeHomeFromCompare } from '../../../redux/slice/comparedHomesSlice';
import { useDispatch } from 'react-redux';
import FilledBgBtn from '../Buttons/FilledBg/FilledBgBtn';

const CompareCard = ({ properties, onClose }) => {
    const dispatch = useDispatch();

    const handleRemove = (property) => {
        dispatch(removeHomeFromCompare(property));
    };

    const filledButtonStyle = {
        backgroundColor: '#fafafa',
        color: '#3c73d6',
        height: '50px',
        borderRadius: '20px',
        padding: '10px 40px',
        '&:hover': {
            backgroundColor: '#3c73d6',
            color: '#fafafa',
        }
    };

    const removeButtonStyle = {
        position: 'absolute', 
        width: '25px', 
        height: '25px', 
        top: '-13px', 
        left: '-13px', 
        backgroundColor: '#5B5A5A', 
        color: '#fafafa', 
        padding: '15px',
        '&:hover': {
            backgroundColor: '#fafafa',
            color: '#5B5A5A',
        }
    }

    return (
        <Box sx={{ width: '100%', padding: '20px', display: 'flex', justifyContent: 'center', boxSizing: 'border-box', backgroundColor: 'white', display: properties.length > 0 ? 'block' : 'none', boxShadow: 3, marginTop: '20px' }}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Box sx={{ width: '80%', minWidth: '1200px', display: 'flex', justifyContent: 'space-around' }}>
                    <div style={{ display: 'flex' }}>
                        {properties.map((property, index) => (
                            <Box key={index} sx={{ margin: '10px', width: '200px', position: 'relative', marginRight: '30px' }}>
                                <IconButton
                                    sx={removeButtonStyle}
                                    onClick={() => handleRemove(property)}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <img src={property.image} alt="property" style={{ width: '100%' }} />
                            </Box>
                        ))}
                    </div>
                    <div style={{ marginLeft: 'auto', maxWidth: '200px', display: 'flex', alignItems: 'center' }}>
                        <FilledBgBtn text={'Compare'} style={filledButtonStyle} />
                    </div>
                </Box>
            </div>
        </Box>
    );
};

export default CompareCard;