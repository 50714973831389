import React from "react";
import "./ConfirmPopup.css";
import FilledBgBtn from "../../Buttons/FilledBg/FilledBgBtn";

const ConfirmPopup = ({ isOpen, onClose, onConfirm, children }) => {
  if (!isOpen) {
    return null;
  }
  const filledBtnStyle = {
    fontSize: "17px",
    textTransform: "none",
    capitalize: "none",
    borderRadius: "10px",
    paddingLeft: "3px",
    paddingRight: "3px",
    boarderColor: "#3c73d6",
    backgroundColor: "#3c73d6",
    width: "25%",
    maxWidth: "500px",
    minWidth: "50px",
    height: "40px",
  };
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-body">{children}</div>
        <div className="popup-actions">
          <FilledBgBtn
            text={"Cancel"}
            style={filledBtnStyle}
            callBack={() => onClose()}
            className="popup-button"
          />
          <FilledBgBtn
            text={"Confirm"}
            style={filledBtnStyle}
            callBack={() => onConfirm()}
            className="popup-button"
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
