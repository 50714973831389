import React from "react";
import "./PropertyView.css";
import RectangleCard from "../../Cards/RectangleCard/RectangleCard";
import sampleHouse1 from "../../../../assets/images/homePage/sampleHouse1.png";
import sampleHouse2 from "../../../../assets/images/homePage/sampleHouse2.png";
import sampleHouse3 from "../../../../assets/images/homePage/sampleHouse3.png";

function PropertyView({ houseInfo, user }) {
  return (
    <div className="propGrid">
      {houseInfo.map((card) => (
        <RectangleCard
          key={card.propertyId}
          id={card.id}
          houseInfo={card}
          user={user}
        />
      ))}
    </div>
  );
}

export default PropertyView;

//   const cardsData = [
//     { id: 1, houseInfo: { price: '1,300,000', bedroom: '3', bathroom: '2', img: sampleHouse1, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
//     { id: 2, houseInfo: { price: '1,300,000', bedroom: '3', bathroom: '2', img: sampleHouse2, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
//     { id: 3, houseInfo: { price: '1,300,000', bedroom: '3', bathroom: '2', img: sampleHouse2, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
//     { id: 4, houseInfo: { price: '1,300,000', bedroom: '3', bathroom: '2', img: sampleHouse1, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
//     { id: 5, houseInfo: { price: '1,300,000', bedroom: '3', bathroom: '2', img: sampleHouse2, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
//     { id: 6, houseInfo: { price: '1,300,000', bedroom: '3', bathroom: '2', img: sampleHouse2, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
//     { id: 7, houseInfo: { price: '1,300,000', bedroom: '3', bathroom: '2', img: sampleHouse2, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
//     { id: 8, houseInfo: { price: '1,300,000', bedroom: '3', bathroom: '2', img: sampleHouse2, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
// ];
