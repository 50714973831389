import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import axios from "axios";
import Constants from "expo-constants";
const PORT = Constants.expoConfig.extra.PORT;

export async function handleGoogle() {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const idToken = await result.user.getIdToken();
    await postToken(idToken);
    return idToken;
  } catch (error) {
    console.error("Google sign-in error:", error.message);
  }
}

export async function postToken(idToken) {
  try {
    const response = await axios.post(
      `http://ec2-18-218-73-66.us-east-2.compute.amazonaws.com:${PORT}/api/user/login-Google`,
      {
        idToken,
      }
    );
  } catch (error) {
    console.error(
      "Login failed:",
      error.response ? error.response.data : error.message
    );
  }
}

export async function registerUser(userData, onSuccess, onError) {
    await axios.post(`http://ec2-18-218-73-66.us-east-2.compute.amazonaws.com:${PORT}/api/user`, userData)
        .then(response => {
            // Handle success
            console.log('Registration successful:', response.data);
            // onSuccess(response.data);
        })
        .catch(error => {
            // Handle error
            console.error('Registration failed:', error.response ? error.response.data : error.message);
            // onError(error);
        });
}
