// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mapContainer {
    width: 100%;
    height: calc(100vh - 280px);
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-right: 0;
    position: relative;
}

@media only screen and (max-width: 768px) {
    .mapContainer {
        padding: 0;
        width: 100%;
        height: 40vh;
        min-height: 300px;
    }
}`, "",{"version":3,"sources":["webpack://./App/Pages/component/ListingPage/MapboxMap/MapboxMap.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,2BAA2B;IAC3B,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI;QACI,UAAU;QACV,WAAW;QACX,YAAY;QACZ,iBAAiB;IACrB;AACJ","sourcesContent":[".mapContainer {\r\n    width: 100%;\r\n    height: calc(100vh - 280px);\r\n    justify-content: center;\r\n    align-items: center;\r\n    padding: 10px;\r\n    padding-right: 0;\r\n    position: relative;\r\n}\r\n\r\n@media only screen and (max-width: 768px) {\r\n    .mapContainer {\r\n        padding: 0;\r\n        width: 100%;\r\n        height: 40vh;\r\n        min-height: 300px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
