import React, { useEffect, useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Checkbox,
  Box,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDispatch } from "react-redux";
import {
  addHomeToCompare,
  removeHomeFromCompare,
} from "../../../redux/slice/comparedHomesSlice";
import { useSelector } from "react-redux";
import sampleHouse1 from "../../../assets/images/homePage/sampleHouse1.png";

const SavedHomeCard = ({
  property,
  isMaxSelectionReached,
  isListingSelected,
}) => {
  const dispatch = useDispatch();
  const comparedHomes = useSelector(
    (state) => state.comparedHomes.comparedHomes
  );
  const isChecked = comparedHomes.some((home) => home.id === property.id);
  const [checked, setChecked] = useState(isChecked);

  const handleCompareChange = (event) => {
    if (event.target.checked) {
      dispatch(addHomeToCompare(property));
    } else {
      dispatch(removeHomeFromCompare(property));
    }
  };

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <Card
      sx={{
        width: "345px",
        margin: "10px auto",
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
        boxShadow: 3,
        boxSizing: "border-box",
      }}
    >
      <CardContent
        sx={{ padding: "10px 20px 0px 20px", boxSizing: "border-box" }}
      >
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
        >
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon />}
            checkedIcon={<CheckBoxIcon />}
            sx={{ color: "#3c73d6", "&.Mui-checked": { color: "#3c73d6" } }}
            checked={checked}
            onChange={handleCompareChange}
            disabled={isMaxSelectionReached && !isListingSelected}
          />
          <Typography
            variant="body1"
            color={
              isMaxSelectionReached && !isListingSelected ? "grey" : "#3c73d6"
            }
            fontWeight="bold"
          >
            Compare
          </Typography>
        </div>
        <div style={{ position: "relative" }}>
          <img
            src={property.image}
            alt="property"
            style={{ width: "100%", height: "200px", objectFit: "cover" }}
          />
          <Checkbox
            icon={<FavoriteIcon />}
            checkedIcon={<FavoriteIcon />}
            sx={{
              position: "absolute",
              top: "10px",
              left: "10px",
              color: "white",
              "&.Mui-checked": { color: "red" },
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            alignItems: "left",
            marginTop: "10px",
          }}
        >
          <div style={{ display: "flex", width: "100%" }}>
            <p style={{ margin: 0, marginRight: "20px" }}>
              <span style={{ fontWeight: "bold" }}>$</span>
              {property.price ? property.price : 550000}
            </p>
            <p style={{ margin: 0, marginRight: "10px" }}>
              <span style={{ fontWeight: "bold", marginRight: "2px" }}>
                {property.beds}
              </span>
              bd
            </p>
            <p style={{ margin: 0, marginRight: "10px" }}>
              <span style={{ fontWeight: "bold", marginRight: "2px" }}>
                {property.baths}
              </span>
              ba
            </p>
            <p style={{ margin: 0, marginRight: "10px" }}>
              <span style={{ fontWeight: "bold", marginRight: "2px" }}>
                {property.sqft}
              </span>
              sqf
            </p>
          </div>
          <p
            style={{
              margin: 0,
              fontSize: "13px",
              marginTop: "5px",
              textAlign: "left",
            }}
          >
            {property.address}
          </p>
          <p
            style={{
              margin: 0,
              fontSize: "13px",
              marginTop: "5px",
              textAlign: "left",
            }}
          >
            {property.city}, {property.state} {property.zip}
          </p>
        </div>
        <hr />
        <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ marginBottom: "10px" }}>
              <Typography variant="body2" fontWeight="bold">
                Type
              </Typography>
              <Typography variant="body2">{property.type}</Typography>
            </Box>
            <Box sx={{ marginBottom: "10px" }}>
              <Typography variant="body2" fontWeight="bold">
                Lot
              </Typography>
              <Typography variant="body2">{property.lotSize} sqft</Typography>
            </Box>
            <Box sx={{ marginBottom: "10px" }}>
              <Typography variant="body2" fontWeight="bold">
                Year Built
              </Typography>
              <Typography variant="body2">{property.yearBuilt}</Typography>
            </Box>
            <Box sx={{ marginBottom: "10px" }}>
              <Typography variant="body2" fontWeight="bold">
                Heating
              </Typography>
              <Typography variant="body2">{property.heating}</Typography>
            </Box>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="body2" fontWeight="bold">
                Cooling
              </Typography>
              <Typography variant="body2">{property.cooling}</Typography>
            </Box>
            <Box>
              <Typography variant="body2" fontWeight="bold">
                Parking
              </Typography>
              <Typography variant="body2">{property.parking}</Typography>
            </Box>
            <Box>
              <Typography variant="body2" fontWeight="bold">
                Price/sqft
              </Typography>
              <Typography variant="body2">${property.pricePerSqft}</Typography>
            </Box>
          </div>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SavedHomeCard;
