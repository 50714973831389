import axios from "axios";
import Constants from "expo-constants";

const PORT = Constants.expoConfig.extra.PORT;
const URL = Constants.expoConfig.extra.API_BASE_URL;
const baseUrl = `${URL}:${PORT}/api`;

export const fetchNewsData = async () => {
  try {
    const newsUrl = baseUrl + "/news";
    const response = await axios.get(newsUrl);
    let entries = response.data.map((item) => {
      const authorNameMatch = item.author.match(/\(([^)]+)\)/);
      const authorName = authorNameMatch ? authorNameMatch[1] : "N/A";

      return {
        title: item.title,
        description: item.description,
        date: new Date(item.date).toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        }),
        link: item.link,
        author: authorName,
      };
    });

    return entries;
  } catch (error) {
    console.error("Error fetching news data:", error);
    throw error;
  }
};

export const fetchAllListingsData = async () => {
  try {
    const url = baseUrl + "/listings";
    const response = await axios.get(url);
    const dataArray = Object.values(response.data);
    return dataArray;
  } catch (error) {
    console.error("Error fetching listings data:", error);
    throw error;
  }
};

export const fetchOneListingData = async (propertyID) => {
  try {
    const url = `${baseUrl}/listings/${propertyID}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(
      `Error fetching listing data for propertyID ${propertyID}:`,
      error
    );
    throw error;
  }
};


export const fetchUserData = async (userID) => {
  try {
    const url = baseUrl + `/user/${userID}`;
    const response = await axios.get(url);
    return response.data;
  } 
  catch (error) {
    console.error('Error fetching user data:', error);
    throw error; 
  }
};

export const updateUserData = async (userID, updateData) => {
  
  try {
    const url = baseUrl + `/user/${userID}`;
    const response = await axios.put(url, updateData);
    return response.data;
  } 
  catch (error) {
    console.error('Error fetching user data:', error);
    throw error; 
  }
}