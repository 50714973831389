import house1 from "../../assets/images/comparePage/house1.jpeg";
import house2 from "../../assets/images/comparePage/house2.jpeg";


const housesData = {
  house1: {
      image: house1,
      overviewContent: {
        status: 'off market',
        listPrice: 'insert price',
        pricePerSqFt: 'insert price/#',
        bedrooms: 'insert # here',
        bathrooms: 'insert # here',
        squareFt: 'insert # here'
      },
      interiorContent: {
        homeType: 'off market',
        condition: 'insert price',
        architecturalStyle: 'insert price/#',
        newConstruction: 'insert # here',
        heating: 'insert # here',
        cooling: 'insert # here'
      },
      propertyContent: {
        lotSize: '2000 sq ft',
        lotFeatures: 'city lot',
        parkingSpaces: 'insert # here',
        parkingFeatures: 'insert features here',
        patioPorch: 'insert feature here',
        fencing: 'insert feature here'
      },
      hoaFinancialContent: {
        annualTax: '$3,000',
        hoa: 'YES',
        taxAssessedValue: 'insert price/#',
        buyersAgentFee: 'insert # here',
        offerReviewDate: 'insert # here',
        listingTerms: 'insert # here'
      },
      communityContent: {
        cityTown: 'Fort Lee',
        walkScore: '80',
        transitScore: '75',
        schoolsRating: 'Elementary: 7\nMiddle: 7\nHigh: 10',
        commuteTime: 'insert # here'
      }
    },
  house2: {
      image: house2,
      overviewContent: {
        status: 'for sale',
        listPrice: 'insert price',
        pricePerSqFt: 'insert price/#',
        bedrooms: 'insert # here',
        bathrooms: 'insert # here',
        squareFt: 'insert # here'
      },
      interiorContent: {
        homeType: 'for sale',
        condition: 'insert price',
        architecturalStyle: 'insert price/#',
        newConstruction: 'insert # here',
        heating: 'insert # here',
        cooling: 'insert # here'
      },
      propertyContent: {
        lotSize: '1600 sq ft',
        lotFeatures: 'suburban',
        parkingSpaces: 'insert # here',
        parkingFeatures: 'insert features here',
        patioPorch: 'insert feature here',
        fencing: 'insert feature here'
      },
      hoaFinancialContent: {
        annualTax: '$5,000',
        hoa: 'YES/NO',
        taxAssessedValue: 'insert price/#',
        buyersAgentFee: 'insert # here',
        offerReviewDate: 'insert # here',
        listingTerms: 'insert # here'
      },
      communityContent: {
        cityTown: 'Teaneck',
        walkScore: '75',
        transitScore: '75',
        schoolsRating: 'Elementary: 5\nMiddle: 7\nHigh: 10',
        commuteTime: 'insert # here'
      }
  },
  house3: {
      image: house1,
      overviewContent: {
        status: 'off market',
        listPrice: 'insert price',
        pricePerSqFt: 'insert price/#',
        bedrooms: 'insert # here',
        bathrooms: 'insert # here',
        squareFt: 'insert # here'
      },
      interiorContent: {
        homeType: 'off market',
        condition: 'insert price',
        architecturalStyle: 'insert price/#',
        newConstruction: 'insert # here',
        heating: 'insert # here',
        cooling: 'insert # here'
      },
      propertyContent: {
        lotSize: '2000 sq ft',
        lotFeatures: 'city lot',
        parkingSpaces: 'insert # here',
        parkingFeatures: 'insert features here',
        patioPorch: 'insert feature here',
        fencing: 'insert feature here'
      },
      hoaFinancialContent: {
        annualTax: '$3,000',
        hoa: 'YES',
        taxAssessedValue: 'insert price/#',
        buyersAgentFee: 'insert # here',
        offerReviewDate: 'insert # here',
        listingTerms: 'insert # here'
      },
      communityContent: {
        cityTown: 'Fort Lee',
        walkScore: '80',
        transitScore: '75',
        schoolsRating: 'Elementary: 7\nMiddle: 7\nHigh: 10',
        commuteTime: 'insert # here'
      }
  },
  house4: {
    image: house1,
    overviewContent: {
      status: 'off market',
      listPrice: 'insert price',
      pricePerSqFt: 'insert price/#',
      bedrooms: 'insert # here',
      bathrooms: 'insert # here',
      squareFt: 'insert # here'
    },
    interiorContent: {
      homeType: 'off market',
      condition: 'insert price',
      architecturalStyle: 'insert price/#',
      newConstruction: 'insert # here',
      heating: 'insert # here',
      cooling: 'insert # here'
    },
    propertyContent: {
      lotSize: '2000 sq ft',
      lotFeatures: 'city lot',
      parkingSpaces: 'insert # here',
      parkingFeatures: 'insert features here',
      patioPorch: 'insert feature here',
      fencing: 'insert feature here'
    },
    hoaFinancialContent: {
      annualTax: '$3,000',
      hoa: 'YES',
      taxAssessedValue: 'insert price/#',
      buyersAgentFee: 'insert # here',
      offerReviewDate: 'insert # here',
      listingTerms: 'insert # here'
    },
    communityContent: {
      cityTown: 'Fort Lee',
      walkScore: '80',
      transitScore: '75',
      schoolsRating: 'Elementary: 7\nMiddle: 7\nHigh: 10',
      commuteTime: 'insert # here'
    },
  },
  house5: {
    image: house1,
    overviewContent: {
      status: 'off market',
      listPrice: 'insert price',
      pricePerSqFt: 'insert price/#',
      bedrooms: 'insert # here',
      bathrooms: 'insert # here',
      squareFt: 'insert # here'
    },
    interiorContent: {
      homeType: 'off market',
      condition: 'insert price',
      architecturalStyle: 'insert price/#',
      newConstruction: 'insert # here',
      heating: 'insert # here',
      cooling: 'insert # here'
    },
    propertyContent: {
      lotSize: '2000 sq ft',
      lotFeatures: 'city lot',
      parkingSpaces: 'insert # here',
      parkingFeatures: 'insert features here',
      patioPorch: 'insert feature here',
      fencing: 'insert feature here'
    },
    hoaFinancialContent: {
      annualTax: '$3,000',
      hoa: 'YES',
      taxAssessedValue: 'insert price/#',
      buyersAgentFee: 'insert # here',
      offerReviewDate: 'insert # here',
      listingTerms: 'insert # here'
    },
    communityContent: {
      cityTown: 'Fort Lee',
      walkScore: '80',
      transitScore: '75',
      schoolsRating: 'Elementary: 7\nMiddle: 7\nHigh: 10',
      commuteTime: 'insert # here'
    },
  }
  };
  
  export default housesData;
  