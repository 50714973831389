import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function BulletStyledCard({ image, icon, text, link }) {
    return (
        <Card sx={{ 
                borderTopLeftRadius: '20px', 
                borderBottomLeftRadius: '20px', 
                boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.1)', 
                minWidth: '250px', 
                minHeight: '180px',
                maxHeight: '250px',
                width: '15vw'
            }}>
            <div style={{ display: 'flex', width: '100%', height: '100%', position: 'relative' }}>
                <CardContent sx={{ padding: 0 }}>
                    <img src={image} alt="BackgroundImage" style={{ zIndex: '1', width: '50%', height: '100%', position: 'absolute' }} />
                </CardContent>
                <CardContent sx={{ 
                        paddingLeft: '50%', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        width: '100%',
                        paddingTop: '35px'
                    }}>
                    <img src={icon} alt="Icon" style={{ width: '35%' }}  />
                    <Typography>
                        I want to
                    </Typography>
                    <Typography>
                        {text} a home
                    </Typography>
                    <CardActions>
                        <Button size="small" sx = {{textDecoration: 'underline'}}>{"Start>"}</Button>
                    </CardActions>
                </CardContent>
            </div>
        </Card>
    );
}