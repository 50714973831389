import React from 'react';
import SingleGuideCardMobile from './SingleGuideCardMobile';

export default function GuideSectionMobile() {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '90%', minHeight: '670px', justifyContent: 'space-evenly'}}>
            <SingleGuideCardMobile
                title={"Tailored Searches"}
                description={"On the NEST's geo-map, the filtered properties for sales/lease are shown with" +
                    "some pictures and a virtual tour option. Users can browse each filtered property and save for the comparison."}
                buttonText={"guide"}
            />
            <SingleGuideCardMobile
                title={"Accessibility"}
                description={"Buyers can search properties simply and intuitively using our " +
                    "voice A.I engine for a tailored search."}
                buttonText={"Speech-to-text guide"}
            />
            <SingleGuideCardMobile
                title={"Convenience"}
                description={"When it comes to the contract, buyers and sellers do not need to meet in person. " +
                    "Instead, contracts can be signed directly from their mobile devices with bio signatures."}
                buttonText={"Try out our geo-map"}
            />
        </div>
    );
}
