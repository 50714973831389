import { Grid, TextField } from '@mui/material';
import FilledBgBtn from '../Buttons/FilledBg/FilledBgBtn';
import { useState } from 'react';
import { subscribeToNewsUpdate } from '../../../services/subscribeToNewsUpdate';
import { useSelector, useDispatch } from 'react-redux';
import { setSnackbarOpen, closeSnackbar } from './../../../redux/slice/snackbarSlice';
import CustomizedSnackbars from '../SnackBars/CustomizedSnackbars/CustomizedSnackbars';

export default function GetLatestNewsForm() {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const snackbar = useSelector(state => state.snackbar);

    const validate = () => {
        let tempErrors = {};
        let isValid = true;
        if (!fullName) {
            tempErrors.fullName = 'Full name is required';
            isValid = false;
        }
        if (!email) {
            tempErrors.email = 'Email is required';
            isValid = false;
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            tempErrors.email = 'Email is not valid';
            isValid = false;
        }
        setErrors(tempErrors);
        return isValid;
    };

    const handleSubmit = async () => {
        let response;
        if (validate()) {
            try {
                response = await subscribeToNewsUpdate(email, fullName);
                setFullName('');
                setEmail('');
                dispatch(setSnackbarOpen({ open: true, message: `Successfully signed up!`, severity: 'success', autoHideDuration: 5000 }));
            }
            catch (error) {
                dispatch(setSnackbarOpen({ open: true, message: error.response.data.title, severity: 'error', autoHideDuration: 5000 }));
                setFullName('');
                setEmail('');
            }
        }
    };

    const FilledBgButtonStyle = {
        borderRadius: '50px',
        height: '45px',
        padding: '10px 50px',
        backgroundColor: '#3c73d6',
        marginTop: '10px',
        float: 'right'
    };

    return (
        <Grid item xs={12} md={6} sx={{ p: 3, padding: '0 4vw' }}>
            <CustomizedSnackbars
                open={snackbar.open}
                autoHideDuration={snackbar.autoHideDuration}
                onClose={() => dispatch(closeSnackbar())}
                message={snackbar.message}
            />
            <p className="RegisterSectionTitle">
                Get the latest news from Nest.
            </p>
            <p className="RegisterSectionParagraph">
                By completing and submitting this form, you consent to Nest emailing you news,
                offers, and surveys about Nest's products and services. You can unsubscribe at any time
                by using the link at the end of each email.
            </p>
            <form className="RegisterSectionTextFieldForm" onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                <div className="RegisterSectionTextFieldDiv">
                    <TextField
                        fullWidth
                        name="fullName"
                        label="Full Name"
                        margin="normal"
                        variant="outlined"
                        className="RegisterSectionTextField"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        error={!!errors.fullName}
                        helperText={errors.fullName || ' '}
                    />
                    <TextField
                        fullWidth
                        name="email"
                        label="Email"
                        margin="normal"
                        variant="outlined"
                        className="RegisterSectionTextField"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={!!errors.email}
                        helperText={errors.email || ' '}
                    />
                </div>
                <FilledBgBtn style={FilledBgButtonStyle} callBack={() => handleSubmit()} text={"SIGN UP"} />
            </form>
        </Grid>
    );
}

