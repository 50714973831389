// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section5-container {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    margin: 10px;
}

.info-row5 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly; /* Align items to the start */
    gap: 20px; /* Add space between items */
    padding-top: 20px;
}

.info-item5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0; /* Vertical margin */
}

.info-text5 {
    font-weight: bold;
    margin-bottom: 10px; /* Adjust as needed */
    color: rgb(86, 86, 255);
}
`, "",{"version":3,"sources":["webpack://./App/Pages/component/BuyPageComps/Section5Info.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,iCAAiC;IACjC,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,6BAA6B,EAAE,6BAA6B;IAC5D,SAAS,EAAE,4BAA4B;IACvC,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,cAAc,EAAE,oBAAoB;AACxC;;AAEA;IACI,iBAAiB;IACjB,mBAAmB,EAAE,qBAAqB;IAC1C,uBAAuB;AAC3B","sourcesContent":[".section5-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    font-family: 'Roboto', sans-serif;\r\n    margin: 10px;\r\n}\r\n\r\n.info-row5 {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: space-evenly; /* Align items to the start */\r\n    gap: 20px; /* Add space between items */\r\n    padding-top: 20px;\r\n}\r\n\r\n.info-item5 {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    margin: 10px 0; /* Vertical margin */\r\n}\r\n\r\n.info-text5 {\r\n    font-weight: bold;\r\n    margin-bottom: 10px; /* Adjust as needed */\r\n    color: rgb(86, 86, 255);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
