import React from 'react';
import './Warning.css';
import FilledBgBtn from '../Buttons/FilledBg/FilledBgBtn';

const WarningDialog = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="confirmation-dialog-overlay">
      <div className="warning-dialog">
        <p>{message}</p>
        <div className="confirmation-dialog-buttons">
          <button className="confirm-btn" onClick={onConfirm}>Yes</button>
          <button className="cancel-btn" onClick={onCancel}>No</button>
          {/* Replace with reusable button component */}
        </div>
      </div>
    </div>
  );
};

export default WarningDialog
