import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const daysInMonth = Array.from({ length: 31 }, (_, i) => i + 1);

const CalendarContainer = styled(Box)({
  borderRadius: '10px',
  backgroundColor: '#fff',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  padding: '20px',
  textAlign: 'center',
});

const CalendarHeader = styled(Typography)({
  color: '#1976d2',
  fontSize: '20px',
  fontWeight: 'bold',
  marginBottom: '10px',
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const Calendar = () => {
  return (
    <CalendarContainer>
      <CalendarHeader variant="h6">July</CalendarHeader>
      <Grid container spacing={1}>
        {daysOfWeek.map((day) => (
          <Grid item xs={1.71} key={day}>
            <Typography variant="subtitle1" color="textSecondary">
              {day}
            </Typography>
          </Grid>
        ))}
        {daysInMonth.map((day) => (
          <Grid item xs={1.71} key={day}>
            <Typography variant="body1">{day}</Typography>
          </Grid>
        ))}
      </Grid>
    </CalendarContainer>
  );
};

export default Calendar;
