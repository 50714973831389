import React from "react";
import Navbar from "../Navigation/NavBar";
import Footer from "../Footer/Footer";
import FooterMobile from '../Footer/FooterMobile';
import NavbarMobile from '../Navigation/NavBarMobile';
import './Error.css'
import "typeface-roboto";
import ErrorBgURL from  '../../../assets/images/Error404Bg.png';
import FilledBgBtn from "../Buttons/FilledBg/FilledBgBtn";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function ErrorPage({text, subtext, subtext2, buttonText, buttonFunction, bgURL}) {
    const isWide = useSelector(state => state.windowSize.isWide);
    const ImageUrl = bgURL;

    const FilledBgButtonStyle = {
        borderRadius: '50px',
        height: '45px',
        padding: '10px 50px',
        backgroundColor: '#3c73d6',
    };

    return(
        <>
            <div className="background" style={{ backgroundImage: `url(${ImageUrl})` }}>
                {isWide ? <Navbar /> : <NavbarMobile />}
                <div className="container" style={{ fontFamily: "'Roboto', sans-serif" , top: isWide ? '10%' : '30%'}}>
                    <div className="Body">
                        <h1>{text}</h1> 
                        <div className="subtext">
                            <h3>{subtext}</h3>
                        </div>
                        <div>
                            <p>{subtext2}</p>
                        </div>
                    </div>
                </div>
                <div className="button">
                    <FilledBgBtn text={buttonText} style={FilledBgButtonStyle} callBack={buttonFunction}/>
                </div>
                {isWide ? <Footer /> : <FooterMobile />}
            </div>
        </>
    );
}