// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-bar {
  display: flex;
  align-items: center;
}

.search-input:hover {
  color: #3c73d6;
}

.search-icon {
  color: white;
}
`, "",{"version":3,"sources":["webpack://./App/Pages/component/SearchBar/LargeSearchBar/LargeSearchBar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".search-bar {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.search-input:hover {\r\n  color: #3c73d6;\r\n}\r\n\r\n.search-icon {\r\n  color: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
