import Error from "../component/ErrorPage/Error";
import React from "react";
import { useNavigate } from "react-router-dom";
import bg404 from '../../assets/images/Error404Bg.png';

export default function E404() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/')
    };

    return (
        <>
            <Error text={"Uh oh, We can't seem to find what you're looking for."} subtext={"Error 404 - page not found"} buttonText={"Return To Nest"} buttonFunction={handleClick} bgURL={bg404}/>
        </>
    );
}