import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import firebaseConfig from "./FirebaseConfig";
import { initializeApp } from "firebase/app";
import { getFirestore, Timestamp } from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import "./App.css";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import RealEstatePage from "./App/Pages/LandingPage/index";
import RealEstatePageMobile from "./App/Pages/MobileLandingPage/index";
import AccountPage from "./App/Pages/component/LoginPage/AccountPage";
import DesktopListingPage from "./App/Pages/DesktopListingPage/DesktopListingPage";
import ProfilePage from "./App/Pages/ProfilePage/ProfilePage";
import NotificationPage from "./App/Pages/NotificationPage/NotificationPage";
import SellPage from "./App/Pages/SellPage/SellPage";
import MobileListingPage from "./App/Pages/MobileListingPage/MobileListingPage";
import ComparisonPage from "./App/Pages/ComparisonPage/ComparisonPage";
import { useSelector } from "react-redux";
import TOUpage from "./App/Pages/TOU/TOUpage";
import WhoWeAre from "./App/Pages/WhoWeArePage/WhoWeAre";
import Error404 from "./App/Pages/Error404/Error404";
import Error500 from "./App/Pages/Error500/Error500";
import SavePage from "./App/Pages/SavedPage/SavedPage";
import Navbar from "./App/Pages/component/Navigation/NavBar";
import { handleGoogle } from "./App/services/userLogin";
import SignInPage from "./App/Pages/SignInPage/SignInPage";
import PasswordResetPage from "./PasswordResetPage";
import RegistrationPage from "./App/Pages/component/LoginPage/RegistrationPage";
import NewsPage from "./App/Pages/NewsPage/NewsPage";
import EditListingPage from "./App/Pages/EditListingPage/EditListing";
import CreateListingPage from "./App/Pages/CreateListingPage/CreateListing";
import MobileCreateListingPage from "./App/Pages/MobileCreateListingPage/MobileCreateListing";
import AdminPage from "./App/Pages/AdminPage/AdminPage";
import MobileAdminPage from "./App/Pages/MobileAdminPage/MobileAdminPage";
import SavedPage from "./App/Pages/SavedPage/SavedPage";
import BuyPage from "./App/Pages/BuyPage/Buy";
import ComparePage from "./App/Pages/ComparePage/Compare";
import MobileEditListing from "./App/Pages/MobileEditListingPage/MobileEditListing";

// 初始化 Firebase
initializeApp(firebaseConfig);
const auth = getAuth();

function RequireAuth({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        navigate("/login", { state: { from: location }, replace: true });
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [navigate, location]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return null;
  }

  return children;
}

export default function App() {
  const isWide = useSelector((state) => state.windowSize.isWide);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, setUser);
    return unsubscribe;
  }, []);

  const handleGoogleLogin = async () => {
    console.log("handleGoogleLogin");
    const loginUser = await handleGoogle();
    setUser(loginUser);
  };

  const HomePage = isWide ? RealEstatePage : RealEstatePageMobile;
  const ListingPage = isWide ? DesktopListingPage : MobileListingPage;
  const AdminPageComponent = isWide ? AdminPage : MobileAdminPage;
  const EditListingPageComponent = isWide ? EditListingPage : MobileEditListing;
  const CreateListingPageComponent = isWide
    ? CreateListingPage
    : MobileCreateListingPage;

  return (
    <Router>
      <Navbar user={user} />
      <div className="app-container">
        <Routes>
          <Route
            path="/"
            element={
              user ? (
                <HomePage />
              ) : (
                <SignInPage onGoogleLogin={handleGoogleLogin} />
              )
            }
          />
          <Route path="/account" element={<AccountPage user={user} />} />
          <Route path="/forgot-password" element={<PasswordResetPage />} />
          <Route
            path="/register"
            element={<RegistrationPage onGoogleLogin={handleGoogleLogin} />}
          />
          <Route path="/listings" element={<ListingPage user={user} />} />
          <Route
            path="/listings/:search"
            element={<ListingPage user={user} />}
          />
          <Route path="/sell" element={<SellPage />} />
          <Route path="/terms" element={<TOUpage />} />
          <Route path="/news" element={<NewsPage />}></Route>
          <Route path="/who" element={<WhoWeAre />}></Route>
          <Route path="/404" element={<Error404 />}></Route>
          <Route path="/500" element={<Error500 />}></Route>
          {/* <Route path="/profile" element={<ProfilePage />}></Route> */}
          <Route
            path="/profile"
            element={
              <RequireAuth user={user}>
                <ProfilePage />
              </RequireAuth>
            }
          />
          <Route
            path="/saved"
            element={
              <RequireAuth user={user}>
                <SavedPage user={user} />
              </RequireAuth>
            }
          />
          <Route path="/notification" element={<NotificationPage />}></Route>
          <Route
            path="/login"
            element={<SignInPage onGoogleLogin={handleGoogleLogin} />}
          />
          <Route path="/compare" element={<ComparisonPage />}></Route>
          <Route path="/register" element={<RegistrationPage />} />
          <Route path="/search-page" element={<DesktopListingPage />} />
          <Route path="/search-page/:search" element={<DesktopListingPage />} />
          <Route path="/news" element={<NewsPage isWide={isWide} />}></Route>
          <Route
            path="/edit-listing/:propertyID"
            element={<EditListingPageComponent isWide={isWide} user={user} />}
          />
          <Route
            path="/create-listing"
            element={<CreateListingPageComponent isWide={isWide} user={user} />}
          />
          <Route
            path="/admin"
            element={<AdminPageComponent isWide={isWide} user={user} />}
          />
          <Route path="/saved" element={<SavedPage />}></Route>
          <Route path="/notification" element={<NotificationPage />}></Route>
          <Route path="/buy" element={<BuyPage user={user} />}></Route>
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </div>
    </Router>
  );
}
