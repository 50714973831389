// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-section3 {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
}

.info-row {
    display: flex;
    justify-content: space-evenly;
    padding-top: 20px;
    flex-wrap: wrap;
    gap: 10px;
}

.info-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info-text {
    font-weight: bold;
    margin-bottom: 10px; /* Adjust as needed */
}
`, "",{"version":3,"sources":["webpack://./App/Pages/component/BuyPageComps/Section3.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,iCAAiC;AACrC;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,iBAAiB;IACjB,eAAe;IACf,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB,EAAE,qBAAqB;AAC9C","sourcesContent":[".container-section3 {\r\n    display: flex;\r\n    flex-direction: column;\r\n    font-family: 'Roboto', sans-serif;\r\n}\r\n\r\n.info-row {\r\n    display: flex;\r\n    justify-content: space-evenly;\r\n    padding-top: 20px;\r\n    flex-wrap: wrap;\r\n    gap: 10px;\r\n}\r\n\r\n.info-item {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n}\r\n\r\n.info-text {\r\n    font-weight: bold;\r\n    margin-bottom: 10px; /* Adjust as needed */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
