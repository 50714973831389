import {useEffect, useState} from "react";
import FilledBgBtn from "../Buttons/FilledBg/FilledBgBtn";

export default function BackToTop() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const FilledBgButtonStyle = {
        borderRadius: '50px',
        height: '45px',
        padding: '10px 50px',
        backgroundColor: '#3c73d6',
    }

    return (
        <FilledBgBtn text={"Back to Top"} style={FilledBgButtonStyle} callBack={scrollToTop}/>
    );
}