import React, { useState, useEffect} from 'react';
import './NotificationPage.css';
import Navbar from "../component/Navigation/NavBar";
import NavbarMobile from "../component/Navigation/NavBar";
import Footer from "../component/Footer/Footer";
import FooterMobile from "../component/Footer/Footer";
import { useSelector } from 'react-redux'
import ProfileNotificationAdminTab from '../component/Tabs/ProfileNotificationAdminTab';
import ConnectLineComponent from '../component/Lines/ConnectLineComponent';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function NotificationTab() {
  const isWide = useSelector(state => state.windowSize.isWide);

  const [notifications, setNotifications] = useState({
    PropertyAlerts: { email: false, push: false },
    Recommendations: { email: false, push: false },
    SearchAlerts: { email: false, push: false },
    NestNews: { email: false, push: false }
  });
  const [homeAddress, setHomeAddress] = useState("");
  const [checked, setChecked] = useState(false);

  const handleCheck = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    const anyTrue = Object.values(notifications).some(notification => notification.email || notification.push);
    setChecked(anyTrue);
  }, [notifications]); 

  useEffect(() => {
    if (!checked) {
      const updatedNotifications = Object.keys(notifications).reduce((acc, category) => {
        acc[category] = { email: false, push: false };
        return acc;
      }, {});
      setNotifications(updatedNotifications);
    } else {
      const anyTrue = Object.values(notifications).some(notification => notification.email || notification.push);
      if (!anyTrue) {
        const updatedNotifications = Object.keys(notifications).reduce((acc, category) => {
          acc[category] = { email: true, push: true };
          return acc;
        }, {});
        setNotifications(updatedNotifications);
      }
    }
  }, [checked]); 

  const handleNotificationChange = (category, type, value) => {
    setNotifications(prevState => ({
      ...prevState,
      [category]: { ...prevState[category], [type]: value }
    }));
  };

  const handleAddressChange = (event) => {
    setHomeAddress(event.target.value);
  };

  const handleSaveChanges = () => {
    console.log("Saved Notifications: ", notifications);
    console.log("Saved Home Address: ", homeAddress);
  };

  const handleCancel = () => {
    console.log("Cancelled changes");
  };

  return (
    <>
      {isWide ? <Navbar /> : <NavbarMobile />}
      <div className="fullScreen && NotificationPageMainDiv">
        <div className = {isWide? "Notification-container": "Notification-container-mobile"}>
          <div className="Notifications-info-header">
            <h1>Notification</h1>
            <p>Customize the way you receive alerts</p>
          </div>
          <ProfileNotificationAdminTab initialValue={1}></ProfileNotificationAdminTab>
          <div>
            <h3>Notifications</h3>
            <p>Know when it's a right time to make a move</p>
            <div className="settings-section">
              {Object.entries(notifications).map(([category, settings]) => (
                <div className="notification-category" key={category}>
                  <div className="category-box">
                    <div>
                      <div className="category-header">
                        <h4 className="header-main">{category.replace(/([A-Z])/g, ' $1').trim()}</h4>
                          {category === "NestNews" ? (isWide ? <h4 className="header-tips">Tips, guides, and product updates</h4>: <h4></h4>) : <h4></h4>}
                      </div>
                      {Object.entries(settings).map(([type, value]) => (
                        <label key={type}>
                          <input
                            type="checkbox"
                            checked={value}
                            onChange={(e) => handleNotificationChange(category, type, e.target.checked)}
                          /> {type.charAt(0).toUpperCase() + type.slice(1)}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
              <div className="switch-control">
                <FormControlLabel
                  control={
                    <Switch
                      checked={checked}
                      onChange={handleCheck}
                      name="checkedSwitch"
                      color="primary"
                    />
                  }
                  label={checked ? 'ON' : 'OFF'}
                  labelPlacement='start'
                />
              </div>
            </div>
          </div>
          <ConnectLineComponent></ConnectLineComponent>
          <div className={isWide?'update-home':'update-home-center'}>
            <h4>Save Home Address (optional)</h4>
            <p>Get notification for listing changes, messages, and more</p>
            <div style={{ marginTop: 30, marginBottom: 30 }}>
              <label>Address</label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input className={isWide ? "input-narrow" : "input-wide"} type="text" placeholder="Enter home address" />
                <button className="update-home-button" onChange={handleAddressChange}>Update</button>
              </div>
            </div>
          </div>
          <ConnectLineComponent />
          <div>
            <button className='save-buttons' onClick={handleCancel}>Cancel</button>
            <button className='save-buttons' onClick={handleSaveChanges}>Save changes</button>
          </div>
        </div>
        {isWide ? <Footer /> : <FooterMobile />}
      </div>
    </>
  );
}