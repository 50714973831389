import React, { useState } from "react";
import arrowImage from "../../../assets/images/sellPage/Arrow.png";
import "./ShowMoreRows2.css";

export default function ShowMoreRows({ rows, title }) {
  const [showMore, setShowMore] = useState(false);
  const [numRows, setNumRows] = useState(3); // Initial number of rows to display

  const toggleShowMore = () => {
    setShowMore(!showMore);
    setNumRows(showMore ? 3 : rows.length); // Toggle between showing 3 rows and all rows
  };

  return (
    <div className="table-container">
      <div className="p1">
        <div>
          <h2>{title}</h2>
        </div>
        <div className="toggleb-container">
          <button onClick={toggleShowMore} className="toggle-button">
            <img
              className={`arrow-image ${showMore ? "rotate" : ""}`}
              src={arrowImage}
              alt="arrow"
            />
            <span className="showmore-text">
              {showMore ? "Show Less" : "Show More / collapsed default"}
            </span>
          </button>
        </div>
      </div>
      <div className="row-titles">
        <h4>Year</h4>
        <h4>Property Taxes</h4>
        <h4>Tax Assessment</h4>
      </div>
      <div className="tableline"></div>
      {rows.slice(0, numRows).map((row, index) => (
        <div key={index}>
          <div className="table-row">
            <div className="table-cell">{row.year}</div>
            <div className="table-cell">{row.propTax}</div>
            <div className="table-cell">{row.assess}</div>
          </div>
          <div className="tableline"></div>
        </div>
      ))}
    </div>
  );
}
