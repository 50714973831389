import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Link } from "react-router-dom";

function CustomCheckbox({ label, to }) {
  return (
    <FormControlLabel
      control={<Checkbox />}
      label={
        <a
          href={to}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black", fontSize: "14px" }}
          onMouseOver={(e) => (e.target.style.color = "#3c73d6")}
          onMouseOut={(e) => (e.target.style.color = "black")}
        >
          {label}
        </a>
      }
      sx={{
        "& .MuiSvgIcon-root": {
          width: "20px",
          height: "20px",
          color: "#3c73d6",
        },
      }}
    />
  );
}

export default CustomCheckbox;
