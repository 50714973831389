import { Box, Tabs, Tab } from '@mui/material';
import { useCallback } from "react";

export default function SavedTab({ tabValue, handleTabChange }) {
    const tabItemStyles = {
        fontFamily: 'Ubuntu, sans-serif',
        fontWeight: 500,
        fontSize: '16px',
        textTransform: 'none',
        color: '#000',
        '&.Mui-selected': {
            color: '#000',
        },
        '&:hover': {
            color: '#000',
            opacity: 1,
        },
        minHeight: 48,
        minWidth: 100,
    };

    const tabsStyles = {
        '.MuiTabs-indicator': {
            backgroundColor: 'primary.main',
            height: 4,
        },
    };

    const handleChange = useCallback((event, newValue) => {
        handleTabChange(event, newValue);
    }, [handleTabChange]);

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="navigation tabs"
                sx={tabsStyles}
                TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
            >
                <Tab label="Saved Homes" sx={tabItemStyles} />
                <Tab label="Saved Searches" sx={tabItemStyles} />
            </Tabs>
        </Box>
    )
}