import Navbar from "../component/Navigation/NavBar";
import "./DesktopListingPage.css";
import "../component/SearchBar/SearchBar";
import PropertyView from "../component/ListingPage/PropertyView/PropertyView";
import FilterSearch from "../component/ListingPage/Filter/FilterSearch";
import RecommendProp from "../component/ListingPage/RecommendListingCard/RecommendListingCard";
import Footer from "../component/Footer/Footer";
import MapboxMap from "../component/ListingPage/MapboxMap/MapboxMap";
import React, { useState, useEffect } from "react";
import SearchBar from "../component/SearchBar/SearchBar";
import { fetchAllListingsData } from "../../services/fetchData";
import CompareCard from "../component/CompareCard/CompareCard";
import { list } from "firebase/storage";
import { useNavigate } from "react-router-dom";

function DesktopListingPage({ user }) {
  const [listings, setListings] = useState([]);
  const [compareList, setCompareList] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const getListings = async () => {
      try {
        const data = await fetchAllListingsData();
        setListings(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching news data:", error);
        if (error.response && error.response.status === 500) {
          navigate("/500");
        }
      }
    };

    getListings();
  }, []);

  const applyPriceFilter = (listings, { minPrice, maxPrice }) => {
    return listings.filter((listing) => {
      const price = Number(listing.price.replace(/,/g, ""));
      console.log(price);
      return (
        (!minPrice || price >= minPrice) && (!maxPrice || price <= maxPrice)
      );
    });
  };

  const applyHomeTypeFilter = (listings, homeType) => {
    return listings.filter((listing) => {
      const matches = Object.entries(homeType).some(([type, isSelected]) => {
        return isSelected && listing.houseType === type;
      });
      return matches;
    });
  };

  const applyBedBathFilter = (listings, { beds, baths }) => {
    beds = beds || "any";
    baths = baths || "any";
    return listings.filter((listing) => {
      const matchBeds =
        beds === "any" ||
        (beds === "4+"
          ? parseInt(listing.bedrooms, 10) >= 4
          : listing.bedrooms.toString() === beds);
      const matchBaths =
        baths === "any" ||
        (baths === "4+"
          ? parseInt(listing.bathrooms, 10) >= 4
          : listing.bathrooms.toString() === baths);
      return matchBeds && matchBaths;
    });
  };

  const handleSearchResults = (data) => {
    setListings(data)
    console.log(listings)
  };


  return (
    <div
      style={{
        width: "100vw",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Navbar />
      {listings.length > 0 && (
        <div className="fullScreen listingPageMainDiv">
          <div className="DT-LS-leftColumn">
            <div className="scrollableContent">
              <RecommendProp isTop={true} houseInfo={listings[0]} user={user} />
              {/*<FilterSearch user={user} />*/}
              <PropertyView houseInfo={listings} user={user} />
            </div>
          </div>
          <div className="listingPageMapContainter">
            <SearchBar onSearchResults={handleSearchResults} user={user} />
            <MapboxMap searches={listings[0].address}/>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default DesktopListingPage;
