import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function BulletStyledCardMobile({ image, icon, text, link }) {
    return (
        <Card sx={{
            borderRadius: '15px',
            boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.1)',
            minWidth: '125px',
            height: '130px',
            maxWidth: '150px', 
            boxSizing: 'border-box',
        }}>
            <div style={{ display: 'flex', width: '100%', height: '100%', position: 'relative', flexDirection: 'column'}}>
                <CardContent sx={{ padding: 0 }}>
                    <img src={image} alt="BackgroundImage" style={{ zIndex: '1', width: '100%', height: '50%', position: 'absolute' }} />
                </CardContent>
                <CardContent sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '20px',
                }}>
                    <img src={icon} alt="Icon" style={{ width: '38px', zIndex: '99' }} />
                    <div style={{ fontSize: '10px', marginTop: '15px', fontWeight: 'bold' }}>
                        I want to
                    </div>
                    <div style={{ fontSize: '10px', fontWeight: 'bold' }}>
                        {text} a home
                    </div>
                    <CardActions style={{ paddingTop: '0' }}>
                        <Button size="small" style={{ fontSize: '8px', textDecoration: 'underline' }}>{"Start>"}</Button>
                    </CardActions>
                </CardContent>
            </div>
        </Card>
    );
}