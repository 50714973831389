import 'typeface-roboto';

function Advisory() {
    return (
        <h3>ALL THE TEXT IS shortened and FROM ZILLOW - REPLACE WITH OWN TEXT</h3>
    );
}

function Intro() {
    return (
        <p>
            Welcome and thank you for your interest in Zillow and the Zillow family of businesses (Zillow, Inc. and its affiliated brands listed in Section 2, collectively, the “Zillow Companies,” “us,” “our,” or “we” ). By clicking a registration or new account submission button, or by otherwise using our websites, networks, mobile applications, or other services provided by the Zillow Companies (collectively, the “Services”), or accessing any content provided by us through the Services, you agree to be bound by the following terms of use, as updated from time to time (the “Terms of Use”). 
        </p>
    );
}

function Eligible3() {
    return (
        <p>
            <strong>3. Eligibility; Accounts and Registration. </strong>
            You must be at least 18 years of age to use the Services. By agreeing to these Terms of Use, you represent and warrant that: (a) you are at least 18 years of age; (b) you have not previously been suspended or removed from the Services; and (c) your registration and your use of the Services complies with all applicable laws and regulations.
        </p>
    );
}

function Restrictions4() {
    return (
        <p> 
            <strong>4. Use of the Services; Restrictions</strong>
            <div>- A. Use of Use Services details</div>
            <div>- B. Mobile Applications</div>
            <div>- C. Use of Consent</div>
        </p>
        
    );
}

function Prohibited5() {
    return (
        <p>
            <strong>5. Prohibited Use. BY USING THE SERVICES, YOU AGREE NOT TO: </strong>
            <div>- reproduce, modify, distribute, display or otherwise provide access to...</div>
            <div>- provide/post/authorize a link to any of the Services...</div>
            <div>- remove or modify any copyright or other intellectual property notices that appear in the Services;</div>
            <div>- use the Services in any way that is unlawful, or harms...</div>
            <div>- use the Services in any way to discriminate against any individual or class of individuals...</div>
            <div>- distribute or post spam or other unsolicited messages...</div>
            <div>- impersonate another person, misrepresent your affiliation with another person or entity...</div>
            <div>- upload invalid data, viruses, worms, or other software agents to the Services;</div>
            <div>- post, reproduce, publicly display, or otherwise make accessible any content...</div>
        </p>
    );
}

function Fees6() {
    return(
        <p> 
            <strong>6. Fees.</strong>
            <div>- A. Generally.</div>
            <div>- B. Subscriptions.</div>
        </p>
    );
}

function UserMats7() {
    return(
        <p>
            <strong>7. User Materials.</strong>
            <div>- A. UGC Definition; License Grant.</div>
            <div>- B. UGC Disclaimer.</div>
        </p>
    );
}

function ThirdParty8() {
    return(
        <p>
            <strong>8. Third Party/Linked Services/Sent information.</strong>
            <div>- A. Generally.</div>
            <strong>B. Certain Third-Party Services.</strong>
            <div>(i) Referrals and Lead Sales.</div>
            <div>(ii) Non-Affiliated Financial Products.</div>
            <div>(iii) Rental Products. If you choose to submit your information in order to submit a background or credit check, you are subject to the CIC and Experian terms as described in Section 8(C) below. We do not process, or store background or credit check information related to our rental products and do not control how a landlord uses background or credit check information in evaluating applications submitted to them. We do not guarantee any acceptance by a landlord of an application but do require all landlords using the Services to comply with the Fair Housing Act and other applicable laws.</div>
            <br></br>
            <div>C. Additional Terms for Third Party Services. Certain aspects of the Services include Third-Party tools that are subject to additional Third-Party terms, including, but not limited to, the following:</div>
            <div>(i) Windows Live Virtual Earth?</div>
            <div>(ii) Google Maps</div>
            <div>(iii) Payment Methods</div>
            <div>(iv) CIC. Some of the Services allow users to use our Third-Party background check partner CIC's services to submit an application and background check to a landlord.</div>
        </p>
    );
}

function IntellectualProp9() {
    return(
        <p>
            <strong>9. Intellectual Property. The Services are owned and operated by Nest.</strong>
            <br></br>
            <strong>Intellectual Property Notices: </strong>
            <div>Certain content on the Services is owned by ZIP+4 data and the United States Postal Service. The names of actual companies, products, and services mentioned herein may be the trademarks of their respective owners. Any rights not expressly granted herein are reserved. We do not assert copyright or grant any rights to the underlying images or descriptions of real estate listings provided through the Services. Any use of these images and descriptions is subject to the copyright owner's permission and the requirements of applicable law</div>
        </p>
    );
}

function Feedback10() {
    return(
        <p>
            <strong>10. Feedback.</strong>
        </p>
    );
}

function DMCA11() {
    return(
        <p>
            <strong>11. DMCA; Claims of Copyright Infringement.</strong>
            We respect the intellectual property rights of others and ask that everyone using the Services do the same. Anyone who believes that their work has been reproduced on the Services in a way that constitutes copyright infringement may notify our copyright agent in accordance with Title 17, United States Code, Section 512(c)(2), by providing the following information:
            <br></br>
            <div>- (i) Identification of the copyrighted work that you claim has been infringed;</div>
            <div>- (ii) Identification of the material that you claim is infringing and needs to be removed, including a description of where it is located on the Services so that the copyright agent can locate it;</div>
            <div>- (iii) Your address, telephone number, and, if available, e-mail address, so that the copyright agent may contact you about your complaint; and</div>
            <div>- (iv) A signed statement that the above information is accurate; that you have a good faith belief that the identified use of the material is not authorized by the copyright owner, its agent, or the law; and, under penalty of perjury, that you are the copyright owner or are authorized to act on the copyright owner’s behalf in this situation.</div>
            <div>- Notices of copyright infringement claims should be sent as follows:</div>
            <div>   - By Mail: </div>
            <div>   - By Email: If you give notice of copyright infringement by e-mail, we may begin investigating the alleged copyright infringement; however, we must receive your signed statement by mail or as an attachment to your e-mail before we are required to take any action.</div>
        </p>
    );
}

function Deactivation12() {
    return (
        <p>
            <strong>12. Deactivation/Deletion/Changes to Agreement.</strong>
        </p>
    );
}

function TOS13() {
    return(
        <p>
            <strong>13. Privacy Policy/Other Terms.</strong>
            <div>- A. Privacy Policy. The Zillow Companies will collect, use, store, and disclose personal information in accordance with our Privacy Policy. Please consult your Privacy Policy for more information, which is incorporated into, and made a part of, these Terms of Use.</div>
            <div>- B. Other Terms.</div>
        </p>
    );
}

function Other14() {
    return(
        <pr>
            <strong>14. Indemnification.</strong>
            <br></br>
            <strong>15. No Warranties.</strong>
            <br></br>
            <strong>16. Limitation of Liability/Exclusive Remedy.</strong>
            <br></br>
            <strong>17. Choice of Law; Disputes.</strong>
            <br></br>
            <strong>18. General. </strong>
            <br></br>
            <strong>19. Consent to Communications.</strong>
            <br></br>
            <strong>20. Notice to California Residents. </strong>
            <div>Nest is a wholly-owned subsidiary of _______ and has its headquarters at _____.</div>
            <br></br>
            <strong>22. Notice to Apple Users.</strong>
            If you are using our mobile applications on an iOS device, the terms of this Section 22 apply..
        </pr> 
    );
}

export default function TermsText() {

    return (
        <div style={{ fontFamily: "'Roboto', sans-serif"}}>
            <Advisory />
            <Intro />
            <Eligible3 />
            <Restrictions4 />
            <Prohibited5 />
            <Fees6 />
            <UserMats7 />
            <ThirdParty8 />
            <IntellectualProp9 />
            <Feedback10 />
            <DMCA11 />
            <Deactivation12 />
            <TOS13 />
            <Other14 />
        </div>
    );
}