import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import {
  db,
  auth,
  Timestamp,
  //getStorage,
  //ref,
  //uploadBytes,
  //getDownloadURL,
  //deleteObject,
} from "../../../App";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import {
  Typography,
  TextField,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  TableContainer,
  Paper,
} from "@mui/material";
import "./EditListing.css";
import Navbar from "../component/Navigation/NavBar";
import NavbarMobile from "../component/Navigation/NavBarMobile";
import Footer from "../component/Footer/Footer";
import FooterMobile from "../component/Footer/FooterMobile";
import fileUploadImage from "../../assets/images/file-upload.png";
import backToAdmin from "../../assets/images/Arrow.png";
import ListingTab from "../component/Tabs/ListingTab/ListingTab";
import { Carousel } from "react-responsive-carousel";
import FilledBgBtn from "../component/Buttons/FilledBg/FilledBgBtn";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { fetchOneListingData } from "../../services/fetchData";
import { updateListing } from "../../services/apiServices";

export default function EditListingPage({ isWide, user }) {
  console.log("User:", user.uid);
  const storage = getStorage();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("admin");
  const [modalImage, setModalImage] = useState(null);
  const { propertyID } = useParams();
  const [isModified, setIsModified] = useState(false);
  const [listing, setListing] = useState({
    Address: "",
    Appliance: "",
    AppraisedValue: "",
    Basement: "",
    Bathrooms: "",
    BedSize: "",
    Bedrooms: "",
    Beds: "",
    Category: "",
    City: "",
    Deck: "",
    Description: "",
    FireplaceType: "",
    FloorFinished: "",
    HOA: "",
    HeatingCooling: "",
    HouseType: "",
    IsSold: "",
    LivingRooms: "",
    ParkingSpaces: "",
    Patio: "",
    Price: "",
    PropertyID: "",
    PropertyTax: "",
    SaleEndDate: "",
    SaleStartDate: "",
    Saves: "",
    Size: "",
    TourDate: "",
    Utility: "",
    Views: "",
    YearBuilt: "",
    id: "",
    Images: [],
  });

  const cancelBtnStyle = {
    backgroundColor: "transparent",
    border: "1px solid #3c73d6",
    color: "#3c73d6",
    textAlign: "left",
    textDecoration: "none",
    fontSize: "0.8rem",
    fontWeight: "bold",
    fontFamily: "Roboto",
    margin: "4px 2px",
    cursor: "pointer",
    whiteSpace: "nowrap",
    width: "100px",
    textTransform: "capitalize",
  };

  const submitBtnStyle = {
    backgroundColor: "#3c73d6",
    color: "white",
    textAlign: "left",
    textDecoration: "none",
    fontSize: "0.8rem",
    fontWeight: "bold",
    fontFamily: "Roboto",
    margin: "4px 2px",
    cursor: "pointer",
    whiteSpace: "nowrap",
    width: "100px",
    textTransform: "capitalize",
  };

  const textFieldStyle = {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid #3c73d6",
        borderRadius: "8px",
        fontFamily: "Roboto",
      },
      "&:hover fieldset": {
        border: "1px solid darkblue",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #3c73d6",
      },
    },
  };

  const textFieldLabel = {
    fontFamily: "Roboto",
    color: "grey",
    fontSize: "14px",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  };

  useEffect(() => {
    const fetchListing = async () => {
      try {
        const listingData = await fetchOneListingData(propertyID);
        setListing(listingData);
        console.log("Listing data:", listingData);
      } catch (error) {
        console.error("Error fetching listing:", error);
      }
    };

    fetchListing();
  }, [propertyID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setListing((prevListing) => ({
      ...prevListing,
      [name]: value,
    }));
    setIsModified(true);
  };

  const handleFileUpload = async (event) => {
    const files = Array.from(event.target.files);
    const maxImages = 20;
    if (listing.Images.length + files.length > maxImages) {
      alert(`You can only upload up to ${maxImages} images.`);
      return;
    }
    const uploadImage = async (file) => {
      // Check for existing images with the same name
      const existingImages = listing.Images.filter((image) =>
          image.url.includes(file.name)
      );
      let fileName = `${listing.PropertyID}_${file.name}`;
      if (existingImages.length > 0) {
        const suffix = ` ${existingImages.length + 1}`;
        const nameParts = fileName.split(".");
        const extension = nameParts.pop();
        fileName = `${nameParts.join(".")}${suffix}.${extension}`;
      }

      // Create a storage reference with a unique file name
      console.log("File:", fileName);
      console.log("Timestamp:", new Date().toISOString());
      const fileRef = ref(storage, `images/${fileName}`);
      // Upload the file
      await uploadBytes(fileRef, file);
      console.log("File uploaded successfully.");
      // Get the download URL
      const url = await getDownloadURL(fileRef);
      console.log("File available at:", url);
      return {
        url: url,
        name: fileName,
        createdBy: new Date().toISOString(),
      };
    };

    try {
      const uploadPromises = files.map(uploadImage);
      const imagesWithTimestamps = await Promise.all(uploadPromises);
      console.log("Images with timestamps:", imagesWithTimestamps);
      setListing((prevListing) => ({
        ...prevListing,
        Images: [...prevListing.Images, ...imagesWithTimestamps],
      }));
      setIsModified(true);
      console.log("Updated listing with new images:", listing);
    } catch (error) {
      console.error("Error uploading images:", error);
    }
    event.target.value = null;
  };

  const removeImage = async (index) => {
    const imageName = listing.Images[index].name;

    // Create a reference to the file to delete
    const imageRef = ref(storage, `images/${imageName}`);

    try {
      // Delete the file
      await deleteObject(imageRef);

      // Successful deletion, filter out the image from listing.Images
      setListing((prevListing) => {
        const updatedImages = prevListing.Images.filter((_, i) => i !== index);
        return { ...prevListing, Images: updatedImages };
      });
      console.log(
          "Image removed successfully from Firebase Storage and listing."
      );
      setIsModified(true);
    } catch (error) {
      console.error("Error removing image from Firebase Storage:", error);
    }
  };

  const handleSubmit = async () => {
    try {
      const updatedListing = await updateListing(user.uid, listing.id, listing);
      navigate("/admin");
    } catch (error) {
      console.error("Error updating listing:", error);
    }
  };

  return (
      <>
        <div className="DT-edit-listing">
          {isWide ? <Navbar /> : <NavbarMobile />}
          <div className="DT-EL-arrow">
            <Link to={`/admin`}>
              <img src={backToAdmin} />
              <span className="back">Back to Admin</span>
            </Link>
          </div>
          <div className="DT-edit-listing-header">
            <Typography
                variant="h4"
                className="DT-EL-listing-title"
                sx={{
                  marginTop: "5vh",
                  marginLeft: "9vw",
                  fontWeight: "bold",
                  fontSize: "1.6rem",
                  textAlign: "left",
                }}
            >
              Edit Listing
            </Typography>
            <div className="DT-EL-listing-tab">
              <ListingTab activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
          </div>

          <Container>
            <TableContainer component={Paper} className="DT-EL-table-container">
              <div className="DT-EL-main-body">
                <div className="DT-EL-left-column">
                  <form className="DT-edit-listing-form">
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <label style={textFieldLabel}>Address</label>

                        <TextField
                            sx={textFieldStyle}
                            name="Address"
                            value={listing.Address}
                            onChange={handleInputChange}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <label style={textFieldLabel}>Price</label>

                        <TextField
                            sx={textFieldStyle}
                            name="Price"
                            value={listing.Price}
                            onChange={handleInputChange}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <label style={textFieldLabel}>Appraised Value</label>
                        <TextField
                            sx={textFieldStyle}
                            name="AppraisedValue"
                            value={listing.AppraisedValue}
                            onChange={handleInputChange}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <label style={textFieldLabel}>Property Tax</label>
                        <TextField
                            sx={textFieldStyle}
                            name="PropertyTax"
                            value={listing.PropertyTax}
                            onChange={handleInputChange}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <label style={textFieldLabel}>HOA</label>
                        <TextField
                            sx={textFieldStyle}
                            name="HOA"
                            value={listing.HOA}
                            onChange={handleInputChange}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <label style={textFieldLabel}>Utilities</label>
                        <TextField
                            sx={textFieldStyle}
                            name="Utility"
                            value={listing.Utility}
                            onChange={handleInputChange}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <label style={textFieldLabel}>Size (SQFT)</label>
                        <TextField
                            sx={textFieldStyle}
                            name="Size"
                            value={listing.Size}
                            onChange={handleInputChange}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <label style={textFieldLabel}># Beds</label>
                        <TextField
                            sx={textFieldStyle}
                            name="Beds"
                            value={listing.Beds}
                            onChange={handleInputChange}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <label style={textFieldLabel}># Baths</label>
                        <TextField
                            sx={textFieldStyle}
                            name="Bathrooms"
                            value={listing.Bathrooms}
                            onChange={handleInputChange}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <label style={textFieldLabel}>Year Built</label>
                        <TextField
                            sx={textFieldStyle}
                            name="YearBuilt"
                            value={listing.YearBuilt}
                            onChange={handleInputChange}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <label style={textFieldLabel}>Basement Finish</label>
                        <TextField
                            sx={textFieldStyle}
                            name="Basement"
                            value={listing.Basement}
                            onChange={handleInputChange}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <label style={textFieldLabel}>Parking</label>
                        <TextField
                            sx={textFieldStyle}
                            name="ParkingSpaces"
                            value={listing.ParkingSpaces}
                            onChange={handleInputChange}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <label style={textFieldLabel}>Deck</label>
                        <TextField
                            sx={textFieldStyle}
                            name="ParkingSpaces"
                            value={listing.ParkingSpaces}
                            onChange={handleInputChange}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <label style={textFieldLabel}>Patio</label>
                        <TextField
                            sx={textFieldStyle}
                            name="Patio"
                            value={listing.Patio}
                            onChange={handleInputChange}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <label style={textFieldLabel}>Floor Finish</label>
                        <TextField
                            sx={textFieldStyle}
                            name="FloorFinished"
                            value={listing.FloorFinished}
                            onChange={handleInputChange}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <label style={textFieldLabel}>Fireplace</label>
                        <TextField
                            sx={textFieldStyle}
                            name="FireplaceType"
                            value={listing.FireplaceType}
                            onChange={handleInputChange}
                            fullWidth
                        />
                      </Grid>
                    </Grid>
                  </form>
                </div>
                <div className="DT-EL-right-column">
                  <label
                      htmlFor="DT-EL-media"
                      style={{
                        fontSize: "21px",
                        fontWeight: "bold",
                        color: "grey",
                      }}
                      className="label"
                  >
                    Media
                  </label>

                  <label
                      htmlFor="DT-EL-file-upload"
                      style={{ cursor: "pointer" }}
                  >
                    <input
                        id="DT-EL-file-upload"
                        type="file"
                        accept=".jpg, .png, .jpeg"
                        style={{ display: "none" }}
                        onChange={handleFileUpload}
                        multiple
                    />
                    <div
                        htmlFor="DT-EL-file-upload"
                        className="inputField DT-EL-file-upload"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                    >
                      <img className="DT-EL-file-image" src={fileUploadImage} />
                      <label
                          htmlFor="DT-EL-file-upload"
                          style={{
                            fontSize: "20px",
                            fontWeight: "bolder",
                            cursor: "pointer",
                            textAlign: "center",
                            color: "grey",
                            marginTop: "20px",
                            paddingLeft: "50px",
                            paddingRight: "30px",
                          }}
                          className="label DT-EL-file"
                      >
                        Drag images here or click to upload
                      </label>
                      <label
                          htmlFor="DT-EL-file-upload"
                          style={{
                            fontSize: "18px",
                            fontWeight: "lighter",
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                          className="label DT-EL-file"
                      >
                        Formats: jpg, png
                      </label>
                    </div>
                  </label>

                  <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "left",
                        overflowX: "hidden",
                        width: "90%",
                        height: "100%",
                      }}
                  >
                    <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          width: "80%",
                          height: "80%",
                        }}
                    >
                      <Carousel
                          showThumbs={false}
                          centerMode
                          centerSlidePercentage={33}
                          gap={5}
                          width={"90%"}
                      >
                        {listing.Images.map((image, index) => (
                            <div
                                key={index}
                                className="DT-EL-uploaded-image-wrapper"
                                style={{
                                  marginRight: "2px",
                                  marginLeft: "2px",
                                  marginTop: "10px",
                                  width: "90%",
                                }}
                            >
                              <img
                                  src={image.url}
                                  alt="Uploaded"
                                  className="DT-EL-uploaded-image"
                                  onDoubleClick={() => setModalImage(image.url)}
                              />
                              <button
                                  onClick={() => removeImage(index)}
                                  className="DT-EL-remove-image-button"
                              >
                                X
                              </button>
                            </div>
                        ))}
                      </Carousel>
                      <Modal
                          isOpen={modalImage !== null}
                          onRequestClose={() => setModalImage(null)}
                          contentLabel="Enlarged Image"
                      >
                        <img src={modalImage} alt="Enlarged" />
                      </Modal>
                    </div>

                    {listing.Images.length > 0 && (
                        <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                        >
                          <label
                              htmlFor="DT-EL-more-images"
                              className="DT-EL-add-more-images"
                          >
                            Add more Images
                            <input
                                id="DT-EL-more-images"
                                type="file"
                                accept=".jpg, .png, .jpeg"
                                style={{ display: "none", alignItems: "center" }}
                                onChange={handleFileUpload}
                                multiple
                            />
                          </label>
                        </div>
                    )}
                  </div>
                  <div className="DT-EL-buttons">
                    <Link to="/admin">
                      <FilledBgBtn text="Cancel" style={cancelBtnStyle} />
                    </Link>
                    <FilledBgBtn
                        text="Save Changes"
                        style={submitBtnStyle}
                        disabled={!isModified}
                        callBack={handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </TableContainer>
          </Container>

          <div className="DT-EL-footer">
            {isWide ? <Footer /> : <FooterMobile />}
          </div>
        </div>
      </>
  );
}

