import './MobileListingPage.css';
import '../component/SearchBar/SearchBar';
import FilterSearch from '../component/ListingPage/Filter/FilterSearch';
import RecommendProp from '../component/ListingPage/RecommendListingCard/RecommendListingCard';
import MapboxMap from '../component/ListingPage/MapboxMap/MapboxMap';
import React from 'react';
import SearchBar from '../component/SearchBar/SearchBar';
import NavbarMobile from '../component/Navigation/NavBarMobile';
import FooterMobile from '../component/Footer/FooterMobile';
import ListingSearchResults from '../component/FinalListingPageMobile/ListingSearchResults';
import { fetchAllListingsData } from '../../services/fetchData';
import { useState, useEffect } from 'react';

function MobileListingPage() {
    const [listings, setListings] = useState([]);
    useEffect(() => {
        const getListings = async () => {
            try {
                const data = await fetchAllListingsData();
                setListings(data);
                console.log(data[0]);
            }
            catch (error) {
                console.error('Error fetching news data:', error);
            }
        };

        getListings();
    }, []);

    return (
        <div style={{ width: '100vw', minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <NavbarMobile />
            {
                listings.length > 0 &&
                <div className="fullScreen">
                    <div style={{ padding: '70px 20px 20px 25px', boxSizing: 'border-box', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <SearchBar />
                        <div style={{ width: '100vw', margin: '10px 0px 30px 0px' }}>
                            <MapboxMap />
                        </div>
                        <RecommendProp isTop={true} houseInfo={listings[0]} />
                        <FilterSearch />
                    </div>
                    <div style={{ width: '80%', minWidth: '250px' }}>
                        <ListingSearchResults houseInfo={listings} />
                    </div>
                </div>
            }
            <FooterMobile />
        </div>
    );
}

export default MobileListingPage;
