import React from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./ListingTab.css";
import useScrollRestoration from "./useScrollRestoration";

const ListingHeader = ({ activeTab, setActiveTab }) => {
  const navigate = useNavigate();
  useScrollRestoration();

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    switch (newValue) {
      case "profile":
        navigate("/profile");
        break;
      case "notifications":
        navigate("/notification");
        break;
      case "admin":
        navigate("/admin");
        break;
      default:
        navigate("/admin");
        break;
    }
  };

  return (
    <Box className="listing-tab">
      <div style={{ width: "100vw" }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="edit listing tabs"
          sx={{
            ".MuiTab-root": {
              textTransform: "none",
              fontSize: "0.8rem",
              color: "black",
              fontWeight: "bold",
            },
            paddingLeft: "9.5%",
            borderBottom: 2,
            borderColor: "divider",
            width: "100vw",
            color: "darkgray",
            marginLeft: "-7vw",
          }}
        >
          <Tab value="profile" label="Profile" />
          <Tab value="notifications" label="Notifications" />
          <Tab value="admin" label="Admin" />
        </Tabs>
      </div>
    </Box>
  );
};

export default ListingHeader;
