// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainDivSearch{
    height: 350px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.mainDivSearch > span:nth-of-type(1){
    display: flex;
    align-items: center;
}

.mainDivSearch > span:nth-of-type(1) img{
    width: 140px;
}

.mainDivSearch > span:nth-of-type(1) p{
    display: inline;
    font-size: 2rem;
    font-weight: 600;
}

@media only screen and (max-width: 768px) {
    .mainDivSearch > span:nth-of-type(1) > img {
        width: 100px;
    }

    .mainDivSearch > span:nth-of-type(1) > p {
        font-size: 15px;
        margin-top: 0;
    }

    .mainDivSearch > span:nth-of-type(1) {
        display: flex;
        flex-direction: column;
    }

    .mainDivSearch {
        max-height: 230px;
        padding-top: 75px;
    }
}`, "",{"version":3,"sources":["webpack://./App/Pages/component/FinalLandingPage/SearchSection/SearchSection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI;QACI,YAAY;IAChB;;IAEA;QACI,eAAe;QACf,aAAa;IACjB;;IAEA;QACI,aAAa;QACb,sBAAsB;IAC1B;;IAEA;QACI,iBAAiB;QACjB,iBAAiB;IACrB;AACJ","sourcesContent":[".mainDivSearch{\r\n    height: 350px;\r\n    width: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    padding-top: 20px;\r\n}\r\n\r\n.mainDivSearch > span:nth-of-type(1){\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.mainDivSearch > span:nth-of-type(1) img{\r\n    width: 140px;\r\n}\r\n\r\n.mainDivSearch > span:nth-of-type(1) p{\r\n    display: inline;\r\n    font-size: 2rem;\r\n    font-weight: 600;\r\n}\r\n\r\n@media only screen and (max-width: 768px) {\r\n    .mainDivSearch > span:nth-of-type(1) > img {\r\n        width: 100px;\r\n    }\r\n\r\n    .mainDivSearch > span:nth-of-type(1) > p {\r\n        font-size: 15px;\r\n        margin-top: 0;\r\n    }\r\n\r\n    .mainDivSearch > span:nth-of-type(1) {\r\n        display: flex;\r\n        flex-direction: column;\r\n    }\r\n\r\n    .mainDivSearch {\r\n        max-height: 230px;\r\n        padding-top: 75px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
