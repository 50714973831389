import React from 'react';
import '../component/styles/HomePage.css';
import Navbar from '../component/Navigation/NavBar';
import SearchSection from '../component/FinalLandingPage/SearchSection/SearchSection';
import BannerSection from '../component/FinalLandingPage/BannerSection/BannerSection';
import BrowseSection from '../component/FinalLandingPage/BrowseSection/BrowseSection';
import RegisterSection from '../component/FinalLandingPage/RegisterSection/RegisterSection';
import GuideSection from '../component/FinalLandingPage/GuideSection/GuideSection';
import Footer from '../component/Footer/Footer';

export default function HomeScreen() {
  return (
    <>
      <Navbar />
      <div className='fullScreen'>
        <SearchSection/>
        <BannerSection/>
        <BrowseSection/>
        <RegisterSection/>
        <GuideSection />
        <Footer />
      </div>
    </>
  );
}
