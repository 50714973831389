import bgImg from '../../../../assets/images/homePageBg.png'
import './BannerSection.css'
import FilledBgButton from '../../Buttons/FilledBg/FilledBgBtn'

export default function BannerSection() {
    const FilledBgButtonStyle = {
        borderRadius: '50px',
        height: '45px',
        padding: '10px 50px',
        backgroundColor: '#3c73d6',
    }

    return (
        <>
            <div className='mainDivBanner'>
                <img src={bgImg} alt="backgroundImage" className='bgImg' />
                <div className='textDiv'>
                    <div>
                        <p className='mainText'>At Nest, we make real estate easy -a</p>
                        <p className='mainText'>stearmlined, stress-free housing search.</p>
                    </div>
                    <div>
                        <p className='minorText'>Sample text: At NEST, each individual</p>
                        <p className='minorText'>real estate agent participates in a bid</p>
                        <p className='minorText'>for a listing in order.</p>
                    </div>
                    <div className='buttonDiv'>
                        <FilledBgButton link={{absolute: false, url: '/news'}} style={FilledBgButtonStyle} text={"NEST NEWS"}/>
                        <FilledBgButton style={FilledBgButtonStyle} text={"GUIDES"}/>
                    </div>
                </div>
            </div>
        </>

    )
}