import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import './TOUpage.css';
import Navbar from "../component/Navigation/NavBar";
import NavbarMobile from '../component/Navigation/NavBarMobile';
import BackToTopButton from "../component/TOUcomps/BackToTopButton";
import TermsText from "../component/TOUcomps/TermsText";
import "typeface-roboto";

export default function TermsPage() {
    const isWide = useSelector(state => state.windowSize.isWide);

    return (
        <>
            {isWide ? <Navbar /> : <NavbarMobile />}
            <div className="content">
                <h1 className="title-text" style={{ fontFamily: "'Roboto', sans-serif"}}>Nest Terms of Use/Privacy Policy</h1>
                <div className="text-body">
                    <TermsText />
                </div>
                <div style={{alignSelf: isWide ? 'flex-end' : 'center'}}>
                    <BackToTopButton />
                </div>
            </div>
        </>
    );
}