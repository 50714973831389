// Navbar.js
import React from 'react';
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faUser } from "@fortawesome/free-solid-svg-icons";
import './NavBar.css';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../../assets/icons/logo1.png'
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {BurgerMenu, BurgerProvider, useBurger} from "../BurgerMenu/BurgerMenu";


const NavbarMobileContent = () => {
  const BtnStyle = {
    color: '#3c73d6',
    fontWeight: '600',
    margin: '0vh 0.5vw',
  };

  const { toggleBurger } = useBurger();

  const navigate = useNavigate();
  return (
    <div className='navBarMainDiv'>
      <div>
        <img src={Logo} alt="NEST" className='mobileLogo' onClick={() => navigate('/')} />
      </div>
      <div>
        <IconButton color="inherit" edge="end" onClick={toggleBurger(true)}>
          <MenuIcon />
        </IconButton>
      </div>
    </div>
  );
};

const NavbarMobile = () => {
    return (
        <BurgerProvider>
            <NavbarMobileContent />
            <BurgerMenu />
        </BurgerProvider>
    );
};

export default NavbarMobile;