import { useSelector } from 'react-redux'
import { Grid, Box, Snackbar, Alert } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import Popup from 'reactjs-popup';
import Navbar from "../component/Navigation/NavBar";
import Footer from "../component/Footer/Footer";
import FooterMobile from '../component/Footer/FooterMobile';
import NavbarMobile from '../component/Navigation/NavBarMobile';
import './ProfilePage.css'
import ProfileNotificationAdminTab from '../component/Tabs/ProfileNotificationAdminTab';
import ConnectLineComponent from '../component/Lines/ConnectLineComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { fetchUserData } from '../../services/fetchData';
import { getAuth, GoogleAuthProvider} from 'firebase/auth';
import { updateUserData } from '../../services/fetchData';
import EditIcon from '@mui/icons-material/Edit';
import ProfileEditor from './ProfileEditor';
import { getStorage, deleteObject, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFirestore, doc, updateDoc, getDoc } from 'firebase/firestore';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';

const loginIcon = require('../../assets/images/loginIcon.png');
const editLogo = require('../../assets/images/profilePage/editIcon.png');
const defaultImage = require('../../assets/images/profilePage/defaultProfileImage.png');

export default function ProfilePage() {
    const [profile, setProfile] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
    });
    const [initialProfile, setInitialProfile] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
    });
    const auth = getAuth();
    const storage = getStorage();
    const db = getFirestore();
    const isWide = useSelector(state => state.windowSize.isWide);
    const [file, setFile] = useState(null);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showProfileEditor, setShowProfileEditor] = useState(false);
    
    const openProfileEditor = () => {
        setShowProfileEditor(true);
    }

    const closeProfileEditor = () => {
        setShowProfileEditor(false);
    }

    const [isGoogleUser, setIsGoogleUser] = useState(false);
    const [url, setUrl] = useState('');
    const [imageScale, setImageScale] = useState(1);
    const editorRef = useRef(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        const fetchUserProfile = async () => {
            if (auth.currentUser) {
                try {
                    const userDoc = doc(db, 'USER', auth.currentUser.uid);
                    const userSnapshot = await getDoc(userDoc);
    
                    if (userSnapshot.exists()) {
                        const userData = userSnapshot.data();
                        setProfile(userData);
                        setInitialProfile(userData);
                        setFile(userData.profileImageUrl || '');
                        
                        // Check if the user is logged in using Google
                        const isGoogle = auth.currentUser.providerData.some(
                            (provider) => provider.providerId === GoogleAuthProvider.PROVIDER_ID
                        );
                        setIsGoogleUser(isGoogle);
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };
    
        fetchUserProfile();
    }, [auth.currentUser]);

    const hasChanges = () => {
        return (
            profile.firstName !== initialProfile.firstName ||
            profile.lastName !== initialProfile.lastName ||
            profile.phoneNumber !== initialProfile.phoneNumber
        );
    }

    const handleSliderChange = (event) => {
        const slider = event.target;
        const value = (slider.value - slider.min) / (slider.max - slider.min) * 100;
    
        // Update the image scale
        setImageScale(parseFloat(slider.value));
    
        // Update the slider background
        slider.style.background = `linear-gradient(to right, #0461CF ${value}%, #d3d3d3 ${value}%)`;
    };


    const handleSave = async (e) => {
        try {
            updateUserData(auth.currentUser.uid, profile);
            console.log("Profile updated successfully");
        } catch (error) {
            console.error("Error updating profile:", error);
        }
    };

    const handleSnackbarOpen = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };
      
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSavePhoto = async(close) => {
        if (editorRef.current) {
            // Get the cropped image as a canvas
            const canvas = editorRef.current.getImageScaledToCanvas();
            // Convert the canvas to a Blob
            canvas.toBlob(async (blob) => {
                if (blob) {
                    const storageRef = ref(storage, `profileImages/${auth.currentUser.uid}`);
                    try {
                        // Upload the blob to Firebase Storage
                        await uploadBytes(storageRef, blob);
                        const downloadURL = await getDownloadURL(storageRef);
                        // Update Firestore with the profile image URL
                        const userDoc = doc(db, 'USER', auth.currentUser.uid);
                        await updateDoc(userDoc, { profileImageUrl: downloadURL });
                        setFile(downloadURL); // Set the file URL for previewing
                        handleSnackbarOpen('Profile picture updated successfully')
                        // console.log("Cropped image uploaded and URL stored successfully:", downloadURL);
                    } catch (error) {
                        console.error("Error uploading cropped image:", error);
                    }
                }
            });
        }
        else{
            console.log("editorRef is null");
        }
        close();
    }

    const handleCancel = () => {
        console.log("changes canceled");
    }

    const fileInputRef = React.useRef(null);
    const handleFileClick = () => {
        fileInputRef.current.click();
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfile({
          ...profile,
          [name]: value
        });
      };
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
            if (!validTypes.includes(file.type.toLowerCase())) {
                alert("Unsupported file type.");
                setFile(null);
                return;
            }
            const storageRef = ref(storage, `profileImages/${auth.currentUser.uid}`);
            try {
                await uploadBytes(storageRef, file);
                const downloadURL = await getDownloadURL(storageRef);
    
                // Update Firestore with the profile image URL
                const userDoc = doc(db, 'USER', auth.currentUser.uid);
                await updateDoc(userDoc, { profileImageUrl: downloadURL });
                setFile(downloadURL); // Set the file URL for previewing
                handleSnackbarOpen('Profile picture uploaded successfully')
                // console.log("File uploaded and URL stored successfully:", downloadURL);
            } catch (error) {
                console.error("Error uploading file:", error);
            }
        }
    };
    const handleRemovePhoto = async () => {
        const storageRef = ref(storage, `profileImages/${auth.currentUser.uid}`);
        try {
            // Delete the file from Firebase Storage
            await deleteObject(storageRef);
            // Update Firestore to remove the image URL
            const userDoc = doc(db, 'USER', auth.currentUser.uid);
            await updateDoc(userDoc, { profileImageUrl: '' });
            // Reset the UI
            setFile(null);
            console.log("Photo removed successfully.");
        } catch (error) {
            console.error("Error removing photo:", error);
        }
    };
    const validatePassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
        return regex.test(password);
    };

    useEffect(() => {
        const passwordInput = document.getElementById('password');
        const confirmPasswordInput = document.getElementById('confirmPassword');
        
        const handlePasswordInput = () => {
            if (passwordInput.value == ""){
                setErrorMessage("");
                setShowError(false);
            }
            else if (!validatePassword(passwordInput.value)) {
                setShowError(true);
                setErrorMessage("Password must be at least 8 characters long and contain an uppercase letter, a lowercase letter, and a number.");
            } else if (passwordInput.value !== confirmPasswordInput.value) {
                setErrorMessage("Passwords do not match!");
                setShowError(true);
            } else {
                setErrorMessage("");
                setShowError(false);
            }
        };
        passwordInput.addEventListener('input', handlePasswordInput);
        confirmPasswordInput.addEventListener('input', handlePasswordInput);

        return () => {
            passwordInput.removeEventListener('input', handlePasswordInput);
            confirmPasswordInput.removeEventListener('input', handlePasswordInput);
        };
    }, []);
    useEffect(() => {
        const passwordInput = document.getElementById('password');
        const confirmPasswordInput = document.getElementById('confirmPassword');

        passwordInput.addEventListener('input', validatePassword);
        confirmPasswordInput.addEventListener('input', validatePassword);

        // Cleanup event listeners on component unmount
        return () => {
            passwordInput.removeEventListener('input', validatePassword);
            confirmPasswordInput.removeEventListener('input', validatePassword);
        };
    }, []);

    const [showEditIcon, setShowEditIcon] = useState(false);

    return (
        <>
            {isWide ? <Navbar /> : <NavbarMobile />}
            <div className="fullScreen && ProfilePageMainDiv">
                <div className={isWide ? "profile-container" : "profile-container-mobile"}>
                    <div className="personal-info-header">
                        <h1>My Profile</h1>
                    </div>
                    <div>
                        <h5>Manage your account here</h5>
                    </div>
                    <ProfileNotificationAdminTab initialValue={0}></ProfileNotificationAdminTab>
                    <div className={isWide ? "profile-image-container" : "profile-image-containe-mobile"}>
                        {/* The profile image will be displayed here */}
                        <Snackbar
                            open={snackbarOpen}
                            autoHideDuration={6000}
                            onClose={handleSnackbarClose}
                            anchorOrigin={ { vertical: 'top', horizontal: 'center' }}
                        >
                            <Alert onClose={handleSnackbarClose} severity="success">
                                {snackbarMessage}
                            </Alert>
                        </Snackbar>
                        <Popup trigger=
                            {<button className="profile-image-inner-container">
                            <div className="profile-image-wrapper">
                                {!file && 
                                    <img 
                                        src={defaultImage} 
                                        // style={{ width: '150px', height: '150px', overflow: 'hidden' }} 
                                    />
                                }
                                {file &&  
                                    <img 
                                        src={file} 
                                    />
                                }
                                <div className='edit-icon'>
                                    Edit Profile <br/>Picture
                                    <img src={editLogo} className='edit-icon-logo'/>
                                </div>
                            </div>
                            </button>} 
                            modal nested>
                            {
                                close => (
                                    <div className='modal profile-popup-content'>
                                        <button className = "popup-close" onClick=
                                            {() => close()}>
                                                <FontAwesomeIcon icon={faXmark} size="3x" color="#0461CF" />
                                        </button>
                                        <div className='popup-title'>
                                            Profile Photo
                                        </div>
                                        <div className='popup-avatorEditor'>
                                            <AvatarEditor
                                                ref={editorRef}
                                                crossOrigin='anonymous'
                                                image={file}
                                                width={250}
                                                height={250}
                                                border={50}
                                                borderRadius={173}
                                                color={[0, 0, 0, 0.3]} // RGBA
                                                rotate={0}
                                                scale={imageScale}

                                            />
                                            
                                        </div>
                                        <div className = "popup-slider">
                                            <input type="range" min={0.5} max={3} step = {0.05} value={imageScale} class="slider" id="profilePopupSlider" onChange={handleSliderChange}></input>
                                        </div>
                                        <div className='popup-upload'>
                                            <button className="popup-upload-button" onClick={handleFileClick}>Upload Photo</button>
                                            <input
                                                ref={fileInputRef}
                                                type="file"
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange}
                                            />
                                            <button className="popup-upload-button" onClick={() => handleSavePhoto(close)}>Save</button>
                                        </div>
                                        <div className='popup-remove'>
                                            <button className="popup-cancel-button" onClick={handleRemovePhoto}>Remove Photo</button>
                                        </div>
                                    </div>
                                )
                            }
                        </Popup>
                        <div>
                            <div className={isWide ? "personal-info-header": "personal-info-header-mobile"}>
                                <h3>Personal Info</h3>
                            </div>
                            
                            <Grid container columnGap={2} > 
                                <Grid item xs={isWide ? 12 : "calc(50%-8px)"} lg={5} sx={{flexGrow:1}}>
                                    <div className={isWide ? "input-group": "input-group-mobile"}>
                                        <label>First Name</label>
                                        <input type="text" name = "firstName" value={profile.firstName || ''} placeholder="Enter first name" onChange={handleChange} />
                                    </div>
                                </Grid>
                                <Grid item xs={isWide ? 12 : "calc(50%-8px)"} lg={5} sx={{flexGrow:1}}>
                                    <div className={isWide ? "input-group": "input-group-mobile"}>
                                        <label>Last Name</label>
                                        <input type="text" name = "lastName" value={profile.lastName || ''} placeholder="Enter last name" onChange={handleChange} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={5}>
                                    <div className={isWide ? "input-group": "input-group-mobile"}>
                                        <label>Phone Number</label>
                                        <input type="text" name = "phoneNumber" value={profile.phoneNumber || ''} placeholder="Enter phone number" onChange={handleChange} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={5}>
                                    <div className={isWide ? "readonly-group": "readonly-group-mobile"}>
                                        <label>Email</label>
                                        <input type="text" className="input-group-readonly" value={profile.email} onChange={handleChange} readOnly />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <ConnectLineComponent />
                    { !isGoogleUser &&
                    (isWide ? (<div className="update-security" style={{ paddingLeft: isWide ? "190px" : "0px" }}>
                        {/* Wide screen */}
                        <h4>Security</h4>
                        <p>Change your password</p>
                        <div className='update-security-container'>
                            <label>Current Password</label>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input type="text" placeholder="Enter Current Password" />
                            </div>
                        </div>
                        <div className='update-security-container'>
                            <label>New Password</label>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input type="text" id="password" placeholder="Enter New Password" />
                            </div>
                        </div>
                        {/* Todo: check if two inputs are identical*/}
                        <div className='update-security-container'>
                            <label>New Password Confirm</label>
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                <input type="text" id="confirmPassword" placeholder="Enter New Password to confirm" />
                            </div>
                        </div>
                        <div className='update-security-container'>
                            {showError && (
                                <Alert severity="error">{errorMessage}</Alert>
                            )}
                        </div>
                        <ConnectLineComponent />
                    </div>) : (<div className="update-security-mobile" style={{ paddingLeft: isWide ? "190px" : "0px" }}>
                        {/* Mobile screen */}
                        <h4>Security</h4>
                        <div style={{ marginLeft: 120, width: '100%', textAlign: 'center' }}>
                            <p>Change your password</p>
                        </div>
                        <div className="update-security-container-mobile">
                            <label>Current Password</label>
                            <input type="text" placeholder="Enter Current Password" />
                        </div>
                        <div className="update-security-container-mobile">
                            <label>New Password</label>
                            <input type="text" id="password" placeholder="Enter New Password" />
                        </div>
                        <div className="update-security-container-mobile">
                            <label>New Password Confirm</label>
                            <input type="text" id="confirmPassword" placeholder="Enter New Password to confirm" />
                        </div>
                        <ConnectLineComponent />
                    </div>))}
                    
                    <div>
                        <button className={`save-buttons ${isWide ? '' : 'save-buttons-mobile'}`} onClick={handleCancel}>Cancel</button>
                        <button className={`save-buttons ${isWide ? '' : 'save-buttons-mobile'}`} onClick={handleSave} disabled={!hasChanges()} >Save changes</button>
                    </div>
                </div>
                {isWide ? <Footer /> : <FooterMobile />}
            </div>
        </>
    )
}