import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function RectangleCardMobile({ id, houseInfo }) {
  return (
    <Card id={id} sx={{
      width: '170px',
      height: '180px',
      borderRadius: '10px',
      boxSizing: 'border-box',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      marginTop: '20px'
    }} >
      <CardActions sx={{ height: '100%', padding: 0 }}>
        <CardContent sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: 0,
          position: 'relative',
          width: '100%',
        }}>
          <img src={houseInfo.img} alt="houseImage" style={{ height: '65%', width: '100%', position: 'absolute' }} />
          <div style={{ width: '100%', zIndex: '99', display: 'flex', flexDirection: 'column', padding: '3px 5px', paddingTop: '140px' }}>
            <p style={{ margin: 0, fontSize: '10px' }}><span style={{ fontWeight: 'bold' }}>$</span>{houseInfo.price}</p>
            <div style={{ display: 'flex', justifyContent: 'flex-start', maxWidth: '70%'}}>
              <p style={{ margin: 0, fontSize: '10px', flex: 1 }}><span style={{ fontWeight: 'bold', marginRight: '2px' }}>{houseInfo.bedroomNum}</span>bd</p>
              <p style={{ margin: 0, fontSize: '10px', flex: 1 }}><span style={{ fontWeight: 'bold', marginRight: '2px' }}>{houseInfo.bathroomNum}</span>ba</p>
              <p style={{ margin: 0, fontSize: '10px', flex: 1 }}><span style={{ fontWeight: 'bold', marginRight: '2px' }}>{houseInfo.size}</span>sqf</p>
            </div>
            <p style={{ margin: 0, fontSize: '8.5px', textWrap:'wrap'}}>{houseInfo.address}</p>
          </div>
        </CardContent>
      </CardActions>
    </Card>
  );
}