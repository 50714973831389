import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

function ConnectLineComponent() {
  const theme = useTheme();
  return (
    <Box
      className="connect-line"
      sx={{
        mt: 2.5, // margin-top: 25px (1 theme spacing unit is usually 10px)
        borderBottom: `1px solid ${theme.palette.divider}`
      }}
    >
      {/* Your content here */}
    </Box>
  );
}

export default ConnectLineComponent;
