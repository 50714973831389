import homePageIcon from '../../../../assets/icons/logo2.png'
import './SearchSection.css'
import SearchBar from '../../SearchBar/SearchBar'

export default function SearchSection(){

    return (
        <div className="mainDivSearch">
            <span>
                <img src={homePageIcon} alt="" />
                <p>What are you looking for?</p>
            </span>
            <SearchBar />
        </div>
    )
}