import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function ProfileNotificationAdminTab({ initialValue = 0 }) {  // Add a prop for the initial value with a default
  const [value, setValue] = React.useState(initialValue);  // Use the prop to set the initial state
  const navigate = useNavigate();
  const isWide = useSelector(state => state.windowSize.isWide);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate('/profile');  // Navigate to /profile
        break;
      case 1:
        navigate('/notification');  // Navigate to /notifications
        break;
      case 2:
        navigate('/admin');  // Navigate to /admin
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
        sx={{
          borderBottom: 1,
          borderColor: 'divider'
        }}>
        <Tab label="Profile" sx={{ color: 'text.primary', textTransform: 'capitalize' }} />
        <Tab label="Notifications" sx={{ color: 'text.primary', textTransform: 'capitalize' }} />
        <Tab label="Admin" sx={{ color: 'text.primary', textTransform: 'capitalize' }}/>
      </Tabs>
    </div>
  );
}