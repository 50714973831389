import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {
  Typography,
  Container,
  Grid,
  TextField,
  Button,
  Box,
  IconButton, MenuItem, Select,
} from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Navbar from "../component/Navigation/NavBar";
import NavbarMobile from "../component/Navigation/NavBarMobile";
import fileUploadImage from "../../assets/images/file-upload.png";
import backToAdmin from "../../assets/images/Arrow.png";
import ListingTab from "../component/Tabs/ListingTab/ListingTab";
import "./MobileEditListing.css";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {fetchOneListingData} from "../../services/fetchData";
import {deleteObject, getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";

export default function MobileEditListingPage({ isWide, user }) {
  const [activeTab, setActiveTab] = useState("profile");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [modalImage, setModalImage] = useState(null);
  const [isModified, setIsModified] = useState(false);
  const location = useLocation();
  const storage = getStorage();
  const { propertyID } = useParams();
  const navigate = useNavigate();
  //const { listing } = location;

  // State variables for input fields
  const [updatedListing, setUpdatedListing] = useState({
    Address: "",
    AppraisedValue:  "",
    PropertyTax:  "",
    HOA:  "",
    Utility:  "",
    Size:  "",
    Beds:  "",
    Bathrooms:  "",
    YearBuilt: "",
    Basement:  "",
    ParkingSpaces:  "",
    Deck:  "",
    Patio:  "",
    FloorFinished:  "",
    FireplaceType:  "",
    Images: []
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    onPageLoad();
  }, []);

  //when loading the page receive the uid and property info
  const onPageLoad = async (event) => {
    try {
      const response = await fetchOneListingData(propertyID);
      setUpdatedListing({
        Address: response.Address || "",
        AppraisedValue: response.AppraisedValue || 0,
        PropertyTax: response.PropertyTax || 0,
        HOA: response.HOA || 0,
        Utility: response.Utility || "",
        Size: response.Size || 0,
        Beds: response.Beds || 0,
        Bathrooms: response.Bathrooms || 0,
        YearBuilt: response.YearBuilt || 0,
        Basement: response.Basement || "",
        ParkingSpaces: response.ParkingSpaces || 0,
        Deck: response.Deck || false,
        Patio: response.Patio || false,
        FloorFinished: response.FloorFinished || "",
        FireplaceType: response.FireplaceType || "",
        Images: response.Images || []
      });
    } catch (error) {
      console.error('Error getting one listing:', error);
    }

  };

  // Use another useEffect to log updatedListing whenever it changes
  useEffect(() => {
    console.log('Updated listing:', updatedListing);
  }, [updatedListing]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedListing((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Clear error if the value is a valid number
    const numberFields = ['PropertyTax', 'HOA', 'Beds', 'Bathrooms', 'YearBuilt',
      'AppraisedValue', 'Size', 'ParkingSpaces'];
    if (numberFields.includes(name)) {
      if (/^\d*\.?\d*$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: null,
        }));
      }
    }
    /*
    //number validation
    if (['PropertyTax', 'HOA', 'Beds', 'Bathrooms',
      'YearBuilt', 'AppraisedValue', 'Size', 'ParkingSpaces'].includes(name)) {
      if (!/^\d*\.?\d*$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Please enter a number",
        }));
        return;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }
    //turn the String input value into Number
    setUpdatedListing((prevData) => ({
      ...prevData,
      [name]: ['PropertyTax', 'HOA', 'Beds', 'Bathrooms',
        'YearBuilt', 'AppraisedValue', 'Size', 'ParkingSpaces'].includes(name) ? Number(value) : value,
    }));
     */
  };

  const handleFileUpload = async (event) => {
    const files = Array.from(event.target.files);
    const maxImages = 20;
    if (updatedListing.Images.length + files.length > maxImages) {
      alert(`You can only upload up to ${maxImages} images.`);
      return;
    }
    const uploadImage = async (file) => {
      // Check for existing images with the same name
      const existingImages = updatedListing.Images.filter((image) =>
          image.url.includes(file.name)
      );
      let fileName = `${propertyID}_${file.name}`;
      if (existingImages.length > 0) {
        const suffix = ` ${existingImages.length + 1}`;
        const nameParts = fileName.split(".");
        const extension = nameParts.pop();
        fileName = `${nameParts.join(".")}${suffix}.${extension}`;
      }

      // Create a storage reference with a unique file name
      console.log("File:", fileName);
      console.log("Timestamp:", new Date().toISOString());
      const fileRef = ref(storage, `images/${fileName}`);
      // Upload the file
      await uploadBytes(fileRef, file);
      console.log("File uploaded successfully.");
      // Get the download URL
      const url = await getDownloadURL(fileRef);
      console.log("File available at:", url);
      return {
        url: url,
        name: fileName,
        createdBy: new Date().toISOString(),
      };
    };

    try {
      const uploadPromises = files.map(uploadImage);
      const imagesWithTimestamps = await Promise.all(uploadPromises);
      console.log("Images with timestamps:", imagesWithTimestamps);
      setUpdatedListing((prevUpdatedListing) => ({
        ...prevUpdatedListing,
        Images: [...prevUpdatedListing.Images, ...imagesWithTimestamps],
      }));
      setIsModified(true);
      console.log("Updated listing with new images:", updatedListing);
    } catch (error) {
      console.error("Error uploading images:", error);
    }
    event.target.value = null;
  };

  const removeImage = async (index) => {
    const imageName = updatedListing.Images[index].name;
    console.log("Remove image:", imageName);
    // Create a reference to the file to delete
    const imageRef = ref(storage, `images/${imageName}`);
    console.log("Remove image:", imageRef);

    try {
      await deleteObject(imageRef);

      // Successful deletion, filter out the image from listing.Images
      setUpdatedListing((prevUpdatedListing) => {
        const updatedImages = prevUpdatedListing.Images.filter((_, i) => i !== index);
        return { ...prevUpdatedListing, Images: updatedImages };
      });
      console.log(
          "Image removed successfully from Firebase Storage and listing."
      );
      setIsModified(true);
    } catch (error) {
      console.error("Error removing image from Firebase Storage:", error);
    }


  };

  const updateListing = async (formData) => {
    console.log(formData)
    try {
      const response = await axios.put('http://ec2-18-218-73-66.us-east-2.compute.amazonaws.com:3001/api/user/' + 'JiXXUDh2vTUjdNgoq1POHCRHTOk1'
          + '/102/update-listing', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error posting listing:', error);
    }
  };

  const validateInputs = () => {
    const newErrors = {};
    const numberFields = ['PropertyTax', 'HOA', 'Beds', 'Bathrooms', 'YearBuilt',
      'AppraisedValue', 'Size', 'ParkingSpaces'];

    numberFields.forEach(field => {
      if (!/^\d*\.?\d*$/.test(updatedListing[field])) {
        newErrors[field] = "Please enter a number";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const stringToNumber = () => {
    const fields = ['PropertyTax', 'HOA', 'Beds', 'Bathrooms', 'YearBuilt',
      'AppraisedValue', 'Size', 'ParkingSpaces'];

    fields.forEach(field => {
      updatedListing[field] = Number(updatedListing[field]);
    })
  }


  const saveChanges = () => {
    if(validateInputs()) {
      stringToNumber();
      updateListing(updatedListing);
      navigate("/admin");
    }
  };

  const renderTextField = (name, className = "text") => (
    <TextField
        variant="outlined"
        fullWidth
        margin="normal"
        name={name}
        className={className}
        value={updatedListing[name]}
        onChange={handleInputChange}
        error={!!errors[name]}
        helperText={errors[name]}
        sx={{
          marginTop: '0px',
          marginBottom: '20px',
          '& .MuiFormHelperText-root': {
            marginLeft: '5px',
            marginRight: '0px',

          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#0461cf', // Default border color
            },
            '&:hover fieldset': {
              borderColor: '#0461cf', // Border color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: '#0461cf', // Border color when focused
            },
          },
          '& .MuiInputBase-input': {
            padding: '15px 5px', // Padding inside the input
          },
        }}
    />
  );
  const renderSelect = (name, className = "text") => (
    <Select
        className={className}
        id={name}
        name={name}
        value={updatedListing[name]}
        onChange={handleInputChange}
        sx={{width: "100%",
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#0461cf',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#0461cf',
          },
          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#0461cf',
          },
          '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
            padding:'15px 5px'
          }
        }}
    >
      <MenuItem value={true}>Have</MenuItem>
      <MenuItem value={false}>Don't have</MenuItem>
    </Select>
  );


  return (
    <>
      <div className="edit-listing">
        {isWide ? <Navbar /> : <NavbarMobile />}
        <Container>
          <div className="arrow">
            <a href="/admin" className="back-to-admin-link">
              <img src={backToAdmin} alt="Back to Admin"/>
            </a>
          </div>
          <div
              className="edit-listing-header"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
                gap: "5px",
              }}
          >
            <Typography
                variant="h4"
                className="listing-title"
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.6rem",
                  textAlign: "left",
                }}
            >
              Edit Listing
            </Typography>

            <Typography
                variant="h1"
                className="listing-title"
                sx={{
                  fontSize: "1rem",
                  textAlign: "left",
                }}
            >
              Edit your listing Easily!
            </Typography>
            <ListingTab activeTab={activeTab} setActiveTab={setActiveTab}/>
          </div>
          <div className="edit-list-form">
            <div className="bg-canvas"></div>
            <div className="flex1">
              <label htmlFor="Address" className="label flex1-label">
                Address
              </label>
              {renderTextField("Address", "inputField flex1-input")}

              <label htmlFor="AppraisedValue" className="label flex1-label">
                Appraised Value
              </label>
              {renderTextField("AppraisedValue", "inputField flex1-input")}

              <label htmlFor="PropertyTax" className="label flex1-label">
                Property Tax
              </label>
              {renderTextField("PropertyTax", "inputField flex1-input")}

              <label htmlFor="HOA" className="label flex1-label">
                HOA
              </label>
              {renderTextField("HOA", "inputField flex1-input")}

              <label htmlFor="Utility" className="label flex1-label">
                Utility
              </label>
              {renderTextField("Utility", "inputField flex1-input")}

              <label htmlFor="Size" className="label flex1-label">
                Size (SQFT)
              </label>
              {renderTextField("Size", "inputField flex1-input")}

            </div>

            <div className="flex2">
              <div className="beds-container">
                <label htmlFor="Beds" className="label flex2-label">
                  # Beds
                </label>
                {renderTextField("Beds", "inputField flex2-input")}
              </div>

              <div className="baths-container">
                <label htmlFor="Bathrooms" className="label flex2-label">
                  # Baths
                </label>
                {renderTextField("Bathrooms", "inputField flex2-input")}
              </div>
            </div>

            <div className="flex1">
              <label htmlFor="YearBuilt" className="label flex1-label">
                Year Built
              </label>
              {renderTextField("YearBuilt", "inputField flex1-input")}

              <label htmlFor="Basement" className="label flex1-label">
                Basement Finish
              </label>
              {renderTextField("Basement", "inputField flex1-input")}

              <label htmlFor="ParkingSpaces" className="label flex1-label">
                Parking
              </label>
              {renderTextField("ParkingSpaces", "inputField flex1-input")}

              <label htmlFor="Deck" className="label flex1-label">
                Deck
              </label>
              {renderSelect("Deck", "")}

              <label htmlFor="Patio" className="label flex1-label">
                Patio
              </label>
              {renderSelect("Patio", "")}

              <label htmlFor="FloorFinished" className="label flex1-label">
                Floor Finish
              </label>
              {renderTextField("FloorFinished", "inputField flex1-input")}

              <label htmlFor="FireplaceType" className="label flex1-label">
                Fireplace
              </label>
              {renderTextField("FireplaceType", "inputField flex1-input")}
            </div>
          </div>
          <form className="edit-list-form">
            <div className="bg-canvas"></div>
            <div className="mobile-file-upload">
              <Typography
                  variant="h6"
                  sx={{fontWeight: "bold", mb: 1, fontFamily: "Roboto"}}
                  className="label"
              >
                Media
              </Typography>

              <label htmlFor="files" className="file-upload-wrapper">
                <div className="inputField file-uploaded">
                  <input
                      id="files"
                      type="file"
                      accept=".jpg, .png, .jpeg"
                      style={{display: "none"}}
                      onChange={handleFileUpload}
                      multiple
                  />
                  <img
                      htmlFor="file-upload"
                      className="MB-file-image"
                      src={fileUploadImage}
                  />
                  <label
                      htmlFor="file-upload"
                      style={{
                        fontSize: "20px",
                        fontWeight: "bolder",
                        cursor: "pointer",
                        width: "180px",
                        margin: "0"
                      }}
                      className="label file"
                  >
                    Drag images here or click to upload
                  </label>
                  <label
                      htmlFor="file-upload"
                      style={{
                        fontSize: "18px",
                        fontWeight: "lighter",
                        cursor: "pointer",
                      }}
                      className="label file"
                  >
                    Formats: JPG and PNG
                  </label>
                </div>
              </label>
              <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "left",
                    overflowX: "hidden",
                    width: "90%",
                    height: "100%",
                    margin: "auto",
                  }}
              >
                <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      width: "70vw",
                      height: "auto",
                    }}
                >
                  <Carousel
                      showThumbs={false}
                      centerMode
                      centerSlidePercentage={33}
                      gap={6}
                      width={"100%"}
                  >
                    {updatedListing.Images.map((image, index) => (
                        <div
                            key={index}
                            className="uploaded-image-wrapper"
                            style={{
                              marginRight: "2px",
                              marginLeft: "2px",

                              width: "90%",
                            }}
                        >
                          <img
                              src={image.url}
                              alt="Uploaded"
                              className="MB-uploaded-images"
                              onDoubleClick={() => setModalImage(image.url)}
                              onLoad={() => console.log(`Image ${image.url} loaded successfully`)}
                              style={{
                                objectFit: "contain",
                              }}
                          />

                          <button
                              type = "button"
                              onClick={(e) => {e.preventDefault();
                            removeImage(index)}}
                              className="MB-remove-image-button"
                          >
                            X
                          </button>
                        </div>
                    ))}
                  </Carousel>

                  <Modal
                      isOpen={modalImage !== null}
                      onRequestClose={() => setModalImage(null)}
                      contentLabel="Enlarged Image"
                  >
                    <img src={modalImage} alt="Enlarged"/>
                  </Modal>
                </div>

                {updatedListing.Images.length > 0 && (
                    <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                    >
                      <label htmlFor="more-images" className="add-more-image">
                        Add more Images
                        <input
                            id="more-images"
                            type="file"
                            accept=".jpg, .png, .jpeg"
                            style={{display: "none", alignItems: "center"}}
                            onChange={handleFileUpload}
                            multiple
                        />
                      </label>
                    </div>
                )}
              </div>
            </div>

          </form>
          <button className="changes-button" onClick={saveChanges}>save changes</button>
          <button className="cancel-button" onClick={() => navigate('/admin')}>Cancel</button>

        </Container>
      </div>
    </>
  );
}
