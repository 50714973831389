// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-body {
    padding-top: 10px;
    padding-bottom: 20px;
    border-top: 10px solid #007bff;
    margin-top: 10px;
    text-align: left;
}

.title-text {
    padding-top: 50px;
    text-align: center;
    font-size: 30px;
    width: fit-content;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.content {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
`, "",{"version":3,"sources":["webpack://./App/Pages/TOU/TOUpage.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,8BAA8B;IAC9B,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,qBAAqB;AACzB","sourcesContent":[".text-body {\r\n    padding-top: 10px;\r\n    padding-bottom: 20px;\r\n    border-top: 10px solid #007bff;\r\n    margin-top: 10px;\r\n    text-align: left;\r\n}\r\n\r\n.title-text {\r\n    padding-top: 50px;\r\n    text-align: center;\r\n    font-size: 30px;\r\n    width: fit-content;\r\n    display: block;\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n}\r\n\r\n.content {\r\n    padding: 20px;\r\n    max-width: 1200px;\r\n    margin: 0 auto;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: flex-end;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
