import BulletStyledCardMobile from "../../Cards/BulletStyledCard/BulletStyledCardMobile";
import dollorIcon from '../../../../assets/icons/moneyIcon.png'
import buyIcon from '../../../../assets/icons/buyIcon.png'
import BuyImage from '../../../../assets/images/homePage/BulletStyledCardBuy.png'
import SellImage from '../../../../assets/images/homePage/BulletStyledCardSell.png'

export default function BuySellCards() {
    return (
        <div style={{ paddingTop: '10px', display: "flex", width: '100%', justifyContent: 'space-evenly', alignContent: 'center', margin: '25px 0px' }}>
            <BulletStyledCardMobile image={SellImage} icon={dollorIcon} text={'BUY'} />
            <BulletStyledCardMobile image={BuyImage} icon={buyIcon} text={'SELL'} />
        </div>
    )
}