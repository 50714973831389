import axios from "axios";
import Constants from "expo-constants";

const PORT = Constants.expoConfig.extra.PORT;
const URL = Constants.expoConfig.extra.API_BASE_URL;
const baseUrl = `${URL}:${PORT}/api`;

export const checkAdmin = async (userId) => {
  try {
    console.log("URL:", URL);
    console.log("PORT:", PORT);
    console.log("baseUrl:", baseUrl);
    const response = await axios.get(`${baseUrl}/user/check-admin/${userId}`);
    console.log("Response:", response.data);
    return response.data.isAdmin;
  } catch (error) {
    console.error("Error checking admin status:", error);
    throw error;
  }
};

export const deleteListing = async (userId, listingId) => {
  try {
    const response = await axios.delete(
      `${baseUrl}/user/${userId}/${listingId}/delete-listing`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting listing:", error);
    throw error;
  }
};

export const updateListing = async (
  userId,
  listingId,
  updatedPropertyInformation
) => {
  try {
    const response = await axios.put(
      `${baseUrl}/user/${userId}/${listingId}/update-listing`,
      updatedPropertyInformation
    );
    return response.data;
  } catch (error) {
    console.error("Error updating listing:", error);
    throw error;
  }
};

export const addListing = async (userId, propertyInformation) => {
  console.log(userId, propertyInformation);
  try {
    const response = await axios.post(
      `${baseUrl}/user/${userId}/post-listing`,
      propertyInformation
    );
    return response.data;
  } catch (error) {
    if(error.response){
      console.error("Server Response Error:", error.response.status);
      console.error("Server Response Data:", error.response.data);
    }
    else if(error.request){
      console.error("No response received:", error.request);
    }
    else{
      console.error("Error", error.message);
    }
    console.error("Error adding listing:", error.config);
    throw error;
  }
};
