import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';



const PasswordResetPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert('Password reset email sent!');
      })
      .catch((error) => {
        console.error('Error sending password reset email:', error);
      });
  };

  const handleBack = () => {
    navigate('/'); 
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h1>Password Reset</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
          <button className="back-button" onClick={handleBack}>Back</button>

          <button type="submit">Send Reset Email</button>
        </form>
      </div>
    </div>
  );
};

export default PasswordResetPage;
