import React, { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import RectangleCard from '../../Cards/RectangleCard/RectangleCard';

export default function CarouselHomePage({ cardsData }) {
    const cardWidth = 320;
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const [visibleCards, setVisibleCards] = useState(Math.floor(viewportWidth / cardWidth));
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % cardsData.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + cardsData.length) % cardsData.length);
    };

    const updateViewportWidth = () => {
        setViewportWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateViewportWidth);
        return () => window.removeEventListener('resize', updateViewportWidth);
    }, []);

    useEffect(() => {
        setVisibleCards(Math.floor((viewportWidth - 420) / cardWidth));
    }, [viewportWidth]);

    const getCardIndicesToShow = () => {
        let indices = [];
        for (let i = 0; i < visibleCards; i++) {
            let index = (currentIndex + i) % cardsData.length;
            indices.push(index);
        }
        return indices;
    };

    const cardIndicesToShow = getCardIndicesToShow();

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
            <IconButton onClick={handlePrev} aria-label="Previous" sx={{marginRight: '40px'}}>
                <ArrowBackIos />
            </IconButton>
            {cardIndicesToShow.map((index) => (
                <div key={index} style={{ marginRight: '40px' }}>
                    <RectangleCard {...cardsData[index]} />
                </div>
            ))}
            <IconButton onClick={handleNext} aria-label="Next">
                <ArrowForwardIos />
            </IconButton>
        </div>
    );
};







