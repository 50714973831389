import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const useScrollRestoration = () => {
  const { pathname } = useLocation();
  const scrollPositions = useRef({});

  useEffect(() => {
    if (pathname in scrollPositions.current) {
      window.scrollTo(0, scrollPositions.current[pathname]);
    }
  }, [pathname]);

  useEffect(() => {
    const saveScrollPosition = () => {
      scrollPositions.current[pathname] = window.scrollY;
    };

    window.addEventListener("beforeunload", saveScrollPosition);
    return () => {
      window.removeEventListener("beforeunload", saveScrollPosition);
      scrollPositions.current[pathname] = window.scrollY;
    };
  }, [pathname]);
};

export default useScrollRestoration;
