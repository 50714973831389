// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overall {
    font-family: 'Roboto', sans-serif;
}

.showmore-content {
    padding-bottom: 20px;
}

.arrow-image {
    margin-right: 10px;
    transform: rotate(-90deg); 
    transition: transform 0.3s ease; /* Smooth rotation */
    filter: invert(33%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(91%) contrast(89%);
    transform-origin: center; /* Ensure rotation happens around the center */
}

.arrow-image.rotate {
    transform: rotate(90deg); /* Rotate by 90 degrees to point down */
}

.showmore-text {
    margin-left: 8px;
    font-size: 16px;
    font-weight: 300;
    color: grey;
}

.toggle-button {
    border: none;
    cursor: pointer;
    color: grey;
}
`, "",{"version":3,"sources":["webpack://./App/Pages/component/BuyPageComps/ShowMoreRows2.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;AACrC;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,+BAA+B,EAAE,oBAAoB;IACrD,2FAA2F;IAC3F,wBAAwB,EAAE,8CAA8C;AAC5E;;AAEA;IACI,wBAAwB,EAAE,uCAAuC;AACrE;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,WAAW;AACf","sourcesContent":[".overall {\r\n    font-family: 'Roboto', sans-serif;\r\n}\r\n\r\n.showmore-content {\r\n    padding-bottom: 20px;\r\n}\r\n\r\n.arrow-image {\r\n    margin-right: 10px;\r\n    transform: rotate(-90deg); \r\n    transition: transform 0.3s ease; /* Smooth rotation */\r\n    filter: invert(33%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(91%) contrast(89%);\r\n    transform-origin: center; /* Ensure rotation happens around the center */\r\n}\r\n\r\n.arrow-image.rotate {\r\n    transform: rotate(90deg); /* Rotate by 90 degrees to point down */\r\n}\r\n\r\n.showmore-text {\r\n    margin-left: 8px;\r\n    font-size: 16px;\r\n    font-weight: 300;\r\n    color: grey;\r\n}\r\n\r\n.toggle-button {\r\n    border: none;\r\n    cursor: pointer;\r\n    color: grey;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
