import React from "react";
import image from '../../../assets/images/buyPageImages/houseBgClick.png';
import "typeface-roboto";
import FilledBgBtn from '../Buttons/FilledBg/FilledBgBtn';
import { useSelector } from 'react-redux';

export default function Button1Click () {
    const FilledBgButtonStyle2 = {
        borderRadius: '30px',
        backgroundColor: '#3c73d6',
    };

    const isWide = useSelector(state => state.windowSize.isWide);

    return (
        <>
            <div className="total-container" style={{ paddingTop: '20px', paddingBottom: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', fontFamily: "'Roboto', sans-serif"}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <h2> $1,300,000 </h2>
                    <div>
                       <p>8832 Alexadnria Drive, Alexandria, VA 23001</p>
                        <p>Prop#, DOL at NEST, # of Views, # of Saves</p> 
                        {isWide || <p style={{ color: 'grey', fontSize: '12px'}}> Est. $5,400 a month @ 8.4% APR </p>}
                    </div>
                    <div style={{ alignItems: isWide ? 'flex-end' : 'center', paddingTop: '20px'}}>
                        <FilledBgBtn text={"Contact the Agent"} style={FilledBgButtonStyle2} />
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                    <p>#beds</p>
                    <p>#baths</p>
                    <p>#sq ft</p>
                </div>
                {isWide && <div><img src={image}/></div>}
            </div>
        </>
    );
}