import axios from 'axios';
import Constants from 'expo-constants';

const PORT = Constants.expoConfig.extra.PORT;

export const subscribeToNewsUpdate = async (email, fullName) => {
    const [firstName, ...lastName] = fullName.split(' ');
    const url = `http://ec2-18-218-73-66.us-east-2.compute.amazonaws.com:${PORT}/api/email-service/news-update/add-contact`;
    const response = await axios.post(url, {
        email,
        firstName,
        lastName: lastName.join(' '),
    });

    return response.data;
};
