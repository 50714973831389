import homePageIcon from '../../../../assets/icons/logo2.png';
import './NewsPageTitle.css'

export default function NewsPageTitle(){

    return (
        <div className="mainDivNewsPage" style={{display: 'flex', flexDirection: 'column'}}>
            <span>
                <img src={homePageIcon} alt="" />
                <p>Nest Newsroom</p>
            </span>
            <p style={{fontSize: '1.5rem', fontWeight: '500', marginBottom: '0'}}>
                Latest articles
            </p>
        </div>
    )
}