import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const PricePopup = ({ minPrice, setMinPrice, maxPrice, setMaxPrice, closePopup, includeMonthly, setIncludeMonthly }) => {
    const popupRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                const popover = event.target.closest('.MuiPopover-root');
                if (!popover) {
                    closePopup();
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [closePopup]);

    return (
        <Box sx={{ position: 'absolute', top: '45px', left: '0', width: '310px', p: 2, bgcolor: 'background.paper', boxShadow: 3, borderRadius: 2, zIndex: 2000, height: 190 }} ref={popupRef}>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                <FormControl margin="normal" sx={{ width: '120px'}}>
                    <InputLabel>Min</InputLabel>
                    <Select
                        value={minPrice}
                        label="Min"
                        onChange={(e) => setMinPrice(e.target.value)}
                        MenuProps={{ style: { zIndex: 2100 } }}
                    >
                        <MenuItem value=""><em>No min</em></MenuItem>
                        <MenuItem value={0}>0</MenuItem>
                        <MenuItem value={500}>$500</MenuItem>
                        <MenuItem value={1000}>$1,000</MenuItem>
                    </Select>
                </FormControl>
                <Typography variant="h6" sx={{ mx: 2 }}>–</Typography>
                <FormControl margin="normal" sx={{ width: '120px'}}>
                    <InputLabel>Max</InputLabel>
                    <Select
                        value={maxPrice}
                        label="Max"
                        onChange={(e) => setMaxPrice(e.target.value)}
                        MenuProps={{ style: { zIndex: 2100 } }}
                    >
                        <MenuItem value=""><em>No max</em></MenuItem>
                        <MenuItem value={1000}>$1,000</MenuItem>
                        <MenuItem value={2000}>$2,000</MenuItem>
                        <MenuItem value={3000}>$3,000</MenuItem>
                    </Select>
                </FormControl>
            </Stack>
            <FormControlLabel
                control={<Checkbox checked={includeMonthly} onChange={(e) => setIncludeMonthly(e.target.checked)} />}
                label="Include monthly payment listings"
                sx={{ mt: 2 , '.MuiFormControlLabel-label': { fontSize: '0.9rem' } }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2, mb: 1, width: '100%' }}>
                <Button variant="contained" onClick={closePopup} sx={{ 
                    borderRadius: '25px', 
                    width: '50%', 
                    fontSize: '0.75rem', 
                    padding: '4px',
                }}>Apply</Button>
            </Box>

        </Box>
    );
};

export default PricePopup;
