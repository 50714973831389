import React from 'react';
import '../component/styles/HomePage.css';
import NavbarMobile from '../component/Navigation/NavBarMobile';
import SearchSection from '../component/FinalLandingPage/SearchSection/SearchSection';
import BannerSection from '../component/FinalLandingPage/BannerSection/BannerSection';
import RegisterSection from '../component/FinalLandingPage/RegisterSection/RegisterSection';
import GuideSectionMobile from '../component/FinalLandingPage/GuideSection/GuideSectionMobile';
import FooterMobile from '../component/Footer/FooterMobile';
import BuySellCards from '../component/FinalLandingPageMoblie/BuySellCards/BuySellCards'
import CarouselHomePageMobile from '../component/FinalLandingPage/CarsoualHomePage/CarsoualHomePageMobile';
import sampleHouse1 from '../../assets/images/homePage/sampleHouse1.png';
import sampleHouse2 from '../../assets/images/homePage/sampleHouse2.png';

export default function MobileLandingPage() {
    const cardsData = [
        { id: 1, houseInfo: { price: '1,300,000', bedroomNum: '3', bathroomNum: '2', img: sampleHouse1, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
        { id: 2, houseInfo: { price: '1,300,000', bedroomNum: '3', bathroomNum: '2', img: sampleHouse2, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
        { id: 3, houseInfo: { price: '1,300,000', bedroomNum: '3', bathroomNum: '2', img: sampleHouse2, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
        { id: 4, houseInfo: { price: '1,300,000', bedroomNum: '3', bathroomNum: '2', img: sampleHouse1, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
    ];

    return (
        <>
          <NavbarMobile />
          <div className='fullScreen'>
            <SearchSection/>
            <BuySellCards/>
            <BannerSection/>
            <CarouselHomePageMobile cardsData={cardsData}/>
            <GuideSectionMobile />
            <RegisterSection/>
            <FooterMobile />
          </div>
        </>
      );
}