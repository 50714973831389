// Navbar.js
import React, { useEffect, useState } from "react";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faUser } from "@fortawesome/free-solid-svg-icons";
import "./NavBar.css";
import NoBorderBtn from "../Buttons/NoBorder/NoBorderBtn";
import Logo from "../../../assets/icons/logo1.png";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

const loginIcon = require("../../../assets/images/loginIcon.png");

const Navbar = ({ user, onLogout }) => {
  const BtnStyle = {
    color: "#3c73d6",
    fontWeight: "600",
    margin: "0vh 0.5vw",
  };

  const [currentUser, setCurrentUser] = useState(user);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, [auth]);

  const showDropdown = () => {
    setIsDropdownOpen(true);
  };

  const hideDropdown = () => {
    setIsDropdownOpen(false);
  };

  const handleLogin = () => {
    if (currentUser) {
      navigate("/profile");
    } else {
      navigate("/login");
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log("Logged out successfully");
      navigate("/login");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const handleBuyClick = () => {
    navigate("/listings");
  };

  const handleSellClick = () => {
    navigate("/sell");
  };

  const handleWhoClick = () => {
    navigate("/who");
  };

  const handleSavedClick = () => {
    navigate("/saved");
  };

  return (
    <div className="navBarMainDiv">
      <div>
        <img
          src={Logo}
          alt="NEST"
          className="logo"
          onClick={() => navigate("/")}
        />
      </div>
      <div>
        <NoBorderBtn
          style={BtnStyle}
          text={"BUY"}
          link={{ url: "/listings" }}
        />
        <NoBorderBtn style={BtnStyle} text={"SELL"} link={{ url: "/sell" }} />
        <NoBorderBtn
          style={BtnStyle}
          text={"WHO WE ARE"}
          link={{ url: "/who" }}
        />
        {currentUser && (
          <NoBorderBtn
            style={BtnStyle}
            text={"SAVED HOMES"}
            link={{ url: "/saved" }}
          />
        )}
        {currentUser && (
          <img
            src={loginIcon}
            alt="Login"
            className="login"
            onMouseEnter={showDropdown}
            onClick={handleLogin}
          />
        )}
        {isDropdownOpen && currentUser && (
          <div className="dropdown-menu" onMouseLeave={hideDropdown}>
            <ul>
              <li onClick={() => navigate("/profile")}>Profile</li>
              <li onClick={() => navigate("/admin")}>Activity</li>
              <li onClick={() => navigate("/notification")}>Notifications</li>
              <li className="no-hover">
                <hr />
              </li>
              <li
                onClick={() => setShowConfirmLogout(true)}
                className="logout-button"
              >
                Log Out
              </li>
            </ul>
          </div>
        )}
      </div>
      {showConfirmLogout && (
        <div className="confirmation-dialog">
          <div
            className="close-button"
            onClick={() => setShowConfirmLogout(false)}
          >
            ×
          </div>
          <img src={Logo} alt="NEST" className="dialog-logo" />
          <p>Are you sure you want to sign out?</p>
          <div className="line-horizontal"></div>
          <div className="options">
            <div
              className="option cancel"
              onClick={() => setShowConfirmLogout(false)}
            >
              Cancel
            </div>
            <div className="line-vertical"></div>
            <div className="option sign-out" onClick={handleLogout}>
              Sign Out
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
