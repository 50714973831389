import React from "react";
import "typeface-roboto";
import './Section3.css'

export default function S3Info () {
    return (
        <>
        <div className="container-section3">
            <div className="info-row">
                <div className="info-item">
                    <p className="info-text"> Built in Year </p>
                    <p style={{ border: '1px solid black', padding: '5px', borderRadius: '10px'}}> 2020 </p>
                </div>
                <div className="info-item">
                    <p className="info-text"> Basement </p>
                    <p style={{ border: '1px solid black', padding: '5px', borderRadius: '10px'}}> Un/finished (N/A) </p>
                </div>
                <div className="info-item">
                    <p className="info-text"> Parking </p>
                    <p style={{ border: '1px solid black', padding: '5px', borderRadius: '10px'}}> 2 car covered </p>
                </div>
                <div className="info-item">
                    <p className="info-text"> Deck/Patio </p>
                    <p style={{ border: '1px solid black', padding: '5px', borderRadius: '10px'}}> Deck and Patio </p>
                </div>
                <div className="info-item">
                    <p className="info-text"> Hardwood </p>
                    <p style={{ border: '1px solid black', padding: '5px', borderRadius: '10px'}}> Floor Finished </p>
                </div>
                <div className="info-item">
                    <p className="info-text"> Fireplace </p>
                    <p style={{ border: '1px solid black', padding: '5px', borderRadius: '10px'}}> Wood burning </p>
                </div>
            </div>
        </div>
        </>
    );
}