// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh; 
    width: 100vw; 
    position: relative; 
    display: flex; 
    justify-content: center;
    align-items: center;
}

.container {
    position: absolute; 
    left: 50%; 
    transform: translate(-50%, -50%);
    width: fit-content; 
    text-align: center; 
    padding-top: 70px; 
}

.subtext {
    color: blue;
}

.button {
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    width: 100%; 
    display: flex; 
    justify-content: center; 
}
`, "",{"version":3,"sources":["webpack://./App/Pages/component/ErrorPage/Error.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;IAC5B,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,gCAAgC;IAChC,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,WAAW;IACX,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".background {\r\n    background-size: cover;\r\n    background-position: center;\r\n    background-repeat: no-repeat;\r\n    height: 100vh; \r\n    width: 100vw; \r\n    position: relative; \r\n    display: flex; \r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.container {\r\n    position: absolute; \r\n    left: 50%; \r\n    transform: translate(-50%, -50%);\r\n    width: fit-content; \r\n    text-align: center; \r\n    padding-top: 70px; \r\n}\r\n\r\n.subtext {\r\n    color: blue;\r\n}\r\n\r\n.button {\r\n    position: absolute; \r\n    top: 50%; \r\n    left: 50%; \r\n    transform: translate(-50%, -50%); \r\n    width: 100%; \r\n    display: flex; \r\n    justify-content: center; \r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
