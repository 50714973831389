// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Warning.css */
.confirmation-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
  }
  
  .warning-dialog {
    background: white;
    border: 2px solid blue;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  .warning-dialog p {
    margin-bottom: 20px;
    margin-top: 20px;
    padding-top: 20px;
    font-size: 16px;
    color: blue;
  }
  
  .confirmation-dialog-buttons {
    display: flex;
    justify-content: space-evenly;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  .confirm-btn,
  .cancel-btn {
    background: blue;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .cancel-btn {
    background: lightgray;
    color: black;
  }
  
  .confirm-btn:hover,
  .cancel-btn:hover {
    opacity: 0.8;
  }
  `, "",{"version":3,"sources":["webpack://./App/Pages/component/WarningPopUp/Warning.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,iBAAiB;IACjB,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;IACf,WAAW;EACb;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,iBAAiB;IACjB,oBAAoB;EACtB;;EAEA;;IAEE,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,qBAAqB;IACrB,YAAY;EACd;;EAEA;;IAEE,YAAY;EACd","sourcesContent":["/* Warning.css */\r\n.confirmation-dialog-overlay {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    background: rgba(0, 0, 0, 0.5);\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    z-index: 1001;\r\n  }\r\n  \r\n  .warning-dialog {\r\n    background: white;\r\n    border: 2px solid blue;\r\n    border-radius: 10px;\r\n    padding: 20px;\r\n    text-align: center;\r\n    max-width: 400px;\r\n    width: 100%;\r\n  }\r\n  \r\n  .warning-dialog p {\r\n    margin-bottom: 20px;\r\n    margin-top: 20px;\r\n    padding-top: 20px;\r\n    font-size: 16px;\r\n    color: blue;\r\n  }\r\n  \r\n  .confirmation-dialog-buttons {\r\n    display: flex;\r\n    justify-content: space-evenly;\r\n    padding-top: 30px;\r\n    padding-bottom: 30px;\r\n  }\r\n  \r\n  .confirm-btn,\r\n  .cancel-btn {\r\n    background: blue;\r\n    color: white;\r\n    border: none;\r\n    border-radius: 5px;\r\n    padding: 10px 20px;\r\n    cursor: pointer;\r\n    font-size: 16px;\r\n  }\r\n  \r\n  .cancel-btn {\r\n    background: lightgray;\r\n    color: black;\r\n  }\r\n  \r\n  .confirm-btn:hover,\r\n  .cancel-btn:hover {\r\n    opacity: 0.8;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
