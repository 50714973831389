// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.popup-body {
  margin-bottom: 20px;
}

.popup-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 60px;
}

.popup-button {
  padding: 10px 20px;
  border: none;
  background-color: #3c73d6;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.popup-button:hover {
  background-color: #2a55a5;
}
`, "",{"version":3,"sources":["webpack://./App/Pages/component/PopUps/RemoveListing/ConfirmPopup.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,8BAA8B;EAC9B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,yCAAyC;AAC3C;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".popup-overlay {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  right: 0;\r\n  bottom: 0;\r\n  background: rgba(0, 0, 0, 0.5);\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  z-index: 1000;\r\n}\r\n\r\n.popup-content {\r\n  background: white;\r\n  padding: 20px;\r\n  border-radius: 5px;\r\n  text-align: center;\r\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.popup-body {\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.popup-actions {\r\n  display: flex;\r\n  flex-direction: row;\r\n  justify-content: center;\r\n  gap: 60px;\r\n}\r\n\r\n.popup-button {\r\n  padding: 10px 20px;\r\n  border: none;\r\n  background-color: #3c73d6;\r\n  color: white;\r\n  cursor: pointer;\r\n  border-radius: 5px;\r\n}\r\n\r\n.popup-button:hover {\r\n  background-color: #2a55a5;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
