import Error from "../component/ErrorPage/Error";
import React from "react";

export default function E500() {
    const handleRefresh = () => {
        window.location.reload(false);
    };

    return (
        <>
            <Error text={"Whoops! Something went wrong."} subtext={"500 Server Error"} subtext2={"Try refereshing the page or try again later. if the problem persists, please contact us."} buttonText={"Refresh Page"} buttonFunction={handleRefresh}/>
        </>
    );
}