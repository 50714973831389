// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listing-tab {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.MuiTabs-indicator {
  width: 100%;
  height: 500px;
  background-color: blue;
}

/*.listing-title {
  margin: 0;
  padding-left: 0;
  margin-bottom: 0;
  color: black;
  font-size: large;
  font-weight: bold;
}

.MuiTabs-root {
  position: relative;
}

.MuiTabs-root::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 2px solid gray;
  width: 100vw;
}

.MuiTabs-flexContainer::after {
  content: "";
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 2px solid gray;
  width: 100%;
}*/`, "",{"version":3,"sources":["webpack://./App/Pages/component/Tabs/ListingTab/ListingTab.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;;AAEA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;EA+BE","sourcesContent":[".listing-tab {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: flex-start;\r\n  width: 100%;\r\n}\r\n\r\n.MuiTabs-indicator {\r\n  width: 100%;\r\n  height: 500px;\r\n  background-color: blue;\r\n}\r\n\r\n/*.listing-title {\r\n  margin: 0;\r\n  padding-left: 0;\r\n  margin-bottom: 0;\r\n  color: black;\r\n  font-size: large;\r\n  font-weight: bold;\r\n}\r\n\r\n.MuiTabs-root {\r\n  position: relative;\r\n}\r\n\r\n.MuiTabs-root::after {\r\n  content: \"\";\r\n  position: absolute;\r\n  bottom: 0;\r\n  left: 0;\r\n  right: 0;\r\n  border-bottom: 2px solid gray;\r\n  width: 100vw;\r\n}\r\n\r\n.MuiTabs-flexContainer::after {\r\n  content: \"\";\r\n  position: relative;\r\n  bottom: 0;\r\n  left: 0;\r\n  right: 0;\r\n  border-bottom: 2px solid gray;\r\n  width: 100%;\r\n}*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
