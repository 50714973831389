import pencil from "../../../assets/icons/pencil.png";
import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import axios from "axios";
import { format, parseISO } from "date-fns";

function PencilOp() {
  return <img src={pencil} width={15} height={15} />;
}

// Editable Card Component
function EditableCard({ data, onSave, onDelete }) {
  const [editMode, setEditMode] = useState(false);
  const [searchDetails, setSearchDetails] = useState(data.details || "");

  const handleEdit = () => {
    if (editMode) {
      onSave(data.id, searchDetails);
    }
    setEditMode(!editMode);
  };

  const cardStyles = {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "start",
    minWidth: "400px",
  };

  // Safely parse and format the timestamp
  let formattedTimestamp = "";
  try {
    formattedTimestamp = format(parseISO(data.timestamp), "PPpp");
  } catch (error) {
    console.error("Error formatting timestamp:", error);
  }

  return (
    <Card variant="outlined" sx={{ mb: 2 }} style={cardStyles}>
      <CardContent>
        <Typography variant="h6">{data.address}</Typography>
        <Typography variant="subtitle1">
          {formattedTimestamp || "Invalid date"}
        </Typography>
        {editMode ? (
          <TextField
            fullWidth
            variant="outlined"
            value={searchDetails}
            onChange={(e) => setSearchDetails(e.target.value)}
            placeholder="Add details"
          />
        ) : (
          <Typography>{searchDetails || "Add details"}</Typography>
        )}
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
        }}
      >
        <Button size="small" onClick={handleEdit} startIcon={<PencilOp />}>
          {editMode ? "Save" : "Edit"}
        </Button>
        <Button size="small" onClick={() => onDelete(data.id)} color="error">
          Delete
        </Button>
      </CardActions>
    </Card>
  );
}

// Parent Component, contains multiple cards
function SavedSearches({ user, itemsPerPage, page }) {
  if (user) {
    console.log("User:", user.uid);
  }
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSavedSearches = async () => {
      try {
        const response = await axios.get(
          `http://ec2-18-218-73-66.us-east-2.compute.amazonaws.com:3001/api/saved-searches/getSearches/${user.uid}`
        );
        setCards(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching saved searches:", error);
        setLoading(false);
      }
    };

    fetchSavedSearches();
  }, []);

  const currentData = cards.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const count = Math.ceil(cards.length / itemsPerPage);

  const saveCardDetails = (id, details) => {
    const updatedCards = cards.map((card) => {
      if (card.id === id) {
        return { ...card, details };
      }
      return card;
    });
    setCards(updatedCards);
  };

  const deleteCard = async (id) => {
    try {
      console.log("Deleting search with ID:", id);
      await axios.delete(
        `http://ec2-18-218-73-66.us-east-2.compute.amazonaws.com:3001/api/saved-searches/deleteSearch/${user.uid}`,
        {
          data: { id },
        }
      );
      const updatedCards = cards.filter((card) => card.id !== id);
      setCards(updatedCards);
    } catch (error) {
      console.error("Error deleting search:", error);
    }
  };

  return (
    <div style={{ paddingTop: "10px" }}>
      <h2 style={{ marginBottom: "0px" }}>Saved Searches</h2>
      <p style={{ marginBottom: "30px" }}>
        Saved searches (Searches can only be saved when users are logged into
        their accounts)
      </p>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        currentData.map((card) => (
          <div key={card.id} style={{ maxWidth: "1000px" }}>
            <EditableCard
              data={card}
              onSave={saveCardDetails}
              onDelete={deleteCard}
            />
          </div>
        ))
      )}
    </div>
  );
}

export default SavedSearches;
