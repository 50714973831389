import React from "react";
import { useState } from "react";
import arrowImage from "../../../assets/images/sellPage/Arrow.png";
import "typeface-roboto";
import "./ShowMore.css";

export default function ShowMore({ content }) {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore((prevShowMore) => !prevShowMore);
  };

  return (
    <div className="overall">
      <div className="showmore-content">
        {showMore ? content : `${content.slice(0, 100)}... CONTINUED`}
      </div>
      <div>
        <button onClick={toggleShowMore} className="toggle-button">
          <img
            className={`arrow-image ${showMore ? "rotate" : ""}`}
            src={arrowImage}
            alt="arrow"
          />
          <span className="showmore-text">
            {showMore ? "Show Less" : "Show More"}
          </span>
        </button>
      </div>
    </div>
  );
}
