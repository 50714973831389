import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { Box, IconButton } from '@mui/material';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import RectangleCardMobile from '../../Cards/RectangleCard/RectangleCardMobile';

export default function CarouselHomePageMobile({ cardsData }) {
    const cardWidth = 170;
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const [visibleCards, setVisibleCards] = useState(Math.floor(viewportWidth / cardWidth));
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % cardsData.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + cardsData.length) % cardsData.length);
    };

    useEffect(() => {
        const updateViewportWidth = () => {
            setViewportWidth(window.innerWidth);
        };

        window.addEventListener('resize', updateViewportWidth);
        return () => window.removeEventListener('resize', updateViewportWidth);
    }, []);

    useEffect(() => {
        setVisibleCards(Math.floor(viewportWidth * 0.68 / cardWidth));
    }, [viewportWidth]);

    const handlers = useSwipeable({
        onSwipedLeft: () => handleNext(),
        onSwipedRight: () => handlePrev(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const getCardIndicesToShow = () => {
        let indices = [];
        for (let i = 0; i < visibleCards; i++) {
            let index = (currentIndex + i) % cardsData.length;
            indices.push(index);
        }
        return indices;
    };

    const sliderWidth = `${(80 / cardsData.length) * visibleCards}%`;
    const sliderPosition = `${(60 / cardsData.length) * currentIndex}%`;

    return (
        <>
            <p style={{margin: '0', marginBottom: '12px', fontWeight: 'bolder', fontSize: '18px', marginTop: '40px', color: 'gray'}}>Listing for you</p>
            <div {...handlers} style={{ position: 'relative', width: '100vw', maxWidth: `${cardWidth * visibleCards + 20 * (visibleCards - 1)}px` }}>
                <Box sx={{ height: '5px', backgroundColor: '#3c73d6', width: sliderWidth, position: 'absolute', top: 0, left: sliderPosition, transition: 'left 0.5s ease' }} />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', paddingTop: '4px' }}>
                    {getCardIndicesToShow().map((index) => (
                        <div key={index} style={{ margin: '0 10px', width: `${cardWidth}px` }}>
                            <RectangleCardMobile {...cardsData[index]} />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};












