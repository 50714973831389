import React, { useState } from 'react';
import './FilterSearch.css';
import bookmark from '../../../../assets/images/listingPage/bookmark.png';
import heart from '../../../../assets/images/listingPage/heart.png';
import { useSelector } from 'react-redux';
import PricePopup from '../../PopUps/FilterPopUps/PricePopup'; 
import HometypePopup from '../../PopUps/FilterPopUps/HometypePopup';
import BedBathPopup from '../../PopUps/FilterPopUps/BedBathPopup';
import MoreFiltersPopup from '../../PopUps/FilterPopUps/MoreFiltersPopup';  

const FilterSearch = ({ applyFilter }) => {
  const [price, setPrice] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [includeMonthly, setIncludeMonthly] = useState(false);
  const [homeStyle, setHomeStyle] = useState('');
  const [homeType, setHomeType] = useState({
    'Single house': false,
    'Townhouse': false,
    'Co-op': false,
    'Condo': false
  });
  const [beds, setBeds] = useState('');
  const [baths, setBaths] = useState('');
  const [showHomeTypePopup, setShowHomeTypePopup] = useState(false);  
  const [moreFilters, setMoreFilters] = useState({
    'New - old listings': false,
    'Old - new lisings': false,
    'Most - least sq ft': false,
    'Least - most sq ft': false
});
  const [showPricePopup, setShowPricePopup] = useState(false);
  const [showBedBathPopup, setShowBedBathPopup] = useState(false);
  const [showMoreFiltersPopup, setShowMoreFiltersPopup] = useState(false);
  const isWide = useSelector(state => state.windowSize.isWide);

  const handleSubmit = (e) => {
    e.preventDefault();
    const filters = {
      price,
      homeStyle: homeType,
      beds,
      baths,
      moreFilters
    };
    applyFilter(filters);
  };

  const handlePricePopup = () => setShowPricePopup(!showPricePopup);
  const handleHomeTypePopup = () => setShowHomeTypePopup(!showHomeTypePopup);
  const handleBedBathPopup = () => setShowBedBathPopup(!showBedBathPopup);
  const handleMoreFiltersPopup = () => setShowMoreFiltersPopup(!showMoreFiltersPopup);

  return (
    <form onSubmit={handleSubmit} className='filterMenu'>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <button type="button" onClick={handlePricePopup} className='filterProp'>Price <span className="dropdown"></span> 
        </button>
        {showPricePopup && (
                <PricePopup
                    minPrice={minPrice}
                    setMinPrice={setMinPrice}
                    maxPrice={maxPrice}
                    setMaxPrice={setMaxPrice}
                    includeMonthly={includeMonthly}
                    setIncludeMonthly={setIncludeMonthly}
                    closePopup={() => setShowPricePopup(false)}
                />
            )}
          <button type="button" onClick={handleHomeTypePopup} className='filterProp'>
            Home/Type <span className="dropdown"></span>
          </button>
          {showHomeTypePopup && (
            <HometypePopup
              homeType={homeType}
              setHomeType={setHomeType}
              closePopup={() => setShowHomeTypePopup(false)}
            />
          )}
          <button type="button" onClick={handleBedBathPopup} className='filterProp'>Beds/Baths <span className="dropdown"></span></button>
          {showBedBathPopup && (
            <BedBathPopup
              beds={beds}
              setBeds={setBeds}
              baths={baths}
              setBaths={setBaths}
              closePopup={() => setShowBedBathPopup(false)}
            />
          )}
          <button type="button" onClick={handleMoreFiltersPopup} className='filterProp'>More<span className="dropdown"></span></button>
          {showMoreFiltersPopup && (
            <MoreFiltersPopup
              moreFilters={moreFilters}
              setMoreFilters={setMoreFilters}
              closePopup={() => setShowMoreFiltersPopup(false)}
            />
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row-reverse'}}>
          <button className='filterProp' style={{ padding: '7px 12px', flex: '0 1 auto' }}>Compare bookmarked homes <span><img src={bookmark} style={{ width: '12px', transform: 'translateY(2px)' }} /></span></button>
          <button className='filterProp' style={{ padding: '7px 12px', flex: '0 1 auto' }}>See favorited homes <span><img src={heart} style={{ width: '15px', transform: 'translateY(2px)' }} /></span></button>
        </div>
      </div>
    </form>
  );
};

export default FilterSearch;
