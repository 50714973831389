import React from "react";

export default function Button2Click () {
    return (
        <>
            <div className="total-container" style={{ paddingTop: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', fontFamily: "'Roboto', sans-serif"}}>
                <div className="text-bd" style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
                    <h2> EST. PROP. TAX Text </h2>
                </div>
            </div>
            
        </>
    );
}