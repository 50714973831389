import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';

export default function AccountPage({ user }) {
  const navigate = useNavigate();

  if (!user) {
    return <div>Please login to view this page.</div>;
  }

  const handleBackToHome = () => {
    navigate('/'); // 导航回主页
  };

  // 登出逻辑
  const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      // 登出成功后的操作，比如导航回登录页面
      navigate('/');
    }).catch((error) => {
      // 处理可能出现的错误
      console.error("Sign out error", error);
    });
  };


return (
  <div className="account-page">
    <h1>Account Information</h1>
    <img src={user.photoURL} alt="Profile" className="account-avatar" />
    <p>Name: {user.displayName}</p>
    <p>Email: {user.email}</p>

    {/* 如果有更多信息，也可以在这里展示 */}
    <button onClick={handleBackToHome}>Back to Home</button> {/* 添加回到主页的按钮 */}
    <button className="sign-out-button" onClick={handleSignOut}>Sign Out</button> {/* 登出按钮 */}
  </div>
);
}
