import React, { useState, useEffect } from "react";
import { Typography, Grid, Pagination, Box } from "@mui/material";
import SavedHomeCard from "./SavedHomeCard";
import sortIcon from "../../../assets/icons/sort.png";
import axios from "axios";
import Constants from "expo-constants";
import { useSelector } from "react-redux";

const PORT = Constants.expoConfig.extra.PORT;
const URL = Constants.expoConfig.extra.API_BASE_URL;
const baseUrl = `${URL}:${PORT}/api`;

const SavedHome = ({
  user,
  itemsPerPage,
  page,
  onSave,
  onItemsPerPageChange,
  onSortByAddDate,
  onFetchListings,
}) => {
  const [cards, setCards] = useState([]);

  const comparedHomes = useSelector(
    (state) => state.comparedHomes.comparedHomes
  );
  const isMaxSelectionReached = comparedHomes.length >= 5;

  useEffect(() => {
    // Function to post initial home data
    const postInitialHomeData = async () => {
      try {
        await axios.post(
          `${baseUrl}/user/${user.uid}/saved-home-data`,
          initialData
        );
      } catch (error) {
        console.error("Error posting initial home data:", error);
      }
    };

    // Function to fetch saved home data
    const fetchSavedHomeData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/user/${user.uid}/saved-home`
        );
        setCards(response.data);
        onFetchListings(response.data);
      } catch (error) {
        console.error("Error fetching saved home data:", error);
      }
    };

    // Post initial data and then fetch saved home data
    const initializeData = async () => {
      //await postInitialHomeData();
      await fetchSavedHomeData();
    };

    initializeData();
  }, [user.uid, onFetchListings]);

  const currentData = cards.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const saveCardDetails = (id, details) => {
    const updatedCards = cards.map((card) => {
      if (card.id === id) {
        return { ...card, details };
      }
      return card;
    });
    setCards(updatedCards);
    if (onSave) {
      onSave(updatedCards);
    }
  };

  const [addedDate, setAddedDate] = useState("");
  const [showAll, setShowingAll] = useState("");

  const handleSortChange = (event) => {
    const sortOrder = event.target.value;
    setAddedDate(sortOrder);
    onSortByAddDate(sortOrder);
  };

  return (
    <div style={{ padding: "20px 0px", width: "100%" }}>
      <div
        style={{
          height: "60px",
          width: "94%",
          minWidth: "400px",
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "white",
          padding: "20px 20px",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontWeight: "bold", color: "#0461cf" }}
          >
            {cards.length} homes saved
          </Typography>
          <Typography variant="h6" gutterBottom>
            3 for sale, 6 off market
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <select
            className="filterProp"
            style={{ height: "40px" }}
            value={itemsPerPage}
            onChange={onItemsPerPageChange} // Use the passed handler
          >
            <option value={99}>Showing all</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
          </select>
          <select
            className="filterProp"
            style={{ height: "40px" }}
            value={addedDate}
            onChange={handleSortChange}
          >
            <option value="">By added date</option>
            <option value="asc">Oldest First</option>
            <option value="desc">Newest First</option>
          </select>
          <img src={sortIcon} style={{ height: "30px", width: "30px" }} />
        </div>
      </div>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          marginTop: "20px",
          width: "100%",
        }}
      >
        {currentData.map((card) => (
          <Box key={card.id}>
            <SavedHomeCard
              property={card}
              onSave={saveCardDetails}
              isMaxSelectionReached={isMaxSelectionReached}
              isListingSelected={comparedHomes.some(
                (home) => home.id === card.id
              )}
            />
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default SavedHome;
