import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/loginForm.css'; // 假设注册页面的样式和登录页面一致
import { registerUser } from '../../../services/userLogin';

export default function RegistrationPage({ onGoogleLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [registrationError, setRegistrationError] = useState('');
  const [isProfessional, setIsProfessional] = useState(false);
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    // Reset error messages
    setEmailError('');
    setPasswordError('');
    setRegistrationError('');

    let valid = true;
    if (!email) {
      setEmailError('Please enter your email address.');
      valid = false;
    }

    if (!password) {
      setPasswordError('Please enter your password.');
      valid = false;
    }

    if (valid) {
      const newUser = {
        email: email,
        displayName: "new user",
        password: password
      }
      await registerUser(newUser);
    }
  };



  return (
    <div className="login-container">
      <h1 className="login-header">Welcome to Nest</h1>
      <div className="tab-container">
        <div className="tab" onClick={() => navigate('/')}>Sign in</div>
        <div className="tab active">New account</div>
      </div>
      <form onSubmit={handleSignUp}>
        <div className="input-group">
          <label htmlFor="email" className="input-label">Email</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter Email"
            className="input-field"
            autoComplete="username"
          />
          {emailError && <div className="error-message">{emailError}</div>}
        </div>
        <div className="input-group">
          <label htmlFor="password" className="input-label">Password</label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Create password"
            className="input-field"
            autoComplete="new-password"
          />
          <div className="password-requirements">
            <p>At least 8 characters</p>
            <p>Mix of letters and numbers</p>
            <p>At least 1 special character</p>
            <p>At least 1 lowercase letter and 1 uppercase letter</p>
          </div>
          {passwordError && <div className="error-message">{passwordError}</div>}
        </div>
        <div className="input-group checkbox-group">
          <input
            id="isProfessional"
            type="checkbox"
            checked={isProfessional}
            onChange={(e) => setIsProfessional(e.target.checked)}
            className="input-checkbox"
          />
          <label htmlFor="isProfessional" className="checkbox-label">I am a landlord or industry professional</label>
        </div>
        <button type="submit" className="primary-button"> Submit </button>
        <p className="terms-link">
          By submitting, I accept Nest's <Link to="/terms-of-use">terms of use</Link>.
        </p>
        <hr className="separator" />
        <p className="connect-text">Or Connect With</p>
        <button type="button" onClick={onGoogleLogin} className="social-button">Continue with Google</button>
      </form>
    </div>
  );
}
