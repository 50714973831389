import { Typography } from "@mui/material";
import NoBorderBtn from "../../Buttons/NoBorder/NoBorderBtn";

export default function SingleGuide({ title, description, buttonText }) {
  return (
    <div
      style={{
        height: "100%",
        minHeight: "240px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        padding: "15px",
      }}
    >
      <div style={{ marginBottom: "20px" }}>
        <Typography variant="h5" component="h2" style={{ fontWeight: "bold" }}>
          {title}
        </Typography>
        <Typography variant="body1" sx={{ mt: "10px" }}>
          {description}
        </Typography>
      </div>
      <div style={{ marginTop: "auto" }}>
        <NoBorderBtn
          text={buttonText}
          style={{ textDecoration: "underline" }}
        />
      </div>
    </div>
  );
}
