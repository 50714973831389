import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";
import { getAuth } from "firebase/auth";
import "./AdminPage.css";
import Navbar from "../component/Navigation/NavBar";
import NavbarMobile from "../component/Navigation/NavBarMobile";
import Footer from "../component/Footer/Footer";
import FooterMobile from "../component/Footer/FooterMobile";
import SmlSearchBar from "../component/SearchBar/SmallSearchBar/SmallSearchBar";
import backToAdmin from "../../assets/images/Arrow.png";
import ListingTab from "../component/Tabs/ListingTab/ListingTab";
import NoBorderBtn from "../component/Buttons/NoBorder/NoBorderBtn";
import ConfirmPopup from "../component/PopUps/RemoveListing/ConfirmPopup";
import { checkAdmin, deleteListing } from "../../services/apiServices";
import { fetchAllListingsData } from "../../services/fetchData";

export default function AdminPage({ isWide }) {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [listingToRemove, setListingToRemove] = useState(null);
  const [activeTab, setActiveTab] = useState("admin");
  const [currentPage, setCurrentPage] = useState(1);
  const [isAdmin, setIsAdmin] = useState(false);
  const [listingsPerPage, setListingsPerPage] = useState(4);
  const [listings, setListings] = useState([]);
  const [uniqueListings, setUniqueListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [inputValue, setInputValue] = useState("");
  //const [user, setUser] = useState(null);

  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    async function verifyAdmin() {
      //const auth = getAuth();
      //setUser(auth.currentUser);
      //const user = auth.currentUser;
      if (user) {
        console.log("User ID:", user.uid);
        try {
          const isAdmin = await checkAdmin(user.uid);
          setIsAdmin(isAdmin);
          if (isAdmin) {
            fetchListings();
          }
        } catch (error) {
          console.error("Error checking admin status22:", error);
        }
      } else {
        console.log("No user is logged in.");
        setIsAdmin(false);
      }
      setLoading(false);
    }

    verifyAdmin();
  }, []);

  async function fetchListings() {
    try {
      const listingsData = await fetchAllListingsData();
      console.log("Listings data:", listingsData[0]);
      setListings(listingsData);

      const uniqueListingsSet = new Set();
      const uniqueListingsData = listingsData.filter((listing) => {
        if (uniqueListingsSet.has(listing.propertyID)) {
          return false;
        } else {
          uniqueListingsSet.add(listing.propertyID);
          return true;
        }
      });

      setUniqueListings(uniqueListingsData);
    } catch (error) {
      console.error("Error fetching listings:", error);
    }
  }

  useEffect(() => {
    setCurrentPage(1); // Reset to the first page on search or listingsPerPage change
  }, [searchQuery, listingsPerPage]);

  function filterListings() {
    return uniqueListings.filter((listing) => {
      return (
          listing.address &&
          listing.address.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }

  const filteredListings = filterListings();
  const totalPages = Math.ceil(filteredListings.length / listingsPerPage);

  const indexOfLastListing = currentPage * listingsPerPage;
  const indexOfFirstListing = indexOfLastListing - listingsPerPage;
  const currentListings = filteredListings.slice(
    indexOfFirstListing,
    indexOfLastListing
  );

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages || 1); // Avoid setting to 0
    }
  }, [totalPages, currentPage]);

  const paginate = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const noBorderBtnStyle = {
    backgroundColor: "transparent",
    border: "none",
    color: "black",
    textAlign: "right",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "0.8rem",
    fontWeight: "bold",
    fontFamily: "Roboto, sans-serif",
    margin: "4px 2px",
    cursor: "pointer",
    whiteSpace: "nowrap",
    width: "auto",
    textTransform: "capitalize",
  };

  const getImagePath = (id) => {
    try {
      return require(`../../assets/images/adminPage/home_${id}.png`);
    } catch (err) {
      console.error(`Image not found: home_${id}.png`);
      return null;
    }
  };

  const openPopup = (listingId) => {
    setPopupOpen(true);
    setListingToRemove(listingId);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const removeListing = async () => {
    try {
      await deleteListing(user.uid, listingToRemove);
      const updatedListings = uniqueListings.filter(
        (listing) => listing.propertyID !== listingToRemove
      );
      setUniqueListings(updatedListings);
      const totalPages = Math.ceil(updatedListings.length / listingsPerPage);
      setCurrentPage((prev) => Math.min(prev, totalPages));
    } catch (error) {
      console.error("Error removing listing:", error);
    } finally {
      closePopup();
    }
  };

  if (loading) {
    return <div>{"Verifying Credentials..."}</div>;
  }

  if (!isAdmin) {
    return (
      <div>
        Access denied, You do not have the necessary permission to view this
        page
      </div>
    );
  }

  return (
    <>
      <div className="nav-bar">{isWide ? <Navbar /> : <NavbarMobile />}</div>
      <div className="body">
        <div className="header">
          <div className="arrow">
            <a href="/profile" className="back-to-myAccount-link">
              <img src={backToAdmin} alt="Back to Admin" />
              <span className="back">back to My Account</span>
            </a>
          </div>
          <div
            className="admin-header"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
              gap: "5px",
            }}
          >
            <Typography
              variant="h4"
              className="admin-title"
              sx={{
                marginTop: "5vh",
                marginLeft: "4.2vw",
                fontWeight: "bold",
                fontSize: "1.6rem",
                textAlign: "left",
              }}
            >
              Edit your listings
            </Typography>
            <div style={{ display: "flex", width: "75%" }}>
              <ListingTab activeTab={activeTab} setActiveTab={setActiveTab} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "-5vw",
                  textTransform: "none",
                }}
              >
                <a
                  href="/create-listing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <NoBorderBtn style={noBorderBtnStyle} text="Add New" />
                </a>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ width: 62 }}
                >
                  <InputLabel
                    style={{
                      fontSize: "0.9rem",
                      color: "black",
                      textAlign: "left",
                      fontWeight: "bold",
                      fontFamily: "Roboto, sans-serif",
                    }}
                    id="items-per-page-label"
                  >
                    # Items
                  </InputLabel>
                  <Select
                    labelId="items-per-page-label"
                    value={listingsPerPage}
                    onChange={(e) => setListingsPerPage(e.target.value)}
                    label="# Items"
                  >
                    {[...Array(uniqueListings.length).keys()].map((num) => (
                      <MenuItem key={num + 1} value={num + 1}>
                        {num + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <Container className="main-body">
          <TableContainer component={Paper}>
            <Table className="table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div
                      className="searchContainer"
                      style={{ marginTop: "3vh" }}
                    >
                      <SmlSearchBar
                        placeholder="Search"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onSearch={(value) => setSearchQuery(value)}
                      />
                    </div>
                  </TableCell>
                  <TableCell>Compare</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ gap: "30px" }}>
                {currentListings.map((listing) => (
                  <TableRow key={listing.propertyID}>
                    <TableCell>
                      <Grid container spacing={5}>
                        <Grid item>
                          <img
                            src={getImagePath(listing.propertyID)}
                            alt={listing.address}
                            width={80}
                            height={80}
                          />
                        </Grid>
                        <Grid item></Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <div style={{ marginLeft: "10px" }}>
                        <BookmarkButton />
                      </div>
                    </TableCell>
                    <TableCell>{listing.houseType}</TableCell>
                    <TableCell>
                      $
                      {isNaN(listing.price)
                        ? listing.price
                        : Number(listing.price).toLocaleString("en-US")}
                    </TableCell>
                    <TableCell>{listing.isSold ? "Sold" : "On Sale"}</TableCell>
                    <TableCell
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        gap: "5px",
                      }}
                    >
                      <Link to={`/edit-listing/${listing.propertyID}`}>
                        <NoBorderBtn style={noBorderBtnStyle} text="Edit" />
                      </Link>
                      <a
                        href="#"
                        rel="noopener noreferrer"
                        onClick={() => openPopup(listing.propertyID)}
                      >
                        <NoBorderBtn style={noBorderBtnStyle} text="Remove" />
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="pagination">
            <Pagination
              count={totalPages}
              color="primary"
              page={currentPage}
              onChange={paginate}
            />
          </div>
        </Container>
        <div className="admin-footer">
          {isWide ? <Footer /> : <FooterMobile />}
        </div>
        <ConfirmPopup
          isOpen={isPopupOpen}
          onClose={closePopup}
          onConfirm={removeListing}
        >
          Are you sure you want to remove this listing?
        </ConfirmPopup>
      </div>
    </>
  );
}
