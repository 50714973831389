// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainDivNewsPage{
    height: 25vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: min-content;
}

.mainDivNewsPage > span:nth-of-type(1){
    display: flex;
    align-items: center;
}

.mainDivNewsPage > span:nth-of-type(1) img{
    width: 140px;
}

.mainDivNewsPage > span:nth-of-type(1) p{
    display: inline;
    font-size: 2rem;
    font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./App/Pages/component/NewsPage/NewsPageTitle/NewsPageTitle.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".mainDivNewsPage{\r\n    height: 25vh;\r\n    width: 100%;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    height: min-content;\r\n}\r\n\r\n.mainDivNewsPage > span:nth-of-type(1){\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.mainDivNewsPage > span:nth-of-type(1) img{\r\n    width: 140px;\r\n}\r\n\r\n.mainDivNewsPage > span:nth-of-type(1) p{\r\n    display: inline;\r\n    font-size: 2rem;\r\n    font-weight: 600;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
