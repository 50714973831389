import { configureStore } from "@reduxjs/toolkit";
import snackbarReducer from "../slice/snackbarSlice";
import windowSizeReducer, { windowSizeActions } from "../slice/windowSizeSlice";
import comparedHomesReducer from "../slice/comparedHomesSlice";
import userReducer from "../slice/userSlice";

const store = configureStore({
  reducer: {
    snackbar: snackbarReducer,
    windowSize: windowSizeReducer,
    comparedHomes: comparedHomesReducer,
    user: userReducer,
  },
});

store.dispatch(windowSizeActions.startListeningToResize());
export default store;
