// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.burgerMenu{
    background-color: #0461CF;
    height: 100%;
}

.closeIconBar{
    background-color: #FFFFFF;
    height: 50px ;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.iconPosition{
    float: right;
}

.iconColor{
    color: #0461CF
}

.userBar{
    margin-top:30px;
    margin-bottom:10px;
}
.beCenter{
    text-align: center;
}
.loginStyle{
    color: #FFFFFF;
    font-size: 12px;
}

.loginIcon{
    width: 27px;
    margin-left: 10px;
}

.arrowStyle{
    width: 20px;
    height: 20px
}

.buttonTextStyle{
    font-size: 12px
}

.listTextStyle{
    text-align: right;
    color: #ffffff;
    padding-right: 20px!important;
}`, "",{"version":3,"sources":["webpack://./App/Pages/component/BurgerMenu/BurgerMenu.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;AACJ;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,6BAA6B;AACjC","sourcesContent":[".burgerMenu{\r\n    background-color: #0461CF;\r\n    height: 100%;\r\n}\r\n\r\n.closeIconBar{\r\n    background-color: #FFFFFF;\r\n    height: 50px ;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: flex-end;\r\n}\r\n\r\n.iconPosition{\r\n    float: right;\r\n}\r\n\r\n.iconColor{\r\n    color: #0461CF\r\n}\r\n\r\n.userBar{\r\n    margin-top:30px;\r\n    margin-bottom:10px;\r\n}\r\n.beCenter{\r\n    text-align: center;\r\n}\r\n.loginStyle{\r\n    color: #FFFFFF;\r\n    font-size: 12px;\r\n}\r\n\r\n.loginIcon{\r\n    width: 27px;\r\n    margin-left: 10px;\r\n}\r\n\r\n.arrowStyle{\r\n    width: 20px;\r\n    height: 20px\r\n}\r\n\r\n.buttonTextStyle{\r\n    font-size: 12px\r\n}\r\n\r\n.listTextStyle{\r\n    text-align: right;\r\n    color: #ffffff;\r\n    padding-right: 20px!important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
