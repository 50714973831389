import React, { useState } from "react";
import Navbar from "../component/Navigation/NavBar";
import NavbarMobile from "../component/Navigation/NavBarMobile";
import Footer from "../component/Footer/Footer";
import FooterMobile from "../component/Footer/FooterMobile";
import {
  Divider,
  Pagination,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import SavedTab from "../component/SavedPage/SavedTabs";
import SavedSearches from "../component/SavedPage/SavedSearches";
import SavedHome from "../component/SavedPage/SavedHome";
import "./SavedPage.css";
import sampleHouse1 from "../../assets/images/homePage/sampleHouse1.png";
import sampleHouse3 from "../../assets/images/homePage/sampleHouse3.png";
import CompareCard from "../component/CompareCard/CompareCard";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import "./SavedPage.css";

const initialSearchData = [
  { id: 1, title: "Saved Home", details: "Saved search details" },
  { id: 2, title: "Saved Home", details: "Saved search details" },
  { id: 3, title: "Saved Home", details: "Saved search details" },
  { id: 4, title: "Saved Home", details: "Saved search details" },
  { id: 5, title: "Saved Home", details: "Saved search details" },
  { id: 6, title: "Saved Home", details: "Saved search details" },
  { id: 7, title: "Saved Home", details: "Saved search details" },
  { id: 8, title: "Saved Home", details: "Saved search details" },
  { id: 9, title: "Saved Home", details: "Saved search details" },
  { id: 10, title: "Saved Home", details: "Saved search details" },
  { id: 11, title: "Saved Home", details: "Saved search details" },
  { id: 12, title: "Saved Home", details: "Saved search details" },
];

const SavedPage = (user) => {
  const [searchItems, setSearchItems] = useState(initialSearchData);
  const [homeItems, setHomeItems] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(99);
  const [tabSelection, setTabSelection] = useState(0);
  const isWide = useSelector((state) => state.windowSize.isWide);
  const compareProperties = useSelector(
    (state) => state.comparedHomes.comparedHomes
  );
  const dispatch = useDispatch();

  const currentItems = tabSelection === 0 ? homeItems : searchItems;
  const count = Math.ceil(currentItems.length / itemsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFetchListings = (listings) => {
    setHomeItems(listings);
  };

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setPage(1);
  };

  const handleTabChange = (event, newValue) => {
    setTabSelection(newValue);
    setPage(1);
  };

  const handleSortByAddDate = (sortOrder) => {
    console.log("Sorting order: ", sortOrder);
    const sortedItems = [...homeItems].sort((a, b) => {
      const dateA = new Date(a.addDate);
      const dateB = new Date(b.addDate);
      console.log("Comparing: ", dateA, dateB);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
    console.log("Sorted items: ", sortedItems);
    setHomeItems(sortedItems);
  };

  return (
    <>
      {isWide ? <Navbar /> : <NavbarMobile />}
      <div className="fullScreen" style={{ paddingTop: "70px" }}>
        <div
          style={{
            paddingLeft: "9vw",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <p className="SavedSearchPageTitle">Saved</p>
            <p className="SavedSearchPageSubtitle">Description of activity. </p>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              alignItems: "flex-start",
              flex: "1",
            }}
          >
            <SavedTab
              tabValue={tabSelection}
              handleTabChange={handleTabChange}
            />
            <div
              style={{
                maxWidth: "100vw",
                overflowX: "hidden",
                transform: "translate(-9vw, 0px)",
              }}
            >
              <Divider
                style={{
                  width: "99vw",
                  height: "1px",
                  backgroundColor: "gray",
                }}
              />
            </div>
            <div className="SavedSearchesContainer">
              {tabSelection === 0 ? (
                <SavedHome
                  user={user.user}
                  page={page}
                  itemsPerPage={itemsPerPage}
                  onItemsPerPageChange={handleItemsPerPageChange}
                  onSortByAddDate={handleSortByAddDate}
                  onFetchListings={handleFetchListings}
                />
              ) : (
                <SavedSearches
                  user={user.user}
                  initialData={searchItems}
                  page={page}
                  itemsPerPage={itemsPerPage}
                />
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            flex: "1",
            display: "flex",
            justifyContent: "flex-end",
            margin: "30px 0 10px 0px",
            alignSelf: "center",
            alignContent: "center",
            width: "100%",
            minHeight: "100px",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              alignSelf: "center",
              flex: "1",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Pagination
              count={count}
              page={page}
              onChange={handleChangePage}
              color="primary"
            />
          </div>
        </div>
        <CompareCard
          properties={compareProperties}
          onClose={() => dispatch(clearComparedHomes())}
          style={{ width: "100vw" }}
        />
        {isWide ? <Footer /> : <FooterMobile />}
      </div>
    </>
  );
};

export default SavedPage;
