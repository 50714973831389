import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import NoBorderBtn from "../Buttons/NoBorder/NoBorderBtn";
import { useNavigate } from "react-router-dom";

export default function FeaturedArticleCard({
  title,
  author,
  date,
  description,
  link,
}) {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        width: "100%",
        padding: "22px",
        minHeight: "200px",
        paddingBottom: "0px",
        boxSizing: "border-box",
        margin: "10px 0px",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          padding: "0px",
        }}
      >
        <Box>
          <Typography
            variant="h5"
            component="p"
            sx={{ fontWeight: "bold", mb: 1 }}
          >
            {title ? title : "Title of Featured Nest News article"}
          </Typography>
          <Typography variant="body1">
            {description
              ? description
              : "Preview of article text here. Multiple sentences of preview text to simulate the appearance of an article snippet..."}
          </Typography>
        </Box>
        <Box sx={{ mt: 2, paddingBottom: "0" }}>
          <Box sx={{ display: "flex", mb: 1 }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {author ? author : "Author"}
            </Typography>
            <Typography sx={{ fontWeight: "bold", marginLeft: "30px" }}>
              {date ? date : "Date"}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 0 }}>
            <NoBorderBtn
              link={link}
              text={"Read full post>"}
              style={{
                marginLeft: "auto",
                marginRight: "10px",
                textTransform: "none",
                textDecoration: "underline",
              }}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

{
  /* {
    tags ? tags.map((element) => <Typography sx={{ mr: 2 }}>{element}</Typography>) 
        : 
    (<div style={{display: 'flex'}}>
        <Typography sx={{ mr: 2 }}>Tag</Typography>
        <Typography sx={{ mr: 2 }}>Tag</Typography>
        <Typography sx={{ mr: 2 }}>Tag</Typography>
    </div>
    )
} */
}
