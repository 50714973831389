import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import heart from '../../../../assets/images/listingPage/heart.png';
import bookmark from '../../../../assets/images/listingPage/bookmark.png';
import sampleHouse1 from '../../../../assets/images/homePage/sampleHouse1.png';
import { CardMedia, Typography, Grid, Checkbox, Box } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import TurnedInIcon from '@mui/icons-material/TurnedIn';

export default function RectangleCard({ id, houseInfo }) {
  const iconStyle = {
    width: '18px',
    position: 'absolute',
    top: '24px',
    right: '24px',
    filter: 'brightness(0) invert(1)',

  }

  return (
    <Card id={id} sx={{
      width: '250px',
      height: '260px',
      borderRadius: '15px',
      boxSizing: 'border-box',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    }} >
      <CardActions sx={{ height: '100%', padding: 0 }}>
        <CardContent sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: 0,
          position: 'relative',
          width: '100%',
        }}>
          <img src={houseInfo.img ? houseInfo.img : sampleHouse1} alt="houseImage" style={{ height: '65%', width: '100%', position: 'absolute' }} />
          <Checkbox
            icon={<FavoriteIcon />}
            checkedIcon={<FavoriteIcon />}
            sx={{ zIndex: '100', position: 'absolute', top: '10px', left: '10px', color: 'white', '&.Mui-checked': { color: 'red' } }}
          />
          <Checkbox
            icon={<TurnedInIcon />}
            checkedIcon={<TurnedInIcon />}
            sx={{ zIndex: '100', position: 'absolute', top: '10px', right: '10px', color: 'white', '&.Mui-checked': { color: '#3c73d6' } }}
          />
          <div style={{ width: '100%', zIndex: '99', display: 'flex', flexDirection: 'column', padding: '1px', paddingTop: '200px', fontSize: '14px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
              <p style={{ margin: 0, marginRight: '5px' }}><span style={{ fontWeight: 'bold' }}>$</span>{houseInfo.price ? houseInfo.price : 550000}</p>
              <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold', marginRight: '2px' }}>{houseInfo.bedrooms}</span>bd</p>
              <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold', marginRight: '2px' }}>{houseInfo.bathrooms}</span>ba</p>
              <p style={{ margin: 0 }}><span style={{ fontWeight: 'bold', marginRight: '2px' }}>{houseInfo.size}</span>sqf</p>
            </div>
            <p style={{ margin: 0, fontSize: '13px', margin: 'auto', marginTop: '5px' }}>{houseInfo.address}</p>
          </div>
        </CardContent>
      </CardActions>
    </Card>
  );
}