import React from "react";
import { Provider } from "react-redux";
import App from "./App";
import store from "./App/redux/store/store";
// import { SpeedInsights } from "@vercel/speed-insights/react";

export default function RootComponent() {
  return (
    <Provider store={store}>
      {/* <SpeedInsights /> */}
      <App />
    </Provider>
  );
}
