import BulletStyledCard from "../../Cards/BulletStyledCard/BulletStyledCard"
import dollorIcon from '../../../../assets/icons/moneyIcon.png'
import buyIcon from '../../../../assets/icons/buyIcon.png'
import BuyImage from '../../../../assets/images/homePage/BulletStyledCardBuy.png'
import SellImage from '../../../../assets/images/homePage/BulletStyledCardSell.png'
import './BrowseSection.css'
import CarsoualHomePage from "../CarsoualHomePage/CarsoualHomePage"
import sampleHouse1 from '../../../../assets/images/homePage/sampleHouse1.png'
import sampleHouse2 from '../../../../assets/images/homePage/sampleHouse2.png'
import FeaturedArticleCard from "../../FeaturedNestCard/FeaturedNestCard"
import { fetchNewsData } from "../../../../services/fetchData"
import { useEffect, useState } from "react"

export default function BrowseSection() {

    const [news, setNews] = useState([]);
    useEffect(() => {
        const getNews = async () => {
            try {
                const data = await fetchNewsData();
                setNews(data);
            }
            catch (error) {
                console.error('Error fetching news data:', error);
            }
        };

        getNews();
    }, []);


    const cardsData = [
        { id: 1, houseInfo: { price: '1,300,000', bedroomNum: '3', bathroomNum: '2', img: sampleHouse1, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
        { id: 2, houseInfo: { price: '1,300,000', bedroomNum: '3', bathroomNum: '2', img: sampleHouse2, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
        { id: 3, houseInfo: { price: '1,300,000', bedroomNum: '3', bathroomNum: '2', img: sampleHouse2, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
        { id: 4, houseInfo: { price: '1,300,000', bedroomNum: '3', bathroomNum: '2', img: sampleHouse1, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
        { id: 5, houseInfo: { price: '1,300,000', bedroomNum: '3', bathroomNum: '2', img: sampleHouse2, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
        { id: 6, houseInfo: { price: '1,300,000', bedroomNum: '3', bathroomNum: '2', img: sampleHouse2, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
        { id: 7, houseInfo: { price: '1,300,000', bedroomNum: '3', bathroomNum: '2', img: sampleHouse2, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
        { id: 8, houseInfo: { price: '1,300,000', bedroomNum: '3', bathroomNum: '2', img: sampleHouse2, size: '1500', address: '9500 Gilman Dr, La Jolla, CA 92093' } },
    ];

    return (
        <div className="browseSectionMainDiv">
            <div className="BulletCardsDiv">
                <BulletStyledCard image={BuyImage} icon={dollorIcon} text={'BUY'} />
                <BulletStyledCard image={SellImage} icon={buyIcon} text={'SELL'} />
            </div>
            <div className="CarsoualMainDiv">
                <CarsoualHomePage cardsData={cardsData} />
                <div style={{ width: '95%' }}>
                    {news.length >= 1 && (
                        <FeaturedArticleCard title={news[0].title} description={news[0].description} author={news[0].author} date={news[0].date} link={{absolute: true, url: news[0].link}}/>
                    )}
                </div>
            </div>
        </div>
    )
}