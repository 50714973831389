import Map, { GeolocateControl, Marker, NavigationControl } from 'react-map-gl';
import Constants from 'expo-constants';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useState } from 'react';
import './MapboxMap.css';
import { useSelector } from 'react-redux';
import FilledBgBtn from '../../Buttons/FilledBg/FilledBgBtn';

export default function MapboxMap({ searches }) {
  const accessToken = Constants.expoConfig.extra.MAPBOX_ACCESS_TOKEN;
  const isWide = useSelector(state => state.windowSize.isWide);

  const [viewState, setViewState] = useState({
    longitude: -122.335167, // Default coordinates
    latitude: 47.608013,
    zoom: 14,
  });

  const FilledBgButtonStyle = {
    borderRadius: '10px',
    padding: '10px 50px',
    backgroundColor: '#3c73d6',
    top: '85%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30px',
    height: '40px',
    position: 'absolute',
  };

  useEffect(() => {
    if (searches !== null) {
      findLocation(searches);
    }
  }, [searches]);

  const findLocation = async (searches) => {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(searches)}.json?access_token=${accessToken}`;
    fetch(url)
        .then((response) => response.json())
        .then((data) => {
          const [longitude, latitude] = data.features[0].geometry.coordinates;
          setViewState((prevState) => ({
            ...prevState,
            longitude,
            latitude,
          }));
        })
        .catch((error) => {
          console.error('Error fetching location:', error);
        });
  };

  return (
      <div className="mapContainer">
        <Map
            mapboxAccessToken={accessToken}
            {...viewState}
            onMove={(evt) => setViewState(evt.viewState)} // This updates the state when the map moves
            mapStyle="mapbox://styles/mapbox/streets-v9"
        >
          <Marker longitude={viewState.longitude} latitude={viewState.latitude} />
          <NavigationControl position="top-left" />
          <GeolocateControl
              style={{ marginRight: '20px' }}
              position="top-right"
              trackUserLocation={true}
              onGeolocate={(position) => {
                const { longitude, latitude } = position.coords;
                setViewState({
                  longitude,
                  latitude,
                  zoom: 14,qua
                });
              }}
          />
        </Map>
        {!isWide ? (
            <FilledBgBtn style={FilledBgButtonStyle} text="LEGEND" />
        ) : null}
      </div>
  );
}
