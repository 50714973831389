import React, {useState, useRef, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFilter, faMagnifyingGlass, faMicrophone} from '@fortawesome/free-solid-svg-icons';
import './SearchBar.css';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import { AddressAutofill } from '@mapbox/search-js-react';
import Constants from 'expo-constants';
import axios from 'axios';
import {TextField, Autocomplete, Tabs, Tab, Box, Paper} from "@mui/material";
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

export default function SearchBar({onSearchResults}) {
  const { search } = useParams();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const options = [
    { address: 'Fort Lee, New Jersey, NJ', label: '3 NEW', type: 'Mansion'},
    { address: 'Hudson Yards, New York, NY', label: '300+ NEW',  type: 'Condor'},
    { address: 'Somewhere, New Jersey, NJ', label: '35 NEW',  type: 'Mansion'},
  ];
  const searchHistoryLength = 13;
  const savedSearchLength =  13;
  const [searchHistory, setSearchHistory] = useState([{ address: 'Fort Lee, New Jersey, NJ', label: '3 NEW', type: 'Mansion'},
    { address: 'Hudson Yards, New York, NY', label: '300+ NEW', type: 'Mansion'},]);
  const [savedSearches, setSavedSearches] = useState([{ address: 'Somewhere, New Jersey, NJ', label: '35 NEW', type: 'Mansion' },]);
  const [tabIndex, setTabIndex] = useState(0);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const [searchTerm, setSearchTerm] = useState(search || '');
  const [isRecording, setIsRecording] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [recordedUrl, setRecordedUrl] = useState('');
  const [transcription, setTranscription] = useState(''); // 存储转录文本

  const handleTabChange = (event, newValue) => {
    event.stopPropagation();
    setTabIndex(newValue);
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const accessToken = Constants.expoConfig.extra.MAPBOX_ACCESS_TOKEN;

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = async (event) => {
    const updatedSearchTerm = inputRef.current?.value || '';
    setSearchTerm(updatedSearchTerm);
    const searchAddress = transcription ? transcription : searchTerm;
    console.log(searchAddress);
    /*
    try {
      await axios.post('http://ec2-18-218-73-66.us-east-2.compute.amazonaws.com:3001/api/saved-searches/postSearch', { address: searchAddress });

    } catch (error) {
      console.error('Error saving search:', error);
    }
     */
    navigate(`/listings/${searchTerm}`);
    const rawContent = {
      text: searchAddress,
    }
    const jsonContent = JSON.stringify(rawContent);

    try{
      const response = await axios.post('http://ec2-18-218-73-66.us-east-2.compute.amazonaws.com:3001/api/listings/queryListings',
          jsonContent,{
            headers: {
              'Content-Type': 'application/json'
            }
          })
      console.log('Response:', response);
      if (onSearchResults) {
        onSearchResults(response.data); // Send the results back to DesktopListingPage
      }
    }catch(e){
      console.log('Error searching:', e);
    }
  };

  const handleSavedSearch = async () => {
    try{
      const response = await axios.get('http://ec2-18-218-73-66.us-east-2.compute.amazonaws.com:3001/api/saved-searches/getSearches/'+'JiXXUDh2vTUjdNgoq1POHCRHTOk1')
      console.log('Response:', response.data);
      const formattedSearches = response.data.slice(0, savedSearchLength).map((search, index) => ({
        address: search.address,
        label: `${index} NEW`,
        type: 'Mansion',
      }));

      setSavedSearches(formattedSearches);
    } catch (error) {
      console.log('Error fetching saved searches:', error);
    }
  }

  // When component is loading(navigate from homepage or refresh), read the param then handle the searching
  useEffect(() => {
    if (searchTerm) {
      handleSearch();
    }
    handleSavedSearch()
  }, []);

  // Ensure the autocomplete paper component moves with textfield when resizing the window
  useEffect(() => {
    const updatePosition = () => {
      if (inputRef.current) {
        const rect = inputRef.current.getBoundingClientRect();
        setDropdownPosition({
            top: 0,
            left: 0
        });
      }
    };
    // Initial position update
    updatePosition();
    // Update position on window resize
    window.addEventListener('resize', updatePosition);
    // Cleanup
    return () => {
      window.removeEventListener('resize', updatePosition);
    };
  }, [inputRef.current]);


  const mediaStream = useRef(null);
  const mediaRecorder = useRef(null);
  const chunks = useRef([]);

  const startRecording = async (event) => {
    event.preventDefault();
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream);

      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data);
        }
      };

      mediaRecorder.current.onstop = async () => {
        const recordedBlob = new Blob(chunks.current, { type: 'audio/webm' });
        const url = URL.createObjectURL(recordedBlob);
        setRecordedUrl(url);
        chunks.current = [];

        const formData = new FormData();
        formData.append('audio', recordedBlob, 'audio.webm');

        try {
          const response = await axios.post('http://ec2-18-218-73-66.us-east-2.compute.amazonaws.com:3001/api/whisper-service/transcribe', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          setTranscription(response.data.text);
          console.log("transcription: " + response.data.text);
        } catch (error) {
          console.error('Error transcribing audio123:', error);
        }
      };

      mediaRecorder.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = (event) => {
    event.preventDefault();
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.stop();
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => {
        track.stop();
      });
    }
    setIsRecording(false);
  };

  const handleRecording = (event) => {
    if (isRecording) {
      stopRecording(event);
    } else {
      startRecording(event);
    }
  };

  const handleFilterClick = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  return (
      <div className="mainContent">
        <div className="assembler">
          <form className="mainFrame">
            <div className="voiceButtonContainer">
              <button className="voiceButton" onClick={handleRecording}>
                <FontAwesomeIcon
                    icon={faMicrophone}
                    className="searchBarVoiceIcon"
                />
              </button>
            </div>

            <Autocomplete
                freeSolo
                clearOnEscape={false}
                options={tabIndex === 0 ? searchHistory : savedSearches}
                getOptionLabel={(option) => option?.address || ''}
                PaperComponent={(props) => (
                    <Paper sx={{
                      width: '45vw',
                      minWidth: '260px',
                      marginTop: '-5px',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      paddingBottom: '20px',
                      position: 'absolute',
                      top: dropdownPosition.top,
                      left: dropdownPosition.left,
                    }}
                        {...props}
                    >
                      <Tabs
                          value={tabIndex}
                          onChange={handleTabChange}
                          aria-label="Search tabs"
                          indicatorColor="false"
                          variant="fullWidth"
                          textColor="inherit"
                          centered
                          sx={{
                            width: '100%',
                            '& .MuiTabs-indicator': {
                              display: 'none', // Disable the default indicator
                            },
                          }}
                          onMouseDown={(event) => event.preventDefault()}
                      >
                        <Tab label="Search History" sx={{
                          flexGrow: 1,
                          borderBottom: tabIndex === 0 ? '4px solid black' : '2px solid black',
                          transition: 'border-bottom-width 0.3s',
                          fontWeight: tabIndex === 0 ? 'bold' : 'normal',
                        }}/>
                        <Tab label="Saved Searches" sx={{
                          flexGrow: 1,
                          borderBottom: tabIndex === 1 ? '4px solid black' : '2px solid black',
                          transition: 'border-bottom-width 0.3s',
                          fontWeight: tabIndex === 1 ? 'bold' : 'normal',}}/>
                      </Tabs>

                      {/* Render the dropdown options below the tabs */}
                      {props.children}
                    </Paper>
                )}
                renderOption={(props, option, index) => (
                    <li {...props} key={`${option.address}-${index}`} style={{borderBottom: '1px solid black', paddingLeft:'0px',}}>
                      <AccessTimeOutlinedIcon sx={{color: 'gray', fontSize: '13px'}}></AccessTimeOutlinedIcon>
                      <div className="option-text">{option.address}</div>
                      <span className={option.label ? 'option-dot' : 'option'}></span>
                      <div className="option-label">{option.label}</div>
                      <div className="option-type">{option.type}</div>
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        className="searchBarInput"
                        placeholder="Say it! or Enter an address, neighborhood, city or ZIP code"
                        value={searchTerm}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: null, // Removes the clear button (x button)
                        }}
                        inputRef={inputRef} // Attach the ref to the input field
                    />
                )}
                sx={{

                }}
            />

            <div className="filterIconContainer">
              <FontAwesomeIcon
                  icon={faFilter}
                  className="searchBarFilterIcon"
                  onClick={handleFilterClick}
              />
            </div>
            <button className="searchBarbutton" onClick={handleSearch}>
              <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className="searchBarIcon"
              />
            </button>
          </form>
          {isMenuVisible && (
              <div className="searchBarMenu">
                <p>Placeholder for menu content</p>
              </div>
          )}
        </div>
      </div>
  );
}
