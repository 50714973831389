import React from "react";
import "./Buy.css";
import image1 from "../../assets/images/buyPageImages/house1.png";
import image2 from "../../assets/images/buyPageImages/house2.png";
import image3 from "../../assets/images/buyPageImages/house3.png";
import image4 from "../../assets/images/buyPageImages/house4.png";
import Navbar from "../component/Navigation/NavBar";
import Footer from "../component/Footer/Footer";
import Footer2 from "../component/Footer/Footer2";
import FilledBgBtn from "../component/Buttons/FilledBg/FilledBgBtn";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Button1Click from "../component/BuyPageComps/Button1";
import Button2Click from "../component/BuyPageComps/Button2";
import Button3Click from "../component/BuyPageComps/Button3";
import Button4Click from "../component/BuyPageComps/Button4";
import MapboxMap from "../component/ListingPage/MapboxMap/MapboxMap";
import "typeface-roboto";
import S3Info from "../component/BuyPageComps/Section3Info";
import arrowImage from "../../assets/images/sellPage/Arrow.png";
import xImage from "../../assets/images/xImage.png";
import ShowMore from "../component/BuyPageComps/ShowMore";
import AgentPhoto from "../../assets/images/buyPageImages/AgentPhoto.png";
import S5Info from "../component/BuyPageComps/Section5info.jsx";
import ShowMoreRows from "../component/BuyPageComps/ShowMoreRows";
import ShowMoreRows2 from "../component/BuyPageComps/ShowMoreRows2";
import ListingSearchResults from "../component/FinalListingPageMobile/ListingSearchResults";
import { fetchAllListingsData } from "../../services/fetchData";
import PropertyView from "../component/ListingPage/PropertyView/PropertyView";
import SavedPage from "../../Pages/SavedPage/SavedPage";
import BackToTopButton from "../component/TOUcomps/BackToTopButton";
import FooterMobile from "../component/Footer/FooterMobile";
import NavbarMobile from "../component/Navigation/NavBarMobile";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import TimePicker from "../component/BuyPageComps/TimePicker";
import Calendar from "../component/BuyPageComps/Calender";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MRSlider from "../component/BuyPageComps/BuySlider";
import axios from "axios";
import {
  Box,
  Grid,
  Typography,
  Slider,
  Input as MuiInput,
  InputAdornment,
  MenuItem,
  Select,
} from "@mui/material";
import UpdateDialog from "../component/WarningPopUp/Warning";
import Constants from "expo-constants";

const PORT = Constants.expoConfig.extra.PORT;
const URL = Constants.expoConfig.extra.API_BASE_URL;
const baseUrl = `${URL}:${PORT}/api`;

export default function BuyInterface({ user }) {
  const [activeComp, setActiveComp] = useState(<Button1Click />);

  const [value, setValue] = React.useState(dayjs());
  const [time, setTime] = useState(dayjs());

  const isWide = useSelector((state) => state.windowSize.isWide);

  const longText =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

  const [listings, setListings] = useState([]);
  useEffect(() => {
    const getListings = async () => {
      try {
        const data = await fetchAllListingsData();
        setListings(data);
        console.log(data[0]);
      } catch (error) {
        console.error("Error fetching news data:", error);
      }
    };

    getListings();
  }, []);

  const buttons = [
    { buttonText: "Appraised Value", component: <Button1Click /> },
    { buttonText: "Est. Prop. Tax", component: <Button2Click /> },
    { buttonText: "Est. HOA", component: <Button3Click /> },
    { buttonText: "Est. Utilities", component: <Button4Click /> },
  ];

  const FilledBgButtonStyle = {
    borderRadius: "50px",
    height: "45px",
    padding: "10px 50px",
    backgroundColor: "white",
    color: "black",
    border: "black",
  };

  const FilledBgButtonStyle2 = {
    borderRadius: "30px",
    backgroundColor: "#3c73d6",
  };

  const FilledBgButtonStyle3 = isWide
    ? {
        border: "1px solid black",
        padding: "5px 50px 5px 50px",
        borderRadius: "10px",
        backgroundColor: "white",
        color: "black",
      }
    : {
        border: "1px solid black",
        padding: "5px 20px 5px 20px",
        borderRadius: "10px",
        backgroundColor: "white",
        color: "black",
        fontSize: "10px",
      };

  const rows = [
    {
      date: "01/01/2024",
      source: "...",
      event: "Listed for Sale",
      price: "## +- ##%",
    },
    {
      date: "01/01/2024",
      source: "...",
      event: "Listed for Sale",
      price: "## +- ##%",
    },
    {
      date: "01/01/2024",
      source: "...",
      event: "Listed for Sale",
      price: "## +- ##%",
    },
    {
      date: "01/01/2024",
      source: "...",
      event: "Listed for Sale",
      price: "## +- ##%",
    },
  ];

  const rows2 = [
    { year: "2023", propTax: "$#### +- ##%", assess: "$####" },
    { year: "2023", propTax: "$#### +- ##%", assess: "$####" },
    { year: "2023", propTax: "$#### +- ##%", assess: "$####" },
    { year: "2023", propTax: "$#### +- ##%", assess: "$####" },
  ];

  const navigate = useNavigate();

  const [inputValues, setInputValues] = useState({
    down: "0",
    mortgage: "0",
    maintenance: "0",
    propertyTax: "0",
    taxRate: "0",
    mortgageRate: "0",
  });
  const [total, setTotal] = useState(0);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setInputValues({ ...inputValues, [id]: value });
  };

  useEffect(() => {
    const sum = Object.values(inputValues).reduce(
      (acc, value) => acc + (parseFloat(value) || 0),
      0
    );
    setTotal(sum);
  }, [inputValues]);

  const [isTourVisible, setIsTourVisible] = useState(false);

  const [isContactVisible, setIsContactVisible] = useState(false);

  const toggleTourTab = () => {
    setIsTourVisible(!isTourVisible);
    if (!isTourVisible) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  };

  const toggleContactTab = () => {
    setIsContactVisible(!isContactVisible);
    if (!isContactVisible) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  };

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const messageRef = useRef(null);

  const BnameRef = useRef(null);
  const BemailRef = useRef(null);
  const BphoneRef = useRef(null);
  const BmessageRef = useRef(null);

  const handleDateChange = (newValue) => {
    setValue(newValue);
  };

  const handleTimeChange = (newValue) => {
    setTime(newValue);
  };

  const [contactErrorMsg, setContactErrorMsg] = useState("");
  const [isDuplicate, setIsDuplicate] = useState(false);

  const handleConfirmBooking = async () => {
    if (isCheckboxChecked) {
      const bookingData = {
        name: BnameRef.current.value,
        email: BemailRef.current.value,
        phone: BphoneRef.current.value,
        message: BmessageRef.current.value,
        date: value.format("YYYY-MM-DD"),
        time: time,
      };

      try {
        const response = await axios.post(
          `${baseUrl}/booking-service/${user.uid}/postBooking`,
          { booking: bookingData }
        );
        if (response.status === 200) {
          console.log("Booking confirmed");
          setContactErrorMsg("");
          toggleTourTab();
        } else {
          console.error("Failed to confirm booking");
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          setContactErrorMsg(
            "We are currently getting back to you. Hold tight!"
          );
          setIsDialogOpen(true);
          setIsDuplicate(true);
        } else {
          console.error("Error: ", error);
        }
      }
    }
  };

  const handleContact = async () => {
    if (isCheckboxChecked) {
      const contactData = {
        name: nameRef.current.value,
        email: emailRef.current.value,
        phone: phoneRef.current.value,
        message: messageRef.current.value,
      };

      try {
        const response = await axios.post(
          `${baseUrl}/contact-service/${user.uid}/postContact`,
          { contact: contactData }
        );
        if (response.status === 200) {
          console.log("Contact confirmed");
          setContactErrorMsg("");
          toggleContactTab();
        } else {
          console.error("Failed");
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          setContactErrorMsg(
            "We are currently getting back to you. Hold tight!"
          );
        } else {
          console.error("Error: ", error);
        }
      }
    }
  };

  const [loanPeriod, setLoanPeriod] = useState(15);

  const handleLoanPeriodChange = (event) => {
    setLoanPeriod(event.target.value);
  };

  const [mortgageRate, setMortgageRate] = useState(0);

  const handleRateChange = (event, newValue) => {
    setMortgageRate(newValue);
  };

  const [homePrice, setHomePrice] = useState(100000);
  const [downPayment, setDownPayment] = useState(0);
  const [loanAmount, setLoanAmount] = useState(homePrice);

  const HOA = 2000;
  const realEstateTax = 4500;

  const handleCalculatorChange = (event) => {
    const { id, value } = event.target;
    const numericValue = Number(value);

    if (id === "down") {
      setDownPayment(numericValue);
      setLoanAmount(homePrice - numericValue);
    } else if (id === "mortgage") {
      setLoanAmount(numericValue);
      setDownPayment(homePrice - numericValue);
    }
  };

  const [mortgageMonthlyPayment, setMortgageMonthlyPayment] = useState(0);

  const calculateMonthlyPayment = () => {
    const P = loanAmount; // Principal loan amount
    const r = mortgageRate / 100 / 12; // Monthly interest rate
    const n = loanPeriod * 12; // Number of monthly payments

    if (P > 0 && r > 0 && n > 0) {
      const M = (P * (r * Math.pow(1 + r, n))) / (Math.pow(1 + r, n) - 1);
      return M.toFixed(2);
    }
    return 0;
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    setMortgageMonthlyPayment(calculateMonthlyPayment());
  }, [downPayment, loanAmount, mortgageRate, loanPeriod]);

  const monthlyTotalPayment =
    HOA + realEstateTax + parseFloat(mortgageMonthlyPayment);

  const handleConfirm = async () => {
    setIsDialogOpen(false);
    if (isDuplicate) {
      const bookingData = {
        name: BnameRef.current.value,
        email: BemailRef.current.value,
        phone: BphoneRef.current.value,
        message: BmessageRef.current.value,
        date: value.format("YYYY-MM-DD"),
        time: time,
      };

      try {
        const response = await axios.post(
          "http://ec2-18-218-73-66.us-east-2.compute.amazonaws.com:3001/api/booking-service/updateBooking",
          { booking: bookingData }
        );
        if (response.status === 200) {
          console.log("Booking updated successfully");
          setContactErrorMsg("");
        } else {
          console.error("Failed to update booking");
        }
      } catch (error) {
        console.error("Error: ", error);
      }
    }
  };

  const handleCancel = () => {
    console.log("Getting back To you!");
    setIsDialogOpen(false);
  };

  return (
    <>
      {contactErrorMsg && isDialogOpen && (
        <div>
          <UpdateDialog
            message={"Would you like to update your current scheduled time?"}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
          />
        </div>
      )}
      <div
        className="overall-body"
        style={{ fontFamily: "'Roboto', sans-serif" }}
      >
        {isWide ? <Navbar /> : <NavbarMobile />}
        <div className="arrow" onClick={() => navigate("/saved")}>
          <img src={arrowImage} />
          {isWide && <span className="back">Back to saved homes</span>}
        </div>
        <div className={`main-images ${isWide ? "wide" : "narrow"}`}>
          <div className="image-large">
            <img src={image1} alt="Main" />
          </div>
          <div className={`image-column ${isWide ? "wide" : "narrow"}`}>
            <div className="image-med">
              <img src={image2} alt="Interior 1" />
            </div>
            <div className={`image-row ${isWide ? "wide" : "narrow"}`}>
              <div className="image-small">
                <img src={image3} alt="Interior 2" />
              </div>
              <div className="image-small">
                <img src={image4} alt="Interior 3" />
              </div>
            </div>
          </div>
        </div>
        <div className="section2">
          <div
            className="section2-buttons"
            style={{
              display: "flex",
              marginBottom: "20px",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "10px",
              justifyContent: "space-between",
            }}
          >
            {buttons.map((btn, index) => (
              <FilledBgBtn
                key={index}
                text={btn.buttonText}
                style={FilledBgButtonStyle3}
                callBack={() => setActiveComp(btn.component)}
              />
            ))}
          </div>
          <div className="text-btn-body">{activeComp}</div>
        </div>
        {isWide || (
          <div className="borderline1" style={{ paddingTop: "0" }}></div>
        )}
        <div className={`section3 ${isWide ? "wide" : "narrow"}`}>
          <div>
            <h1> Property Information </h1>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <ShowMore content={longText} />
            </div>
          </div>
          <div>
            <S3Info />
          </div>
          <MapboxMap />
        </div>
        <div className="borderline1"></div>
        <div className="section4-container">
          <div className={`section4 ${isWide ? "wide" : "narrow"}`}>
            <div className={`box1 ${isWide ? "wide" : "narrow"}`}>
              <h2> Tour with Your Nest Agent, JOHN DOE </h2>
              <div>
                <p> Next available Tour Date </p>
                <p> Time: MMDD, XXXX, A/PM </p>
              </div>
              <div>
                <FilledBgBtn
                  text={"SEE SCHEDULED TIME"}
                  style={FilledBgButtonStyle2}
                  callBack={toggleTourTab}
                />
                {isTourVisible && (
                  <div className="overlay" onClick={toggleTourTab}></div>
                )}
                <div className={`bottom-tab ${isTourVisible ? "visible" : ""}`}>
                  <h1
                    style={{
                      padding: "0",
                      margin: "0",
                      paddingBottom: "10px",
                      paddingTop: "20px",
                    }}
                  >
                    {" "}
                    Schedule A Showing{" "}
                  </h1>
                  <p
                    style={{ padding: "0", margin: "0", paddingBottom: "20px" }}
                  >
                    {" "}
                    Add your available dates and times to check out this listing
                  </p>
                  <div className="xImage" onClick={toggleTourTab}>
                    <img src={xImage} />
                  </div>
                  <div className="calender-container">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateCalendar value={value} onChange={handleDateChange} />
                    </LocalizationProvider>
                  </div>
                  <TimePicker onTimeChange={handleTimeChange} />
                  <div className="bottom-tab-form">
                    <form className="form-section2">
                      <div className="form-group">
                        <label>Full Name</label>
                        <input type="text" id="full-name" ref={BnameRef} />
                      </div>
                      <div className="form-group">
                        <label>Email</label>
                        <input type="text" id="email" ref={BemailRef} />
                      </div>
                      <div className="form-group">
                        <label>Phone Number</label>
                        <input type="text" id="phone-number" ref={BphoneRef} />
                      </div>
                      <div className="form-group-msg">
                        <label>Message</label>
                        <textarea
                          type="text"
                          id="msg"
                          ref={BmessageRef}
                          style={{
                            fontFamily: "Arial, sans-serif",
                            fontSize: "16px",
                          }}
                        />
                      </div>
                    </form>
                  </div>
                  <FormGroup>
                    <FormControlLabel
                      required
                      control={
                        <Checkbox
                          checked={isCheckboxChecked}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Check to agree with our terms and services"
                    />
                  </FormGroup>
                  <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                    <FilledBgBtn
                      text={"Confirm Booking"}
                      style={{
                        backgroundColor: isCheckboxChecked
                          ? FilledBgButtonStyle2.backgroundColor
                          : "grey",
                        pointerEvents: isCheckboxChecked ? "auto" : "none",
                      }}
                      callBack={handleConfirmBooking}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`divider ${isWide ? "wide" : "narrow"}`}></div>
            <div className="box2">
              <h2> Contact Your Agent </h2>
              <div className="sub-box2">
                <img src={AgentPhoto} alt="Agent's Photo" />
                <div style={{ padding: "0px 0px 0px 20px" }}>
                  <h3> John Doe </h3>
                  <p> Licensed in States(s)</p>
                  <p> License #s </p>
                  <p> # of Properties Represented Up-to-Date </p>
                  <p> # of Days Working with NEST </p>
                  <FilledBgBtn
                    text={"Contact"}
                    style={FilledBgButtonStyle2}
                    callBack={toggleContactTab}
                  />
                </div>
                <div>
                  {isContactVisible && (
                    <div className="overlay" onClick={toggleContactTab}></div>
                  )}
                  <div
                    className={`bottom-tab ${
                      isContactVisible ? "visible" : ""
                    }`}
                  >
                    <h1
                      style={{
                        padding: "0",
                        margin: "0",
                        paddingBottom: "10px",
                        paddingTop: "20px",
                      }}
                    >
                      {" "}
                      Contact Agent{" "}
                    </h1>
                    <p
                      style={{
                        padding: "0",
                        margin: "0",
                        paddingBottom: "20px",
                      }}
                    >
                      Want to learn more, contact the agent to learn more.
                    </p>
                    <div className="xImage" onClick={toggleContactTab}>
                      <img src={xImage} />
                    </div>
                    <div className="bottom-tab-form">
                      <form className="form-section2">
                        <div className="form-group">
                          <label>Full Name</label>
                          <input type="text" id="full-name" ref={nameRef} />
                        </div>
                        <div className="form-group">
                          <label>Email</label>
                          <input type="text" id="email" ref={emailRef} />
                        </div>
                        <div className="form-group">
                          <label>Phone Number</label>
                          <input type="text" id="phone-number" ref={phoneRef} />
                        </div>
                        <div className="form-group-msg">
                          <label>Message</label>
                          <textarea type="text" id="msg" ref={messageRef} />
                        </div>
                      </form>
                    </div>
                    <FormGroup>
                      <FormControlLabel
                        required
                        control={
                          <Checkbox
                            checked={isCheckboxChecked}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label="Check to agree with our terms and services"
                      />
                    </FormGroup>
                    <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                      <FilledBgBtn
                        text={"Contact Agent"}
                        style={{
                          backgroundColor: isCheckboxChecked
                            ? FilledBgButtonStyle2.backgroundColor
                            : "grey",
                          pointerEvents: isCheckboxChecked ? "auto" : "none",
                        }}
                        callBack={handleContact}
                      />
                    </div>
                    {contactErrorMsg && (
                      <div style={{ color: "red", marginTop: "10px" }}>
                        {contactErrorMsg}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="borderline2"></div>
        <div className="section5" style={{ paddingBottom: "30px" }}>
          <h1> Facts & Features </h1>
          <div className="section5-box">
            <p> Interior </p>
          </div>
          <div className="S5-subcontainer">
            <S5Info />
          </div>
          <div className="borderline3"></div>
          <div className="section5-history">
            <div className="dropdown2">
              <ShowMoreRows rows={rows} title={"Price History"} />
            </div>
          </div>
          <div className="section5-publictax">
            <div className="dropdown2">
              <ShowMoreRows2 rows={rows2} title={"Public Tax History"} />
            </div>
          </div>
        </div>
        <div className="borderline-sect6"></div>
        <div className="section6">
          <h1
            style={{
              alignSelf: isWide ? "" : "center",
              paddingLeft: isWide ? "23px" : "0px",
            }}
          >
            {" "}
            Monthly Payment Calculator{" "}
          </h1>
          <div className={`section6-container ${isWide ? "wide" : "narrow"}`}>
            <div
              className="calculator-sim"
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "20px",
                border: "1px solid #ccc",
                borderRadius: "10px",
                backgroundColor: "#f9f9f9",
              }}
            >
              <div className="section6-text">
                <h3
                  style={{ color: "blue", paddingBottom: "10px", margin: "0" }}
                >
                  {" "}
                  Monthly Payment Calculator Simulator{" "}
                </h3>
                <p style={{ paddingBottom: "30px", margin: "0" }}>
                  Numbers can be confusing and add to the stress of house
                  hunting. That’s why we’re here to help. This tool will help
                  you estimate the value of your generic package.
                </p>
              </div>
              <div className="form-section">
                <div className="form-group">
                  <label htmlFor="down-payment">Down payment</label>
                  <input
                    type="text"
                    id="down"
                    placeholder="$"
                    onChange={handleCalculatorChange}
                    value={downPayment}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="mortgage-payment">Loan Amount</label>
                  <input
                    type="text"
                    id="mortgage"
                    placeholder="$"
                    onChange={handleCalculatorChange}
                    value={loanAmount}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="maintenance">Loan Period</label>
                  <Select
                    id="loan-period"
                    value={loanPeriod}
                    onChange={handleLoanPeriodChange}
                    displayEmpty
                  >
                    {[15, 30].map((year) => (
                      <MenuItem key={year} value={year}>
                        {year} years
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="form-group">
                  <label htmlFor="property-tax">Mortgage Monthly Payment</label>
                  <p>${mortgageMonthlyPayment}</p>
                </div>
              </div>
              <div style={{ padding: "20px" }}>
                <MRSlider onRateChange={handleRateChange} />
              </div>
            </div>
            <div className={`reference-picture ${isWide ? "wide" : "narrow"}`}>
              <div
                className="card-container"
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <div className="card-header">
                  <h2>Totals</h2>
                  <p>Monthly estimates</p>
                </div>
                <div className="card-content">
                  <div className="card-item">
                    <h3>Mortgage</h3>
                    <p>${mortgageMonthlyPayment}</p>
                  </div>
                  <div className="card-item">
                    <h3>Real Estate Tax</h3>
                    {/* This is what it is supposed to be: <p>${inputValues.mortgage}</p> */}
                    <p> ${realEstateTax} </p>
                  </div>
                  <div className="card-item">
                    <h3>HOA</h3>
                    <p>${HOA}</p>
                  </div>
                </div>
                <div className="calc-divider"></div>
                <div className="card-footer">
                  <p>Monthly total payment</p>
                  <h3 style={{ paddingTop: "5px", margin: "0" }}>
                    ${monthlyTotalPayment}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="borderline-sect6"></div>
        <div className={`section7 ${isWide ? "wide" : "narrow"}`}>
          <h1> For You </h1>
          <h2 style={{ color: "grey", margin: "0", paddingBottom: "10px" }}>
            {" "}
            Nearby Homes{" "}
          </h2>
          <div
            style={{
              marginBottom: "20px",
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div className="blue-borderline"></div>
            <div className="borderline4"></div>
          </div>
          <div>
            <PropertyView houseInfo={listings} />
          </div>
          <h2
            style={{
              color: "grey",
              margin: "0",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            {" "}
            Similar Homes{" "}
          </h2>
          <div
            style={{
              marginBottom: "20px",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div className="blue-borderline"></div>
            <div className="borderline4"></div>
          </div>
          <div>
            <PropertyView houseInfo={listings} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: isWide ? "flex-start" : "center",
            paddingTop: "30px",
            paddingBottom: isWide ? "" : "30px",
          }}
        >
          <BackToTopButton />
        </div>
        {isWide ? <Footer2 /> : <FooterMobile className="footer-mobile" />}
      </div>
    </>
  );
}
