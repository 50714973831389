import { useSelector } from 'react-redux';
import Navbar from "../component/Navigation/NavBar";
import NavbarMobile from "../component/Navigation/NavBar";
import Footer from "../component/Footer/Footer";
import FooterMobile from "../component/Footer/Footer";
import React, { useState } from 'react';
import CompareTabs from '../component/Tabs/CompareTabs';
import housesData from './houseData';
import "./ComparisonPage.css";
import ConnectLineComponent from '../component/Lines/ConnectLineComponent';

const addIcon = require("../../assets/icons/addCompareIcon.png");

export default function ComparisonPage() {


    const isWide = useSelector(state => state.windowSize.isWide);
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const renderLeftColumn = () => {
        switch (activeTab) {
            case 0:
                return (
                    <>
                        <div className="row">Status</div>
                        <div className="row">List Price</div>
                        <div className="row">Price/Sq Ft</div>
                        <div className="row">Bedrooms</div>
                        <div className="row">Bathrooms</div>
                        <div className="row">Square Ft</div>
                    </>
                );
            case 1:
                return (
                    <>
                        <div className="row">Home Type</div>
                        <div className="row">Condition</div>
                        <div className="row">Architectural Style</div>
                        <div className="row">New Construction</div>
                        <div className="row">Heating</div>
                        <div className="row">Cooling</div>
                    </>
                );
            case 2:
                return (
                    <>
                        <div className="row">Lot Size</div>
                        <div className="row">Lot Features</div>
                        <div className="row">Parking Spaces</div>
                        <div className="row">Parking Features</div>
                        <div className="row">Patio/Porch</div>
                        <div className="row">Fencing</div>
                    </>
                );
            case 3:
                return (
                    <>
                        <div className="row">Annual Tax</div>
                        <div className="row">HOA</div>
                        <div className="row">Tax Assessed Value</div>
                        <div className="row">Buyer's Agent Fee</div>
                        <div className="row">Offer Review Date</div>
                        <div className="row">Listing Terms</div>
                    </>
                );
            case 4:
                return (
                    <>
                        <div className="row">City/Town</div>
                        <div className="row">Walk Score</div>
                        <div className="row">Transit Score</div>
                        <div className="row">Schools Rating</div>
                        <div className="row">Commute Time</div>
                    </>
                );
            default:
                return <div>Select a tab to view content</div>;
        }
    };

    const renderContent = (house) => {
        switch (activeTab) {
            case 0:
                return (
                    <>
                        <div className="row">{house.overviewContent.status}</div>
                        <div className="row">{house.overviewContent.listPrice}</div>
                        <div className="row">{house.overviewContent.pricePerSqFt}</div>
                        <div className="row">{house.overviewContent.bedrooms}</div>
                        <div className="row">{house.overviewContent.bathrooms}</div>
                        <div className="row">{house.overviewContent.squareFt}</div>
                    </>
                );
            case 1:
                return (
                    <>
                        <div className="row">{house.interiorContent.homeType}</div>
                        <div className="row">{house.interiorContent.condition}</div>
                        <div className="row">{house.interiorContent.architecturalStyle}</div>
                        <div className="row">{house.interiorContent.newConstruction}</div>
                        <div className="row">{house.interiorContent.heating}</div>
                        <div className="row">{house.interiorContent.cooling}</div>
                    </>
                );
            case 2:
                return (
                    <>
                        <div className="row">{house.propertyContent.lotSize}</div>
                        <div className="row">{house.propertyContent.lotFeatures}</div>
                        <div className="row">{house.propertyContent.parkingSpaces}</div>
                        <div className="row">{house.propertyContent.parkingFeatures}</div>
                        <div className="row">{house.propertyContent.patioPorch}</div>
                        <div className="row">{house.propertyContent.fencing}</div>
                    </>
                );
            case 3:
                return (
                    <>
                        <div className="row">{house.hoaFinancialContent.annualTax}</div>
                        <div className="row">{house.hoaFinancialContent.hoa}</div>
                        <div className="row">{house.hoaFinancialContent.taxAssessedValue}</div>
                        <div className="row">{house.hoaFinancialContent.buyersAgentFee}</div>
                        <div className="row">{house.hoaFinancialContent.offerReviewDate}</div>
                        <div className="row">{house.hoaFinancialContent.listingTerms}</div>
                    </>
                );
            case 4:
                return (
                    <>
                        <div className="row">{house.communityContent.cityTown}</div>
                        <div className="row">{house.communityContent.walkScore}</div>
                        <div className="row">{house.communityContent.transitScore}</div>
                        <div className="row">{house.communityContent.schoolsRating}</div>
                        <div className="row">{house.communityContent.commuteTime}</div>
                    </>
                );
            default:
                return <div>Select a tab to view content</div>;
        }
    };

    return (
        <>
            {isWide ? <Navbar /> : <NavbarMobile />}
            <div className="fullScreen ComparisonPageMainDiv">
                <div className="Compare-container">
                        <h1>Compare homes</h1>
                        <CompareTabs value={activeTab} handleChange={handleTabChange} />
                        <div className="tab-content-container">
                        <div className="left-column">
                            <img src={addIcon} className="add-icon-container" style={{height:'50px', width:'auto'}}></img>
                            {renderLeftColumn()}
                            </div>
                            {Object.keys(housesData).map((key, index) => (
                                <React.Fragment key={index}>
                                    <div className="house-column">
                                        <img src={housesData[key].image} alt={`House ${index + 1}`} style = {{paddingBottom: '20px'}} className="house-image" />
                                            {renderContent(housesData[key])}
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                </div>
                {isWide ? <Footer /> : <FooterMobile />}
            </div>
        </>
    );
}
