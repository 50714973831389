import {Divider, Drawer, Grid, List, ListItem, ListItemText} from "@mui/material";
import React, {createContext, useContext, useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Button from "@mui/material/Button";
import {useLocation, useNavigate} from 'react-router-dom';
import './BurgerMenu.css'
import {getAuth, onAuthStateChanged, signOut } from 'firebase/auth';


const loginIcon = require('../../../assets/images/loginIconMobile.png');

const BurgerContext = createContext();

export const useBurger = () => useContext(BurgerContext);

const BurgerProvider = ({ children }) => {
    const [open, setOpen] = useState(false);

    const toggleBurger = (state) => () => {
        setOpen(state);
    };

    return (
        <BurgerContext.Provider value={{ open, toggleBurger }}>
            {children}
        </BurgerContext.Provider>
    );
};

const BurgerMenu = ({user, onLogout}) => {
    const {open, toggleBurger } = useBurger();
    const [currentUser, setCurrentUser] = useState(user);

    const navigate = useNavigate();
    const location = useLocation();
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
        });
        return () => unsubscribe();
    }, [auth]);
    const handleLogout = async () => {
        try {
            //console.log(currentUser);
            await signOut(auth);
            console.log('Logged out successfully');
            if (['/profile', '/admin', '/notification'].includes(location.pathname)) {
                navigate('/login');
            }
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const buttonStyle = {
        customButton: {
        color: "#FFFFFF",
        borderColor: "#FFFFFF",
        height: "31px",
        width: "139px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "5px 5px"
    }
    };

    const list = () => (
        <div onClick={toggleBurger(false)} onKeyDown={toggleBurger(false)} className="burgerMenu">
            <div className="closeIconBar">
                <IconButton onClick={toggleBurger(false)} className="iconPosition">
                    <CloseIcon className="iconColor"/>
                </IconButton>
            </div>

            <Grid container className="userBar" justifyContent="center" alignItems="center"
                  onClick={(event) => event.stopPropagation()} >
                <Grid item xs={4}></Grid>
                <Grid item xs={4} className="beCenter">
                    {
                        currentUser ? <span className="loginStyle" onClick={handleLogout}>LOG OUT</span>
                            : <span className="loginStyle" onClick={() => navigate('/login')}>LOG IN</span>
                    }
                </Grid>
                <Grid item xs={4}>
                    {
                        currentUser && (<img src={loginIcon} alt='Login' onClick={() => navigate('/profile')}
                                   className='loginIcon'/>)
                    }

                </Grid>
            </Grid>
            <div>
                <List>
                    {[
                        { text: 'BUY', path: '/buy' },
                        { text: 'SELL', path: '/sell' },
                        { text: 'WHO WE ARE', path: '/who' },
                        { text: 'SAVED HOMES', path: '/saved' },
                    ].map((item, index) => (
                        <ListItem button key={item.text}>
                            <Button variant="outlined" style={buttonStyle.customButton}
                                    onClick={() => navigate(item.path)}>
                                <ArrowBackIosNewIcon className="arrowStyle"/>
                                <span className="buttonTextStyle">{item.text}</span>
                            </Button>
                        </ListItem>
                    ))}
                </List>
            </div>
            {currentUser && <Divider variant="middle" style={{borderColor: "#ffffff"}}/>}
            {
                currentUser && (
                    <List className="listTextStyle">
                        <ListItemText onClick={() => navigate('/profile')}>Profile</ListItemText>
                        <ListItemText onClick={() => navigate('/admin')}>Activity</ListItemText>
                        <ListItemText onClick={() => navigate('/notification')}>Notifications</ListItemText>
                    </List>
                )
            }


        </div>
    );
    return(
        <div>
            <Drawer anchor="right" open={open} onClose={toggleBurger(false)}>
                {list()}
            </Drawer>
        </div>
    );
};

export { BurgerMenu, BurgerProvider };