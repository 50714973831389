import { Box, IconButton, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import arrowImage from "../../../assets/images/Arrow.png";

export default function TimePicker({ onTimeChange }) {
  const [hours, setHours] = useState(12);
  const [minutes, setMinutes] = useState(0);
  const [period, setPeriod] = useState("AM");

  const incrementHours = () => {
    setHours((prevHours) => (prevHours === 12 ? 1 : prevHours + 1));
  };

  const decrementHours = () => {
    setHours((prevHours) => (prevHours === 1 ? 12 : prevHours - 1));
  };

  const incrementMinutes = () => {
    setMinutes((prevMinutes) => (prevMinutes === 59 ? 0 : prevMinutes + 1));
  };

  const decrementMinutes = () => {
    setMinutes((prevMinutes) => (prevMinutes === 0 ? 59 : prevMinutes - 1));
  };

  const togglePeriod = () => {
    setPeriod((prevPeriod) => (prevPeriod === "AM" ? "PM" : "AM"));
  };

  useEffect(() => {
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;
    onTimeChange(formattedTime);
  }, [hours, minutes, period, onTimeChange]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius={4}
      padding={2}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginRight={2}
      >
        <IconButton onClick={incrementHours}>
          <img src={arrowImage} style={{ transform: "rotate(90deg)" }} />
        </IconButton>
        <Typography variant="h4">
          {hours.toString().padStart(2, "0")}
        </Typography>
        <IconButton onClick={decrementHours}>
          <img src={arrowImage} style={{ transform: "rotate(-90deg)" }} />
        </IconButton>
      </Box>
      <Typography variant="h4" marginRight={2}>
        :
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginRight={2}
      >
        <IconButton onClick={incrementMinutes}>
          <img src={arrowImage} style={{ transform: "rotate(90deg)" }} />
        </IconButton>
        <Typography variant="h4">
          {minutes.toString().padStart(2, "0")}
        </Typography>
        <IconButton onClick={decrementMinutes}>
          <img src={arrowImage} style={{ transform: "rotate(-90deg)" }} />
        </IconButton>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <IconButton onClick={togglePeriod}>
          <img src={arrowImage} style={{ transform: "rotate(90deg)" }} />
        </IconButton>
        <Typography variant="h4">{period}</Typography>
        <IconButton onClick={togglePeriod}>
          <img src={arrowImage} style={{ transform: "rotate(-90deg)" }} />
        </IconButton>
      </Box>
    </Box>
  );
}
