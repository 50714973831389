import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Button, Flex } from "antd";
import {
  Typography,
  Container,
  Grid,
  TextField,
  Box,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import Navbar from "../component/Navigation/NavBar";
import NavbarMobile from "../component/Navigation/NavBarMobile";
import Footer from "../component/Footer/Footer";
import FooterMobile from "../component/Footer/FooterMobile";
import fileUploadImage from "../../assets/images/file-upload.png";
import backToAdmin from "../../assets/images/Arrow.png";
import ListingTab from "../component/Tabs/ListingTab/ListingTab";
import "./MobileCreateListing.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import TimeSlot from "../component/Calendar/TimeSlotTable/TimeSlot";
import FilledBgBtn from "../component/Buttons/FilledBg/FilledBgBtn";
import { addListing } from "../../services/apiServices";

export default function MobileEditListingPage({ isWide, user }) {
  const [activeTab, setActiveTab] = useState("profile");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [modalImage, setModalImage] = useState(null);
  const [submitAttempted, setSubmitAttempted] = useState(false);

  // State variables for input fields
  const [formState, setFormState] = useState({
    Address: "",
    Price: "",
    AppraisedValue: "",
    PropertyTax: "",
    HOA: "",
    Utilities: "",
    Size: "",
    Beds: "",
    Baths: "",
    YearBuilt: "",
    BasementFinish: "",
    Parking: "",
    Deck: "",
    Patio: "",
    FloorFinish: "",
    Fireplace: "",
  });

  const [errors, setErrors] = useState({
    Address: "",
    Price: "",
    AppraisedValue: "",
    PropertyTax: "",
    HOA: "",
    Utilities: "",
    Size: "",
    Beds: "",
    Baths: "",
    YearBuilt: "",
    BasementFinish: "",
    Parking: "",
    Deck: "",
    Patio: "",
    FloorFinish: "",
    Fireplace: "",
  });

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    const maxImages = 20;
    if (uploadedImages.length + files.length > maxImages) {
      alert(`You can only upload up to ${maxImages} images.`);
      return;
    }

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImages((prevImages) => [...prevImages, reader.result]);
      };
      reader.readAsDataURL(file);
    });

    event.target.value = null;
  };

  const removeImage = (index) => {
    setUploadedImages((prevImages) =>
      prevImages.filter((image, i) => i !== index)
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Update the form state with the new value
    setFormState((prevState) => ({ ...prevState, [name]: value }));

    // Immediately validate the field and update the error state
    // const errorMessage = validateField(name, value);
    // setErrors((prevErrors) => ({ ...prevErrors, [name]: errorMessage }));

    if (submitAttempted) {
      const errorMessage = validateField(name, value);
      setErrors((prevErrors) => ({ ...prevErrors, [name]: errorMessage }));
    }
  };

  const validateField = (name, value) => {
    let errMsg = "";

    switch (name) {
      case "Address":
        if (!value) {
          errMsg = "Address is required";
        }
        if (!validateAddress(value)) {
          errMsg =
            "Correct Format: Street Number and Street Name, City, State Zipcode";
        }
        break;
      case "Price":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      case "AppraisedValue":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      case "PropertyTax":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      case "HOA":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      case "Utilities":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      case "Size":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      case "Beds":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      case "Baths":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      case "YearBuilt":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      case "BasementFinish":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      case "Parking":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      // case "Deck":
      //   if (!value.match(/^\d*(\.\d{0,2})?$/)) {
      //     errMsg = "Please enter a valid number";
      //   }
      //   break;
      // case "Patio":
      //   if (!value.match(/^\d*(\.\d{0,2})?$/)) {
      //     errMsg = "Please enter a valid number";
      //   }
      //   break;
      case "FloorFinish":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
      case "Fireplace":
        if (!value.match(/^\d*(\.\d{0,2})?$/)) {
          errMsg = "Please enter a valid number";
        }
        break;
    }
    return errMsg;
  };

  const validateAddress = (input) => {
    const pattern = /^\d+\s[a-z\s]+\,\s[a-z\s]+\,\s[a-z]{2}\s\d{5}$/i;
    return pattern.test(input);
  };

  const handleSubmit = async () => {
    event.preventDefault();
    setSubmitAttempted(true);

    // Validate all fields again on submission
    const newErrors = Object.keys(formState).reduce((acc, key) => {
      acc[key] = validateField(key, formState[key]);
      return acc;
    }, {});

    setErrors(newErrors);

    // Check for any errors in the form after revalidation
    const hasErrors = Object.values(newErrors).some((error) => error !== "");

    if (!hasErrors) {
      try {
        const userId = user.uid;
        const response = await addListing(userId, formState);
        console.log("Form Data Submitted:", formState);
      } catch (error) {
        console.log("Error Create Listing: ", error);
      }
    } else {
      alert("Please correct the errors before submitting.");
    }
  };

  return (
    <>
      <div className="mobile-create-listing">
        {isWide ? <Navbar /> : <NavbarMobile />}
        <Container>
          <div className="cl-mobile-arrow">
            <a href="/admin" className="back-to-admin-link">
              <img src={backToAdmin} alt="Back to Admin" />
            </a>
          </div>
          <div className="cl-mobile-spacebetween"></div>
          <div
            className="cl-mobile-create-listing-header"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
              gap: "5px",
            }}
          >
            <Typography
              variant="h4"
              className="listing-title"
              sx={{
                fontWeight: "bold",
                fontSize: "1.6rem",
                textAlign: "left",
              }}
            >
              Create Listing
            </Typography>

            <Typography
              variant="h1"
              className="listing-title"
              sx={{
                fontSize: "1rem",
                textAlign: "left",
              }}
            >
              Create your list Easily!
            </Typography>
            <ListingTab activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>
          <div className="cl-mobile-create-list-form">
            <div className="cl-mobile-bg-canvas"></div>
            <div className="flex1">
              <label htmlFor="Address" className="label cl-mobile-flex1-label">
                Address
              </label>
              <TextField
                id="Address"
                name="Address"
                variant="outlined"
                fullWidth
                required
                value={formState.Address}
                onChange={handleInputChange}
                error={Boolean(errors.Address)}
                helperText={
                  errors.Address ||
                  "Format: Street Number Street Name, City, State ZIP"
                }
              />

              <label htmlFor="Price" className="label cl-mobile-flex1-label">
                Price
              </label>
              <TextField
                id="Price"
                name="Price"
                variant="outlined"
                fullWidth
                required
                value={formState.Price}
                onChange={handleInputChange}
                error={Boolean(errors.Price)}
                helperText={errors.Price || ""}
              />

              <label
                htmlFor="AppraisedValue"
                className="label cl-mobile-flex1-label"
              >
                Appraised Value
              </label>
              <TextField
                id="AppraisedValue"
                name="AppraisedValue"
                variant="outlined"
                fullWidth
                required
                value={formState.AppraisedValue}
                onChange={handleInputChange}
                error={Boolean(errors.AppraisedValue)}
                helperText={errors.AppraisedValue || ""}
              />

              <label
                htmlFor="PropertyTax"
                className="label cl-mobile-flex1-label"
              >
                Property Tax
              </label>
              <TextField
                id="PropertyTax"
                name="PropertyTax"
                variant="outlined"
                fullWidth
                required
                value={formState.PropertyTax}
                onChange={handleInputChange}
                error={Boolean(errors.PropertyTax)}
                helpertext={errors.PropertyTax || ""}
              />

              <label htmlFor="HOA" className="label cl-mobile-flex1-label">
                HOA
              </label>
              <TextField
                id="HOA"
                name="HOA"
                variant="outlined"
                fullWidth
                required
                value={formState.HOA}
                onChange={handleInputChange}
                error={Boolean(errors.HOA)}
                helpertext={errors.HOA || ""}
              />

              <label htmlFor="Utility" className="label cl-mobile-flex1-label">
                Utility
              </label>
              <TextField
                id="Utility"
                name="Utility"
                variant="outlined"
                fullWidth
                required
                value={formState.Utility}
                onChange={handleInputChange}
                error={Boolean(errors.Utility)}
                helpertext={errors.Utility || ""}
              />

              <label htmlFor="Size" className="label cl-mobile-flex1-label">
                Size (SQFT)
              </label>
              <TextField
                id="Size"
                name="Size"
                variant="outlined"
                fullWidth
                required
                value={formState.Size}
                onChange={handleInputChange}
                error={Boolean(errors.Size)}
                helpertext={errors.Size || ""}
              />
            </div>

            <div className="cl-mobile-flex2">
              <div className="beds-container">
                <label htmlFor="Beds" className="label flex2-label">
                  # Beds
                </label>
                <TextField
                  id="Beds"
                  name="Beds"
                  variant="outlined"
                  fullWidth
                  required
                  value={formState.Beds}
                  onChange={handleInputChange}
                  error={Boolean(errors.Beds)}
                  helpertext={errors.Beds || ""}
                />
              </div>

              <div className="baths-container">
                <label htmlFor="Bathrooms" className="label flex2-label">
                  # Baths
                </label>
                <TextField
                  id="Baths"
                  name="Baths"
                  variant="outlined"
                  fullWidth
                  required
                  value={formState.Baths}
                  onChange={handleInputChange}
                  error={Boolean(errors.Baths)}
                  helpertext={errors.Baths || ""}
                />
              </div>
            </div>

            <div className="cl-mobile-flex1">
              <label htmlFor="YearBuilt" className="label flex1-label">
                Year Built
              </label>
              <TextField
                id="YearBuilt"
                name="YearBuilt"
                variant="outlined"
                fullWidth
                required
                value={formState.YearBuilt}
                onChange={handleInputChange}
                error={Boolean(errors.YearBuilt)}
                helpertext={errors.YearBuilt || ""}
              />

              <label htmlFor="Basement" className="label flex1-label">
                Basement Finish
              </label>
              <TextField
                id="BasementFinish"
                name="BasementFinish"
                variant="outlined"
                fullWidth
                required
                value={formState.BasementFinish}
                onChange={handleInputChange}
                error={Boolean(errors.BasementFinish)}
                helpertext={errors.BasementFinish || ""}
              />

              <label htmlFor="ParkingSpaces" className="label flex1-label">
                Parking
              </label>
              <TextField
                id="Parking"
                name="Parking"
                variant="outlined"
                fullWidth
                required
                value={formState.Parking}
                onChange={handleInputChange}
                error={Boolean(errors.Parking)}
                helpertext={errors.Parking || ""}
              />

              <label htmlFor="Deck" className="label flex1-label">
                Deck
              </label>
              <Select
                id="Deck"
                name="Deck"
                variant="outlined"
                fullWidth
                required
                value={formState.Deck}
                onChange={handleInputChange}
                error={Boolean(errors.Deck)}
                helpertext={errors.Deck || ""}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="have">Have</MenuItem>
                <MenuItem value="don't have">Don't have</MenuItem>
              </Select>

              <label htmlFor="Patio" className="label flex1-label">
                Patio
              </label>
              <Select
                id="Patio"
                name="Patio"
                variant="outlined"
                fullWidth
                required
                value={formState.Patio}
                onChange={handleInputChange}
                error={Boolean(errors.Patio)}
                helpertext={errors.Patio || ""}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="have">Have</MenuItem>
                <MenuItem value="don't have">Don't have</MenuItem>
              </Select>

              <label htmlFor="FloorFinished" className="label flex1-label">
                Floor Finish
              </label>
              <TextField
                id="FloorFinish"
                name="FloorFinish"
                variant="outlined"
                fullWidth
                required
                value={formState.FloorFinish}
                onChange={handleInputChange}
                error={Boolean(errors.FloorFinish)}
                helpertext={errors.FloorFinish || ""}
              />

              <label htmlFor="FireplaceType" className="label flex1-label">
                Fireplace
              </label>
              <TextField
                id="Fireplace"
                name="Fireplace"
                variant="outlined"
                fullWidth
                required
                value={formState.Fireplace}
                onChange={handleInputChange}
                error={Boolean(errors.Fireplace)}
                helpertext={errors.Fireplace || ""}
              />
            </div>
          </div>
          <form className="edit-list-form">
            <div className="cl-mobile-bg-canvas"></div>
            <div className="cl-mobile-file-upload">
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", mb: 1, fontFamily: "Roboto" }}
                className="label"
              >
                Media
              </Typography>

              <label htmlFor="files" className="file-upload-wrapper">
                <div className="inputField cl-mobile-file-uploaded">
                  <input
                    id="files"
                    type="file"
                    accept=".jpg, .png"
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                    multiple
                  />
                  <img
                    htmlFor="file-upload"
                    className="file-image"
                    src={fileUploadImage}
                  />
                  <label
                    htmlFor="file-upload"
                    style={{
                      fontSize: "20px",
                      fontWeight: "bolder",
                      cursor: "pointer",
                    }}
                    className="label file"
                  >
                    Drag images here or click to upload
                  </label>
                  <label
                    htmlFor="file-upload"
                    style={{
                      fontSize: "18px",
                      fontWeight: "lighter",
                      cursor: "pointer",
                    }}
                    className="label file"
                  >
                    Formats: jpg, png
                  </label>
                </div>
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "left",
                  overflowX: "hidden",
                  width: "90%",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "70vw",
                    height: "auto",
                  }}
                >
                  <Carousel
                    showThumbs={false}
                    centerMode
                    centerSlidePercentage={33}
                    gap={6}
                    width={"90%"}
                  >
                    {uploadedImages.map((image, index) => (
                      <div
                        key={index}
                        className="uploaded-image-wrapper"
                        style={{
                          marginRight: "2px",
                          marginLeft: "2px",
                          marginBottom: "200px",
                          paddingTop: "-200px",
                          width: "100%",
                        }}
                      >
                        <img
                          src={image}
                          alt="Uploaded"
                          className="uploaded-image"
                          onDoubleClick={() => setModalImage(image)}
                          style={{
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />

                        <button
                          onClick={() => removeImage(index)}
                          className="cl-mobile-remove-image-button"
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </Carousel>

                  <Modal
                    isOpen={modalImage !== null}
                    onRequestClose={() => setModalImage(null)}
                    contentLabel="Enlarged Image"
                  >
                    <img src={modalImage} alt="Enlarged" />
                  </Modal>
                </div>

                {uploadedImages.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <label
                      htmlFor="more-images"
                      className="cl-mobile-add-more-images"
                    >
                      Add more Images
                      <input
                        id="more-images"
                        type="file"
                        accept=".jpg, .png"
                        style={{ display: "none", alignItems: "center" }}
                        onChange={handleFileUpload}
                        multiple
                      />
                    </label>
                  </div>
                )}
              </div>
            </div>
          </form>
          <div className="cl-mobile-time-slot-size">
            {/* <div className="cl-mobile-time-slot">
              <Typography
                variant="h4"
                className="time-slot-title"
                sx={{
                  marginTop: "5vh",
                  fontWeight: "bold",
                  marginBottom: "60px",
                  color: "#3c73d6",
                  fontSize: "25px",
                  textAlign: "left",
                }}
              >
                Available Date and Time
              </Typography>
            </div> */}
            {/* <div>
              <TimeSlot />
            </div> */}
            <div className="cl-mobile-btns">
                <Button
                  type="primary"
                  onClick={handleSubmit}
                  className="cl-mobile-buttons"
                  style={{
                    fontSize: "17px",
                    textTransform: "none",
                    color: "white",
                    border: "1px solid #3c73d6",
                    capitalize: "none",
                    borderRadius: "10px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    boarderColor: "#3c73d6",
                    backgroundColor: "#0461CF",
                    marginTop: "10px",
                    width: "100%",
                    maxWidth: "500px",
                    minWidth: "50px",
                    height: "50px",
                  }}
                >
                  Save Changes
                </Button>
            </div>
            <div className="cl-mobile-btns">
            <Button
                  type="primary"
                  //onClick={}
                  style={{
                    fontSize: "17px",
                    textTransform: "none",
                    color: "#3c73d6",
                    border: "1px solid #3c73d6",
                    capitalize: "none",
                    borderRadius: "10px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    boarderColor: "#3c73d6",
                    backgroundColor: "white",
                    marginTop: "10px",
                    width: "100%",
                    maxWidth: "500px",
                    minWidth: "50px",
                    height: "50px",
                  }}
                >
                  Cancel
                </Button>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
